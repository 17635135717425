@import "../../utility.scss";

.customModal.automationModal {
  max-width: 650px;
  .report-date-picker {
    margin-top: 0.5rem;
  }
  table {
    width: 100%;
    tr {
      text-align: center;
      td,
      th {
        &:first-child {
          border-right: 0px none;
          .MuiIconButton-root {
            padding: 0;
          }
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
      th {
        font-weight: 500;
      }
      td {
        font-size: 13px;
        &:nth-child(2) {
          span {
            display: block;
          }
        }
      }
    }
  }
}

.payment-dropzone {
  min-height: 0px !important;
  .MuiTypography-h5 {
    font-size: 1rem;
    color: grey;
  }
  .MuiDropzoneArea-icon {
    color: grey;
  }
  .MuiDropzonePreviewList-imageContainer {
    .MuiTypography-body1 {
      font-size: 0.7rem;
    }
  }
  .MuiDropzonePreviewList-imageContainer {
    margin-top: -12%;
    margin-left: 2%;
    padding-bottom: 10% !important;
  }
  .MuiDropzonePreviewList-removeButton {
    opacity: 1;
    top: -10px;
    right: 150px;
  }
  outline: none;
}
