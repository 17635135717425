
.sc-planning-tab{
    width: 37vw;
}

.newButton, .newButtonOutlined{
    min-width: 108px !important;
    height: fit-content !important;
}

.review-image{
    width: 60vw;
    height: 75vh;

}

.ok-icon{
    color: green!important;
}

.nok-icon{
    color: red!important;
}

.button-border-kpi{
    border: 1.5px solid rgb(149, 149, 149);
    padding: 3px 3px 3px 3px;
    border-radius: 35px;
    min-height: 50px;
}

.icon-in-border{
    margin-top: 12px;
    vertical-align: middle;
    margin-left: 9px;
}

.icon-in-border-image{
   margin: 5px 10px 5px 10px;
  
}

.KpiPlanInput{
    margin: 10px 0;
    .MuiOutlinedInput-input {
        padding: 8.5px 10px;
    }
}

.marginrightLeft05Vw{
    margin: 0px 0.5vw;
}