@import './media-queries.scss';
@import './utility.scss';
// Login Screen
.loginPage {
    .logo {
        @include devices(tablet mobile potrait) {
            width: 70% !important;
        }
        @include devices(xs eReaders) {
            top: 5% !important;
            left: 50% !important;
            width: 120px !important;
            transform: translate(30px, 10px) !important;
        }
    }
    .MuiGrid-grid-md-4.MuiPaper-elevation6 {
        @include devices(xs eReaders) {
            background-image: url(./images/login-bg.png);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        .loginFormContainer {
            @include devices(mobile landscape) {
                margin-top: 5rem;
            }
            @include devices(xs) {
                margin-top: 7rem;
            }
            @include devices(xs mobile landscape) {
                margin-left: 20px;
                background: #fff;
                margin-right: 20px;
                border-radius: 10px;
                padding: 15px;
                opacity: 0.95;
                h1 {
                    font-size: 1.5rem;
                }
            }
        }
    }
}

// Top Navigation
.app-header {
    @include devices(xs eReaders mobile) {
        .userAccountButton {
            padding: 12px 10px !important;
            border-radius: 0;
        }
        .notification-icon {
            padding: 0px !important;
        }
    }
    @include devices(xs eReaders mobile) {
        .app-logo {
            max-width: 120px !important;
            flex-basis: 120px !important;
        }
        .search-box {
            flex-grow: 0;
            max-width: 100% !important;
            flex-basis: 100% !important;
        }
    }
    @include devices(tablet desktop) {
        .search-box {
            flex-grow: 0;
            max-width: 40% !important;
            flex-basis: 40% !important;
        }
    }
}

//Landing Page
.homeBlockDesign {
    @include devices(xs eReaders mobile) {
        .usr-mngmt-breadcrumbs{
            font-size: 12px !important;
        }
        .headerText {
            margin: 50px 20px 20px 20px;
        }
        .user-mgmt-menu-list{
            margin: 20px auto 0 !important;
            max-width: 100%;
            padding: 0 10px;
            .MuiGrid-item{
                padding: 8px 0px !important;
            }
            .block {
                margin: 7.5px auto;
                width: 85%;
                text-align: center;
                min-height: 85px !important;
                background: #fff;
                border: none;
                box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
                img {
                    width: 45px !important;
                    height: 45px !important;
                    margin: 10px 0 10px !important;
                }
                h5 {
                    margin: -5px 5px 5px !important;
                }
            }
            .block:hover {
                background: rgba(245, 150, 41, 0.22);
                transition: all 300ms;
            }
        }
        .blockContainer {
            margin: 50px auto 0;
            .block {
                img {
                    width: 85px;
                    height: 85px;
                    margin: 15px 0 10px;
                }
                h5 {
                    margin: 0 5px 5px;
                }
            }
        }
    }
}

// Panel Left Navigation
.app-sidebar {
    @include devices(xs eReaders mobile) {
        .MuiDrawer-root {
            display: none;
        }
    }
    .blockDesignSidebar {
        @include devices(xs eReaders mobile) {
            width: 100% !important;
            .MuiBox-root.parent-box {
                padding: 10px;
                width: 100%;
                margin: 0px;
                display: list-item;
            }
            .box-icon {
                height: 45px !important;
            }
            .box {
                margin: 25px auto;
                width: 85%;
                text-align: center;
                height: 85px !important;
                background: #fff;
                border: none;
                box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
            }
            .box:hover {
                background: rgba(245, 150, 41, 0.22);
                transition: all 300ms;
            }
            .title-box {
                text-transform: uppercase;
                font-weight: 500;
                color: #000000;
                font-size: 0.8em;
                margin-top: 5px;
            }
        }
    }
}

//APP CONTENT
.app-content {
    height: calc(100vh - 95px);
    @include devices(xs eReaders mobile wideScreens) {
        max-width: 100% !important;
        .content-child {
            width: 100% !important;
        }
    }

    @include devices(customTab) {
        .content-child {
            width: calc(100% + 50em) !important;
        }
    }


    //Global Component
    // React Table
    .ReactTable .rt-thead,
    .ReactTable .rt-tbody {
        display: grid !important;
    }

    .display-inherit{
        display: inherit !important;
    }


    //Digital Processes > Free Cash Flow 
    .free-cash-flow {
        margin-top: -10px;
        .gap-to-target-table {
            margin-top: -10px;
            .newCustomTable {
                min-height: 400px;
                max-height: 400px;
                .rt-tbody{
                    overflow: overlay;
                }
            }
        }
        @include devices(xs eReaders mobile) {
            .cadence-board-radio{
                margin-left: 10px;
            }
            //chevron
            #pointer-mini {
                display: inline-block;
                font-size: 11px;
                width: 120px;
                height: 2em;
                position: relative;
                background: $secondary-light;
                border-right: 1px solid $secondary-light;
                text-align: center;
                vertical-align: middle;
                margin: 0.6em 0px;
                /* margin-left: -15px; */
                margin-right: 5px;
                padding: 0px 1em 0px 5px;
            }
            #pointer-mini:before {
                content: '';
                position: absolute;
                right: -1em;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 1em solid $secondary-light;
                border-top: 1em solid transparent;
                border-bottom: 1em solid transparent;
            }
            #pointer-mini:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 1em solid $body-background-color;
                border-top: 1em solid transparent;
                border-bottom: 1em solid transparent;
            }
            #pointer-chevron {
                display: inline-block;
                font-size: 11px;
                width: 90%;
                height: 2em;
                position: relative;
                background: $secondary-light;
                border-right: 1px solid $secondary-light;
                text-align: center;
                vertical-align: middle;
                margin-left: 0px;
                padding: 0px 1em 0px 5px;
            }
            #pointer-chevron:before {
                content: '';
                position: absolute;
                right: -1em;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 1em solid $secondary-light;
                border-top: 1em solid transparent;
                border-bottom: 1em solid transparent;
            }
            #pointer-chevron:after {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 1em solid $body-background-color;
                border-top: 1em solid transparent;
                border-bottom: 1em solid transparent;
            }
            .cnt {
                margin-top: -1em;
            }
            .menu-options {
                display: inherit !important;
                .radioGroupHorizontal {
                    display: grid !important;
                }
            }
            .export-button {
                margin-top: -200px;
                justify-content: flex-end;
                display: flex;
            }
            #pointer-chevron {
                margin-top: 35px !important;
            }
            .benchmark-group {
                margin-top: -130px !important;
            }
            .ideation-group {
                margin-top: -100px !important;
                display: flex !important;
            }
            .improvement-group {
                margin-top: 0px !important;
                margin-bottom: 10px;
                display: flex !important;
            }
            // Gap to target
            .gapToTarget {
                margin-top: 85px !important;
                .MuiGrid-root.mt-30 {
                    margin-top: -50px !important;
                }
                h4.text-center.mb-20{
                    margin-top: 0px !important;
                }
                .filter-options{
                    margin-top: 5px !important;
                }

                .filter-by-gap-ddl{
                    max-width: 135px !important;
                    padding: 12px 0px 0px 10px !important;
                    flex-basis: 100% !important;
                }   
            }
            // Improvement Plan
            .improvementPlan {
                background: #fff;
                padding: 10px;
                border-radius: 5px;
                .MuiStepper-root {
                    margin-left: -10px;
                    margin-right: 10px;
                    .MuiStepButton-root .MuiStepLabel-label {
                        font-size: 12px;
                    }
                    .MuiTypography-root{
                        font-size: 3.3vw !important;
                    }
                }
                .form-improvement {
                    max-width: 100% !important;
                }
                .MuiInputBase-root {
                    font-size: 12px !important;
                }
                .MuiFormControl-root .MuiFormLabel-root,
                .MuiFormControl-root .MuiSelect-root {
                    font-size: 12px !important;
                }
                .MuiButton-label {
                    font-size: 12px;
                }
                .divider{
                    background-color: #e5e5e5 !important;
                    border: none;
                    border-top: 1px solid #e5e5e5;
                    color: #fff;
                    background-color: #fff;
                    height: 20px;
                    margin-right: -5px;
                    margin-left: -5px;
                    margin-top: 10px;
                    margin-bottom: -10px;
                    width: 100vh;
                }
                .comment-box {
                    border-radius: 5px;
                    flex-basis: 100%;
                    max-width: 100%;
                    margin: 20px 5px;
                    padding: 0px;
                    overflow: hidden;
                    .cmModal {
                        border: 2px solid #eee;
                        .header {
                            margin-top: 0px;
                            padding-top: 20px;
                            height: 40px;
                            font-size: 12px;
                        }
                    }
                }
            }

            // Candance Board
            .cadenceBoard {
                margin-top: 70px !important;
                background: #fff;
                padding: 10px;
                border-radius: 5px;
                .MuiButton-containedSizeSmall {
                    font-size: 12px;
                    margin-right: 2px !important;
                    margin-bottom: 10px;
                }
                .hideShow {
                    padding: 10px 5px !important;
                    margin: 10px !important;
                    border: 2px solid #eee;
                    border-radius: 5px;
                    .MuiButton-contained.ml-16 {
                        margin-left: 2px !important;
                    }
                }
                .contentArea .block {
                    .head {
                        font-size: 11px;
                        font-weight: 600;
                    }
                    .body {
                        height: 25em;
                        display: flex;
                        color: #d00909;
                        padding: 10px 5px;
                        border-left: 1px solid #5a5a5a;
                        border-right: 1px solid #5a5a5a;
                        max-width: 100%;
                        margin: 0 auto;
                        word-break: break-word;
                        font-size: 12px;
                    }
                    .bottom {
                        height: 28em;
                        display: flex;
                        align-items: center;
                        padding: 0 10px;
                        font-size: 11px;
                        word-break: break-word;
                        font-weight: 600;
                    }
                }
            }
        }
        @include devices(xs) {
            .cadenceBoard {
                .contentArea .block {
                    .head {
                        word-break: break-word;
                        height: 60px;
                        text-align: center;
                        align-items: center;
                        display: flex;
                        &::after {
                            right: -10px;
                            border-top: 30px solid transparent;
                            border-bottom: 30px solid transparent;
                            border-left: 10px solid grey;
                        }
                        .block-title {
                            margin: auto;
                        }
                    }
                }
            }
        }
        @include devices(eReaders mobile) {
            .cadenceBoard {
                .contentArea .block {
                    .head {
                        word-break: break-word;
                        text-align: center;
                        align-items: center;
                        &::after {
                            right: -10px;
                            border-top: 22.5px solid transparent;
                            border-bottom: 22.5px solid transparent;
                            border-left: 10px solid grey;
                        }
                    }
                }
            }
            // Improvement Analysis & Performance Analysis
            .gapToTarget,
            .ImprovementAnalysis,
            .MonthlyPerformace {
                margin: 0px 10px;
                .filter-options {
                    display: flex;
                    .collaborate-button {
                        flex-basis: 27% !important;
                    }
                }
            }
        }
        @include devices(customMobile) {
            .menu-options {
                .MuiFormControl-root {
                    min-width: 90%;
                }
                .export-button {
                    display: flex;
                }
            }
            // Gap to Target
            .gapToTarget {
                .MuiGrid-root.mt-30 {
                    margin-top: 10px !important;
                }

                .filter-by-gap-ddl{
                    max-width: 135px !important;
                    padding: 12px 0px 0px 10px !important;
                    flex-basis: 100% !important;
                }  
            }
            // Cadence Board
            .cadenceBoard {
                .MuiGrid-grid-sm-6 {
                    flex-basis: 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }


    //Digital Processes > Key Focus Areas > Overdue AR
    .overdue-ar{
        @include devices(xs eReaders mobile){
            .MuiInputBase-root{
                width: 60%;
            }
            .MuiTab-root {
                background-color: #e0e0e0;
                font-size: 11px;
                padding: 5px 1px;
                max-width: calc(100% / 4);
                min-width: calc(100% / 4);
                border-left: 1px solid #d2d0d0;
                border-right: 1px solid #d2d0d0;
            }
            .MuiTab-textColorPrimary.list-nested:hover{
                background-color: $secondary !important;
            }
        }

        // chart view tab
        .chart-view{
            @include devices(xs eReaders mobile){
                .MuiGrid-spacing-xs-3 {
                    margin: -5px 0 2px -10px;
                }
            }
        }

        //monthly overdue tab
        .monthly-overdue{
            @include devices(xs eReaders mobile){
                .filter-content{
                    display: block !important;
                    .date-filter{
                        margin-top: 10px;
                    }
                }
                .bu-list{
                    margin-top: 30px;
                    margin-left: -10px;
                }

                .summary-content {
                    display: flex !important;
                }
                .w-200 {
                    margin-bottom: -5px;
                }
            }

        }

        //table view tab
        .table-view{
            @include devices(xs eReaders mobile){
                .filter-content{
                    display: block !important;
                    .date-filter{
                        margin-top: 10px;
                    }
                }
                .grand-total-text{
                    white-space: normal !important;
                    word-break: break-word !important;
                }
                .w-200 {
                    margin-bottom: -8px;
                }
            }
        }

        //Analysis action tab
        .analysis-action{
            @include devices(xs){
                .display-inherit{
                    .MuiFormControl-root.w-200{
                        max-width: 200px;
                    }
                    display: block !important;
                    .control-button{
                        margin-left: -20px;
                        margin-bottom: 10px;
                        margin-top: 20px;
                    }
                }
             }
             @include devices(eReaders mobile){
                .display-inherit{
                    .MuiFormControl-root.w-200{
                        max-width: 145px;
                    }
                }
             }

             @include devices(xs eReaders mobile){
                .rthfc.-sp .rthfc-th-fixed,
                .rthfc.-sp .rthfc-td-fixed {
                    position: initial;
                    z-index: 1;
                }
                .ReactTable {
                    .rthfc-th-fixed-left-last,
                    .rthfc-td-fixed-left-last {
                        border-right: none !important;
                    }
                    .rt-thead .rt-th:first-child, .rt-thead .rt-th:nth-child(2){
                        // border-right: 2px solid #062565 !important;
                        position: sticky !important;
                        z-index: 12 !important;
                    }
                    .rt-thead .rt-th:nth-child(2), .rt-tr-group .rt-tr .rt-td:nth-child(2){
                        left: 100px !important;
                        border-right: 2px solid #062565 !important;
                    }
                    .rt-thead .rt-th:first-child, 
                    .rt-thead .rt-th:nth-child(2),
                    .rt-tr-group .rt-tr .rt-td:first-child,
                    .rt-tr-group .rt-tr .rt-td:nth-child(2){
                        max-width: 100px !important;
                    }
                    .rt-tr-group .rt-tr .rt-td:first-child, .rt-tr-group .rt-tr .rt-td:nth-child(2) {
                        // border-right: 2px solid #062565 !important;
                        position: sticky !important;
                        z-index: 2 !important;
                    }

                    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
                        padding-right: 80px !important;
                    }
                }
             }
        }

    }

    //Digital Process > Key Focus Areas > Risk Management
    .risk-management{
        @include devices(xs eReaders mobile){
            .date-toggle{
                margin-left: 0px;
                .MuiFormControlLabel-root{
                    margin-right: 0px !important;
                }
            }
            .MuiGrid-root.contain{
                display: block !important;
                .MuiGrid-grid-sm-9{
                    max-width: 100%;
                }
            }
            .arrange {
                display: block !important;
                .legendBox{
                    margin-top: 15px;
                }
            }
            .MuiTabs-root{
                .MuiButtonBase-root {
                    background-color: $secondary !important;
                    border-radius: 3px;
                    border-right: 1px solid $secondary;
                    border-left: 1px solid $secondary;
                    padding: 9px 15px !important;
                }
                .MuiButtonBase-root{
                    color: $color-white !important;
                    min-height: 35px !important;
                }
                .MuiTab-textColorPrimary.Mui-selected{
                    padding: 9px 15px !important;
                }
            }
            .MuiButtonBase-root{
                font-size: 12px !important;
            }
        }
        @include devices(eReaders mobile){
            .date-toggle{
                margin-top: -15px;
                flex-basis : 100% !important;
                max-width: 100% !important;
            }
            .MuiGrid-root.contain{
                .MuiGrid-grid-sm-3{
                    max-width: 100%;
                }
                .MuiButtonGroup-vertical{
                    margin-top: 10px;
                    flex-direction: inherit !important;
                }
            }
        }
        @include devices(xs){
            .MuiGrid-root.contain{
                .MuiGrid-grid-sm-3{
                    max-width: 100%;
                }
                .MuiButtonGroup-vertical{
                    width: 100%;
                    margin-top: 10px;
                }
            }
            .product-filter , .country-filter {
                min-width: 45% !important;
            }
            .product-filter , .country-filter , .improvement-btn{
                margin-top: 10px;
            }
        }
    }

    //Digital Process > Key Focus Areas > Opportunity Management
    .opportunity-management{
        @include devices(xs eReaders mobile){
            .date-toggle{
                margin-left: 0px;
                .MuiFormControlLabel-root{
                    margin-right: 0px !important;
                }
            }
            .MuiGrid-root.contain{
                display: block !important;
                .MuiGrid-grid-sm-9{
                    max-width: 100%;
                }
            }
            .arrange {
                display: block !important;
                .legendBox{
                    margin-top: 15px;
                }
            }
            .MuiTabs-root{
                .MuiButtonBase-root {
                    background-color: $secondary !important;
                    border-radius: 3px;
                    border-right: 1px solid $secondary;
                    border-left: 1px solid $secondary;
                    padding: 9px 15px !important;
                }
                .MuiButtonBase-root{
                    color: $color-white !important;
                    min-height: 35px !important;
                }
                .MuiTab-textColorPrimary.Mui-selected{
                    padding: 9px 15px !important;
                }
            }
            .MuiButtonBase-root{
                font-size: 12px !important;
            }
        }
        @include devices(eReaders mobile){
            .date-toggle{
                margin-top: -15px;
                flex-basis : 100% !important;
                max-width: 100% !important;
            }
            .MuiGrid-root.contain{
                .MuiGrid-grid-sm-3{
                    max-width: 100%;
                }
                .MuiButtonGroup-vertical{
                    margin-top: 10px;
                    flex-direction: inherit !important;
                }
            }
        }
        @include devices(xs){
            .MuiGrid-root.contain{
                .MuiGrid-grid-sm-3{
                    max-width: 100%;
                }
                .MuiButtonGroup-vertical{
                    width: 100%;
                    margin-top: 10px;
                }
            }
            .product-filter , .country-filter {
                min-width: 45% !important;
            }
            .product-filter , .country-filter , .improvement-btn{
                margin-top: 10px;
            }
        }
    }

        //Analyze > Improvement Plan > Plan Summary
    .analyze-plan-summary{
        .planDataTable{
            .ReactTable .rt-thead .rt-th:first-child {
                width: 100px !important;
            }
            .ReactTable {
                .rt-table {
                    .rt-head {
                        .rt-th:first-child {
                            width: 100px !important;
                        }
                    }
                    .rt-tbody {
                        max-height: 375px;
                        .rt-tr-group .rt-tr .rt-td:first-child {
                            width: 100px !important;
                        }
                    }
                }
            }
            @include devices(xs eReaders mobile) {
                margin-top: 20px;
                .rthfc.-sp .rthfc-th-fixed,
                .rthfc.-sp .rthfc-td-fixed {
                    position: initial;
                    z-index: 1;
                }
                .ReactTable {
                    .rthfc-th-fixed-left-last,
                    .rthfc-td-fixed-left-last {
                        border-right: none !important;
                    }
                    .rt-thead .rt-th:first-child {
                        border-right: 2px solid #062565 !important;
                        position: sticky !important;
                        z-index: 12 !important;
                    }
                    .rt-tr-group .rt-tr .rt-td:first-child {
                        border-right: 2px solid #062565 !important;
                        position: sticky !important;
                        z-index: 2 !important;
                    }
                }
            }
        }
    }

    //Analyze > Analytics Module
    .analyze-analytics{
        @include devices(xs eReaders mobile) {
            .MuiTab-root {
                background-color: #e0e0e0;
                font-size: 11px;
                padding: 5px 1px;
                max-width: calc(100% / 5);
                min-width: calc(100% / 5);
                border-left: 1px solid #d2d0d0;
                border-right: 1px solid #d2d0d0;
                word-break: break-all;
            }

            .content-div{
                display: block !important;
                .MuiGrid-root.MuiGrid-container{
                    display: block !important;
                }
                .MuiGrid-item.MuiGrid-grid-xs-8,.MuiGrid-item.MuiGrid-grid-xs-4{
                    max-width: 100% !important;
                }
            }

            .bu-ddl, .key-account-ddl{
                max-width: 130px !important;
            }

            .region-ddl, .period-ddl, .country-ddl, .segment-ddl {
                max-width: 100px !important;
            }
        }
    }

    //Analyze > Geospatial
    .geospatial-analysis{
        @media screen and (min-width: 280px) and (max-width: 360px) {
            .flex.jc-space-between{
                display: block !important;
                .filter-options{
                    margin-top: 0px !important;
                    .MuiGrid-root.maxw-200{
                        max-width: 95px !important;
                    }
                }

                .download-options{
                    margin-top: -40px !important;
                    margin-bottom: 25px !important;;
                }
                
            }
        }

        @media screen and (min-width: 280px) and (max-width: 355px) {
            .filter-options{
                margin-top: 0px !important;
            }

            .download-options{
                margin-top: -80px !important;
                margin-bottom: 55px !important; 
            }
        }
        @media screen and (min-width: 364px) and (max-width: 666px) {
            .filter-options{
                 margin-top: 0px !important;
            }
            .download-options{
                margin-top: -35px !important;
                margin-bottom: 25px !important;
            }
        }

        @media screen and (min-width: 361px) and (max-width: 420px) {
            .filter-options{
                .MuiGrid-root.maxw-200{
                    max-width: 100px !important;
                }
            }
        }

        @media screen and (min-width: 420px) and (max-width: 444px) {
            .filter-options{
                .MuiGrid-root.maxw-200{
                    max-width: 110px !important;
                }
            }
        }
        @media screen and (min-width: 450px) and (max-width: 666px) {
            .filter-options{
                .MuiGrid-root.maxw-200{
                    max-width: 120px !important;
                }
            }
        }

         @include devices(xs eReaders mobile) {
            .flex.jc-space-between{
                display: block !important;
                .filter-options{
                    margin-top: 30px;
                    .MuiGrid-root.maxw-200{
                        padding: 5px 0px 5px 10px !important;
                    }
                }
                .download-options{
                    margin-top: -80px;
                    margin-bottom: 55px;
                }
                
            }
            .MuiTab-root {
                background-color: #e0e0e0;
                font-size: 11px;
                padding: 5px 1px;
                max-width: calc(100% / 5);
                min-width: calc(100% / 5);
                border-left: 1px solid #d2d0d0;
                border-right: 1px solid #d2d0d0;
            }
            .MuiTab-textColorPrimary.Mui-selected{
                padding: 11px 0 !important;
            }
        }
    }
    //Automated Reports > P&L Reports > NEW BU P&L
    .automated-new-bu-pl{
        .table-div{
            padding: 0px 10px !important;
        }
        @media screen and (min-width: 280px) and (max-width: 360px) {
            .flex.jc-space-between{
                display: block !important;
                .filter-options{
                    margin-top: 0px !important;
                    .MuiGrid-root.maxw-200{
                        max-width: 95px !important;
                    }
                }

                .download-options{
                    margin-top: -40px !important;
                    margin-bottom: 25px !important;;
                }
                
            }
        }

        @media screen and (min-width: 280px) and (max-width: 355px) {
            .filter-options{
                margin-top: 0px !important;
            }

            .download-options{
                margin-top: -80px !important;
                margin-bottom: 55px !important; 
            }
        }
        @media screen and (min-width: 364px) and (max-width: 666px) {
            .filter-options{
                 margin-top: 0px !important;
            }
            .download-options{
                margin-top: -35px !important;
                margin-bottom: 25px !important;
            }
        }

        @media screen and (min-width: 361px) and (max-width: 420px) {
            .filter-options{
                .MuiGrid-root.maxw-200{
                    max-width: 100px !important;
                }
            }
        }

        @media screen and (min-width: 420px) and (max-width: 444px) {
            .filter-options{
                .MuiGrid-root.maxw-200{
                    max-width: 110px !important;
                }
            }
        }
        @media screen and (min-width: 450px) and (max-width: 666px) {
            .filter-options{
                .MuiGrid-root.maxw-200{
                    max-width: 120px !important;
                }
            }
        }

         @include devices(xs eReaders mobile) {
            .content{
                display: block !important;
                .table-div{
                    max-width: 100%;
                    flex-basis: 100%;
                }
                .chart-div{
                    margin: 20px 10px !important;
                    max-width: 100%;
                    flex-basis: 100%;
                    .border-top-gray {
                        border-top: 20px solid #e5e5e5;
                    }
                }
            }
            .analytics-module{
                display: block !important;
                .information-box{
                    margin-top: 10px;
                }
                .px-5{
                    padding: 10px 0px !important;
                }
            }
            .flex.jc-space-between{
                display: block !important;
                .filter-options{
                    margin-top: 30px;
                    .MuiGrid-root.maxw-200{
                        padding: 5px 0px 5px 10px !important;
                    }
                }
                .download-options{
                    margin-top: -80px;
                    margin-bottom: 55px;
                }
                
            }
            .MuiTab-root {
                background-color: #e0e0e0;
                font-size: 11px;
                padding: 5px 1px;
                max-width: calc(100% / 5);
                min-width: calc(100% / 5);
                border-left: 1px solid #d2d0d0;
                border-right: 1px solid #d2d0d0;
            }

            .rt-tr-group .rt-tr .rt-td:first-child{
                padding-left: 10px;
                width: 225px !important;
            }
            .rt-thead .rt-th:first-child{
                width: 225px !important;
            }
        }
    }

    //Automated Reports > Receviable Reports > 100k-120 Day Reports
    .dayrepots-100k-120{
         @include devices(xs eReaders mobile) {
            .date-filter{
                .w-200{
                    margin-bottom: 10px;
                    .MuiInputBase-root{
                        width: 60%;
                    }
                }
            }
            .MuiTab-root {
                background-color: #e0e0e0;
                font-size: 11px;
                max-width: calc(100% / 4);
                min-width: calc(100% / 4);
                border-left: 1px solid #d2d0d0;
                border-right: 1px solid #d2d0d0;
                .MuiTab-textColorPrimary.list-nested:hover {
                    background-color: $secondary !important;
                }
            }
            .MuiTab-textColorPrimary.Mui-selected{
                padding: 11px 0 !important;
            }

            .rthfc.-sp .rthfc-th-fixed,
            .rthfc.-sp .rthfc-td-fixed {
                position: initial;
                z-index: 1;
            }

            .ReactTable {
                .rthfc-th-fixed-left-last,
                .rthfc-td-fixed-left-last {
                    border-right: none !important;
                }
                .rt-thead .rt-th:first-child {
                    border-right: 2px solid #062565 !important;
                    position: sticky !important;
                    z-index: 12 !important;
                }
                .rt-tr-group .rt-tr .rt-td:first-child {
                    border-right: 2px solid #062565 !important;
                    position: sticky !important;
                    z-index: 2 !important;
                }
            }
            .ageingPageDropdown{
                .control-button {
                    float: right;
                }
            }
        }
    }
    
    //Automated Reports > P&L Reports > BU P&L
    .automated-bu-pl{
        @media screen and (min-width: 280px) and (max-width: 360px) {
            .flex.jc-space-between{
                display: block !important;
                .top-summary-options, .top-options{
                    .MuiGrid-root.maxw-200{
                        max-width: 130px !important;
                        padding: 5px 0px 5px 10px !important;
                    }
                }
                .top-summary-options{
                     margin-top: 40px;
                    
                }
                .additional-info-summary{
                    margin-top: -135px;
                    margin-bottom: 105px;
                }

                .top-options{
                     margin-top: 30px;
                    
                }
                .additional-info{
                    margin-top: -80px;
                    margin-bottom: 55px;
                }
                
            }
        }
        @media screen and (min-width: 440px) and (max-width: 666px) {
            .top-options{
                 margin-top: 0px !important;
            }
            .additional-info{
                margin-top: -40px !important;
                margin-bottom: 30px !important;
            }
        }
        @media screen and (min-width: 568px) and (max-width: 666px) {
            .flex.jc-space-between{
                display: block !important;
                .top-summary-options, .top-options{
                    .MuiGrid-root.maxw-200{
                        max-width: 110px !important;
                        padding: 5px 0px 5px 10px !important;
                    }
                }
                .top-summary-options{
                     margin-top: 0px !important;
                }
                .additional-info-summary{
                    margin-top: -40px !important;
                    margin-bottom: 25px !important;
                }
                
            }
        }

        @include devices(xs eReaders mobile) {
            .latest-info{
                font-size: 11px !important;
            }
            .flex.jc-space-between{
                display: block !important;
                .top-summary-options, .top-options{
                    .MuiGrid-root.maxw-200{
                        max-width: 110px !important;
                        padding: 5px 0px 5px 10px !important;
                    }
                }
                .top-summary-options{
                    margin-top: 40px;
                }
                .additional-info-summary{
                    margin-top: -90px;
                    margin-bottom: 55px;
                }

                .top-options{
                     margin-top: 30px;
                    
                }
                .additional-info{
                    margin-top: -80px;
                    margin-bottom: 55px;
                }
                
            }

            .MuiTab-root {
                background-color: #e0e0e0;
                font-size: 11px;
                max-width: calc(100% / 6);
                min-width: calc(100% / 6);
                border-left: 1px solid #d2d0d0;
                border-right: 1px solid #d2d0d0;
                // word-break: break-all;
            }
            .newCustomTable{
                .rt-th, .rt-td{
                    white-space: break-spaces !important;
                }
            }
            .MuiTab-textColorPrimary.Mui-selected{
                padding: 11px 0 !important;
            }
        }

        @include devices(xs eReaders) {
            .newCustomTable{
                .rt-thead .rt-th:nth-child(2), .rt-tbody .rt-tr-group .rt-td:nth-child(2){
                    width: 150px !important;
                    max-width: 150px !important;
                }
                .rt-tbody .bupl-SubTable .rt-tr-group .rt-td:nth-child(2){
                    width: 125px !important;
                    max-width: 125px !important;
                }
                .rt-tbody .bupl-SubTable .bupl-SubTable .rt-tr-group .rt-td:nth-child(2){
                    width: 100px !important;
                    max-width: 100px !important;
                } 
            }
        }
        @include devices(mobile) {
            .newCustomTable{
                .rt-thead .rt-th:nth-child(2), .rt-tbody .rt-tr-group .rt-td:nth-child(2){
                    width: 200px !important;
                    max-width: 200px !important;
                }
                .rt-tbody .bupl-SubTable .rt-tr-group .rt-td:nth-child(2){
                    width: 175px !important;
                    max-width: 175px !important;
                }
                .rt-tbody .bupl-SubTable .bupl-SubTable .rt-tr-group .rt-td:nth-child(2){
                    width: 150px !important;
                    max-width: 150px !important;
                } 
            }
        }
    }
    //Automated Reports > P&L Reports > BU P&L > BU-1 > By Plant
    .automated-bu-pl-tab{
        .exc-column{
            width: 200px;
        }
         @include devices(xs eReaders mobile) {
            .bu-pl-filter{
                display: block !important;
                .MuiGrid-root{
                    margin: 3px 0;
                    .exc-column{
                        width: 100%;
                        .maxw-200{
                            max-width: 130px !important;
                        } 
                    }
                    .MuiButtonBase-root{
                        margin-left: -65px;
                    }
                }
            }
         }
    }

    //Automated Reports > Receivable Reports > Invoice List Reports
    @include devices(xs eReaders mobile){
        .date-filter{
            .w-200{
                .MuiInputBase-root{
                    width: 60%;
                }
            }
        }
    }

    .invoiceListReports-table.hfmDataTable {
        .ReactTable .rt-thead .rt-th:first-child {
            width: 100px !important;
        }
        .ReactTable {
            .rt-table {
                .rt-head {
                    .rt-th:first-child {
                        width: 100px !important;
                    }
                }
                .rt-tbody {
                    max-height: 375px;
                    .rt-tr-group .rt-tr .rt-td:first-child {
                        width: 100px !important;
                    }
                }
            }
        }
        @include devices(xs eReaders mobile) {
            margin-top: 20px;
            .rthfc.-sp .rthfc-th-fixed,
            .rthfc.-sp .rthfc-td-fixed {
                position: initial;
                z-index: 1;
            }
            .ReactTable {
                .rthfc-th-fixed-left-last,
                .rthfc-td-fixed-left-last {
                    border-right: none !important;
                }
                .rt-thead .rt-th:first-child {
                    border-right: 2px solid #062565 !important;
                    position: sticky !important;
                    z-index: 12 !important;
                }
                .rt-tr-group .rt-tr .rt-td:first-child {
                    border-right: 2px solid #062565 !important;
                    position: sticky !important;
                    z-index: 2 !important;
                }
            }
        }
    }
    .invoiceListReports-dropdownList {
        .control-button {
            display: inherit;
            .MuiButton-root.MuiButton-outlined {
                min-width: auto !important;
                padding: 2px 10px !important;
                font-size: 12px !important;
            }
        }
        @include devices(xs eReaders mobile) {
            margin-top: -8px;
            display: block !important;
            .MuiFormControl-root {
                max-width: 145px !important;
            }
            .control-button {
                margin-top: 10px;
                margin-bottom: 10px;
                float: right;
            }
        }
    }


    //Automated Reports > Recivable Reports > Aging Reports
    .ageingPageDropdown {
        .control-button {
            display: inherit;
            .MuiButton-root.MuiButton-outlined {
                min-width: auto !important;
                padding: 2px 10px !important;
                font-size: 12px !important;
            }
        }
        @include devices(xs eReaders mobile) {
            display: block !important;
            .MuiFormControl-root {
                max-width: 145px !important;
            }
            .control-button {
                float: right;
                margin-top: 10px;
            }
        }
    }

    .aging-report{
        @include devices(xs eReaders mobile) {
            .w-200{
                margin-bottom: 10px;
                .MuiInputBase-root{
                    width: 60%;
                }
            }
            .dayReportPage.agingDataTable{
                .rthfc.-sp .rthfc-th-fixed,
                .rthfc.-sp .rthfc-td-fixed {
                    position: initial;
                    z-index: 1;
                }
                .ReactTable {
                    .rthfc-th-fixed-left-last,
                    .rthfc-td-fixed-left-last {
                        border-right: none !important;
                    }
                    .rt-thead .rt-th:first-child {
                        border-right: 2px solid #062565 !important;
                        position: sticky !important;
                        z-index: 12 !important;
                    }
                    .rt-tr-group .rt-tr .rt-td:first-child {
                        border-right: 2px solid #062565 !important;
                        position: sticky !important;
                        z-index: 2 !important;
                    }
                }
            }
            .custom-tab{
                .MuiTabs-root{
                        .MuiTab-textColorPrimary.Mui-selected{
                            padding: 11px 0 !important;
                            border-left: 1px solid #062565 !important;
                        }
                    }
                .blockTabs {
                    .MuiTab-root {
                        max-width: calc(100% / 5);
                        min-width: calc(100% / 5);
                        font-size: 12px;
                        padding: 0;
                    }
                }
                .tab-content{
                    .MuiTab-textColorPrimary.list-nested:hover {
                            background-color: $secondary !important;
                        }
                    .MuiTab-root {
                        background-color: #e0e0e0;
                        font-size: 11px;
                        padding: 0;
                        max-width: calc(100% / 4);
                        min-width: calc(100% / 4);
                        border-left: 1px solid #d2d0d0;
                     }
                 }    
             }
        }
    }


    // USER MANAGEMENT
    .role-master{
        @include devices(xs eReaders mobile) {
            margin-top: -20px;
            .btn-add{
                flex-basis : 100%;
                min-width: 100%;
                margin-bottom: 0px !important;
                .MuiButtonBase-root{
                    font-size: 12px;
                }
            }
            .role-master-table{
                flex-basis : 100%;
                min-width: 100%;
            }
        }
    }

    .user-master{
        @include devices(xs eReaders mobile) {
            display: block !important;
            .filter-options{
                max-width: 100%;
                flex-basis: 100%;
                .maxw-200{
                    max-width: 120px !important;
                }
                .search-email-ddl{
                    max-width: 160px !important;
                    margin-top: 15px;
                }
            }
            .btn-add{
                margin-top: -30px !important;
                .MuiButtonBase-root{
                    font-size: 12px;
                }
            }
            
        }

        @media screen and (min-width: 490px) and (max-width: 668px) {
            .search-email-ddl{
                margin-top: 0px !important;
            }
            .btn-add{
                margin-top: 0px !important;
            }
        }
    }

    .menu-master, .menu-master-pl{
        .MuiTabs-root{
            text-transform: uppercase;
        }
        @include devices(xs eReaders mobile) {
            .MuiButton-root{
                font-size: 12px;
            }
            .MuiTabs-root{
                .MuiTab-root{
                    background-color: #e0e0e0;
                    font-size: 11px;
                    max-width: calc(100% / 4);
                    min-width: calc(100% / 4);
                    border-left: 1px solid #d2d0d0;
                    border-right: 1px solid #d2d0d0;
                    min-height: 35px !important;
                }
                .MuiTab-textColorPrimary.Mui-selected{
                    padding: 9px 15px !important;
                }
            }
        }
    }
    .automated-data-validation{
        .MuiTabs-root{
            text-transform: uppercase;
        }
        @include devices(xs eReaders mobile) {
            .export-btn{
                margin-bottom: 10px;
            }
            .MuiButton-root{
                font-size: 12px;
            }
            .MuiTabs-root{
                .MuiTab-root{
                    background-color: #e0e0e0;
                    font-size: 11px;
                    max-width: calc(100% / 4);
                    min-width: calc(100% / 4);
                    border-left: 1px solid #d2d0d0;
                    border-right: 1px solid #d2d0d0;
                }
                .MuiTab-textColorPrimary.Mui-selected{
                    padding: 9px 15px !important;
                }
            }
        }
    }

    .upload-data-validation{
        @include devices(xs eReaders mobile) {
            .MuiButton-root{
                font-size: 12px;
            }
            .MuiTabs-root{
                .MuiTab-root{
                    background-color: #e0e0e0;
                    font-size: 11px;
                    max-width: calc(100% / 2);
                    min-width: calc(100% / 2);
                    border-left: 1px solid #d2d0d0;
                    border-right: 1px solid #d2d0d0;
                    min-height: 35px !important;
                }
                .MuiTab-textColorPrimary.Mui-selected{
                    padding: 9px 15px !important;
                }
            }
        }
    }
    .user-analytics{
        @include devices(xs eReaders mobile) {
            .jc-space-between{
                display: block !important;
            }
            .filter-options{
                display: block !important;
                .maxw-200{
                    max-width: 115px !important;
                }
                .MuiButtonBase-root{
                    font-size: 12px !important;
                }
            }
            .toggle-btn{
                float: right;
                margin-top: 10px;
                margin-bottom: -10px !important;
            }
            .MuiSwitch-colorPrimary.Mui-checked {
                color: $secondary;
            }
            .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
                background-color: $secondary;
            }
        }

        @media screen and (min-width: 490px) and (max-width: 668px) {
            .toggle-btn{
                margin-top: -35px !important;
            }
        }
    }

}


// GLOBAL COMPONENT
 @include devices(xs eReaders mobile) {
    //USER MANAGEMENT
    .roleMasterModal{
        .modal-content{
            h3{
                font-size: 12px !important;
                margin-top: 15px !important;
            }
            .table-role-menu{
                max-width: 40em;
                max-height: 13em;
                overflow: scroll;
                position: relative;
                margin-bottom: 15px;
            }

            .MuiButtonBase-root{
                font-size: 12px !important;
            }

            table{
                position: relative;
                // border: 2px solid #b1bace;
                thead th {
                  position: -webkit-sticky; /* for Safari */
                  position: sticky;
                  top: 0;
                  background: $secondary-light;;
                  z-index: 1;
                }

                thead th:first-child, tbody td:first-child{
                    max-width: 40px;
                }

                thead th:first-child{
                    border-right: 1px solid rgba(0,0,0,0.07);
                }

                tbody td:first-child{
                    border-right: 1px solid #eee !important;
                }
                thead th:first-child ,  thead th:nth-child(2){
                  left: 0;
                  background: $secondary-light;;
                  z-index: 100;
                  border-left: 2px solid $secondary-light;
                }

                thead th:nth-child(2),tbody tr td:nth-child(2) {
                    border-right: 2px solid $secondary !important;
                    min-width: 150px;
                }
                tbody tr td:nth-child(1),tbody tr td:nth-child(2) {
                  position: -webkit-sticky; /* for Safari */
                  position: sticky;
                  left: 0;
                  background: $color-white;
                   z-index: 99;
                }
                tr th{
                    padding: 5px !important;
                }
                tr td{
                    padding: 1px 5px !important;
                }
                tr th,tr td{
                    font-size: 12px !important;
                }
            } 
        }
    }

    //USER MASTER
    .userMasterModal{
        .budget-group{
            max-width: 100%;
            flex-basis: 100%;
        }
        .MuiButtonBase-root{
            font-size: 12px !important;
        }
    }

    //MENU MASTER
    .menuMasterModal{
        .report-date-picker-label{
            max-width: 30%;
            flex-basis: 30%;
            padding-top: 20px !important;
            margin-right: 10px !important;
            .MuiFormLabel-root{
                font-size: 14px;
            }
        }
        .report-date-picker{
            max-width: 50%;
            flex-basis: 50%;
            .MuiIconButton-root{
                padding: 0px !important;
            }
            .MuiInputBase-root {
               font-size: 14px !important;
            }
        }
        .information-txt{
            margin-top: 15px;
            .MuiFormLabel-root {
                color: #000;
                font-size: 13px !important;
            }
        }
        .radio-options{
            max-width: 100%;
            flex-basis : 100%;
            .MuiFormGroup-root{
                display: block !important;
                margin-bottom: 10px;
            }
        }
        .MuiButtonBase-root{
            font-size: 12px;
        }

        #demo-simple-select-label{
            font-size: 13px !important;
            color: #808080;
            font-weight: 400;
        }
        .filter-section{
            .MuiIconButton-root{
                padding: 0px !important;
            }
        }
    }

    .automated-data-validation, .upload-data-validation{
        .newCustomTable {
            .rt-thead{
                max-height: initial !important;
            }
            .-headerGroups{
                height: 35px;
            }
            .-header{
                .rt-tr{
                    align-items : center;
                    .rt-resizable-header:nth-child(3){
                        height: 100%;
                        padding: 10px;
                    }
                }
            }
        }
        .MuiButtonBase-root{
            font-size: 12px;
        }
        .modal-content{
            padding: 0px 20px 20px !important;
        }
        .MuiIconButton-root{
            padding: 0px !important;
        }
        .maxw-200{
            max-width: 140px !important;
        }
    }

    //MODAL COMPONENT
    .collaborate-modal{
        .cModal.cmModal {
            width: 60% !important;
            top: 50%;
            left: 50%;
            height: 300px;
            transform: translate(-50%, -50%);
            position: absolute;
        }
    }

    .customModal.commentModal{
        height: 45vh;
        max-width: 360px;
        top: 30%;
        left: 2%;
    }

    .customModal.profileModal{
        .MuiGrid-justify-xs-flex-end {
            justify-content: center;
        }
        .profileLeftSide{
            img{
            width: 110px;
            height: 110px;
            }
        }
        .profileRightSide{
            .mb-30 {
                margin-bottom: 1.575rem !important;
            }
        }
        .MuiButtonBase-root{
            padding: 5px;
        }
        .toggleButton{
            margin-left: 0;
        }
        .MuiButton-label{
            font-size: 11px;
        }
        .jc-space-between {
            justify-content: flex-start;
        }
    }
    
    .customModal{
        font-size: 3.4vw;
        .pl-3{
            margin: 20px 0px 10px -3px;
        }
        .header{
            padding: 10px !important;
            h2 {
                margin: 0;
                font-size: 14px;
            }
        }
    }

    .cModal.cmModal {
        .header{
            padding: 15px 15px 0;
        }

    }

    //DROPDOWNLIST COMPONENT
    .MuiSelect-outlined.MuiSelect-outlined {
        padding-right: 15px !important;
    }

    .MuiSelect-iconOutlined {
        right: 0px !important;
    }

    .breakdown-by-dropdownlist{
        max-width: 135px !important;
        padding: 12px 0px 0px 10px !important;
        flex-basis: 100% !important;
    }

    .filter-by-custom-ddl, .stages-custom-ddl{
        max-width: 100px !important;
        padding: 12px 0px 0px 10px !important;
        flex-basis: 100% !important;
    }

    .MuiFormControlLabel-root .MuiTypography-root {
        font-size: 12px;
    }

    .MuiInputBase-root {
        font-size: 12px !important;
    }

    .MuiFormControl-root .MuiFormLabel-root,.MuiFormControl-root .MuiSelect-root {
        font-size: 12px !important;
    }

    .selectOutlineSmall .MuiOutlinedInput-input {
        padding-top: 10px !important;
        padding-bottom: 7px;
    }

    .MuiMenuItem-root {
        min-height: 25px !important;
        line-height: 15px !important;
    }
    .MuiList-root{
        .w-100{
            max-width: 150px !important;
        }
    }
    .MuiAutocomplete-popper{
        font-size: 12px !important;
        .MuiAutocomplete-option{
            padding: 5px 10px !important;
            min-height: 30px !important;
            .MuiTypography-root{
                font-size: 12px !important;
            }
            .minw-200{
                min-width: 180px !important;
                width: 180px !important;
            }
        }
        .MuiAutocomplete-paper {
            overflow: hidden;
            font-size: 12px !important;
            line-height: 1 !important;
            .MuiAutocomplete-noOptions{
                font-size: 12px !important;
                padding: 10px !important;
            }
        }
    }
    .MuiPopover-root{
        width: 100px !important;
    }

    .notificationMenu{
        width: 200px !important;
    }
    .userSettingsMenu{
        width: auto !important;
    }

    //DATEPICKER
    .MuiPickersBasePicker-container{
        .MuiPickersToolbar-toolbar{
            height: 55px !important;
            background-color: $secondary;
            .MuiButton-root {
                font-size: 12px !important;
                h4.MuiPickersToolbarText-toolbarTxt{
                    font-size: 16px !important;
                }
            }
            .MuiPickersToolbarText-toolbarTxt{
                font-size: 12px !important
            }
        }
        .MuiPickersBasePicker-pickerView{
            min-height: 120px !important;
            min-width: 170px;
            .MuiPickersMonthSelection-container{
                width: 170px !important;
                .MuiPickersMonth-root{
                    flex: 1 0 25%;
                    height: 35px;
                }
                .MuiTypography-subtitle1{
                    font-size: 12px;
                }
            }
            .MuiPickersMonth-monthSelected{
                font-weight: 700;
                font-size: 14px;
            }
        }
    }

    //TABS COMPONENT
    .MuiTabs-root{
        .MuiTab-textColorPrimary.Mui-selected {
            background-color: $secondary !important;
            border-radius: 3px;
            border-right: 1px solid $secondary;
            border-left: 1px solid $secondary;
        }
        .MuiTab-root {
            padding: 5px !important;
            word-break: normal !important;
            line-height: 1.3 !important;
            text-transform: initial !important;
        }
        .MuiTabs-indicator{
            left: 0 !important;
            width: 100% !important;
        }
        .MuiTabs-scroller {
            .MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.list-nested:hover {
                background-color: $secondary !important;
                color: $color-white !important;
            }
        }
    }
    .MuiTabs-root .MuiTabs-scroller .MuiTab-textColorPrimary.Mui-selected {
        color: $color-white !important;
    }
    
    //RADIO BUTTON COMPONENT
    span[class*=PrivateSwitchBase-root-]{
        padding: 6px !important;
    }

    //REACT TABLE
    .ReactTable .rt-thead .rt-resizable-header-content {
        font-size: 12px !important;
    }

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    ::-webkit-scrollbar-track {
      background-color: #e6e6e6;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: $secondary;
      border-radius: 10px;
    }
 }