@import "../../utility.scss";



@mixin fixedColumnTHDesign {
  color: $secondary;
  text-align: left;
  padding-left: 50px;
  width: 300px !important;
  max-width: 300px !important;
  border-right: 2px solid $secondary;
  &.maxWidth150 {
    max-width: 150px !important;
    padding-left: 0;
    text-align: center;
    & ~ .rthfc-th-fixed,
    & ~ .rthfc-td-fixed {
      left: 150px !important;
    }
  }
  .rt-resizer {
    display: none;
  }
}

@mixin fixedColumnTHDesignPABValidation {
  color: $secondary;
  // text-align: left;
  // padding-left: 10px;
  text-align: center;
  width: 120px !important;
  max-width: 120px !important;
  border-right: 2px solid $secondary;
  &.maxWidth150 {
    max-width: 150px !important;
    padding-left: 0;
    text-align: center;
    & ~ .rthfc-th-fixed, & ~ .rthfc-td-fixed {
      left: 150px !important;
    }
  }
  .rt-resizer {
    display: none;
  }
}

@mixin fixedColumnTDDesign {
  @include fixedColumnTHDesign;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 500;
}

@mixin fixedColumnTDDesignPABValidation {
  @include fixedColumnTHDesignPABValidation();
  text-align: center;
  margin-left: 0px;
  padding-left: 0px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-weight: 500;
  .totalLabel{
    font-weight: bold;
    font-size: 13px;
  }
}

.MuiPopover-root .MuiMenu-paper {
  max-height: 175px;
}

.ocos-by-country {
  .rt-thead {
    max-height: none !important;
    .rt-th {
      line-height: 25px !important;
    }
  }
}

.newCustomTable {
  margin-top: 10px;
  width: 100%;
  border-radius: 5px !important;
  background-color: $color-white;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  border: 0px none;
  .rt-table {
    @include customScrollBar;
    border-radius: 5px;
    .percentageRowData {
      background-color: $color-pastel-blue-light;
      display: block;
    }
    .enableDrillDown {
      cursor: pointer;
    }
    .colorRowData {
      background-color: $color-pastel-blue-light;
      display: block;
    }
  }

  .rt-thead {
    background-color: $color-pastel-blue-light;
    box-shadow: none !important;
    max-height: 35px;
    .rt-th {
      line-height: 35px;
      padding: 0;
      font-weight: 500;
      font-size: 13px;
      border: 0px none;
      // height: 30px;
      color: $color-koamaru-deep !important;
      background: $color-pastel-blue-light !important;
      box-shadow: none !important;
    }

    .rt-th:last-child,
    .ReactTable .rt-thead .rt-td:last-child {
      border-right: 0 !important;
    }
  }
  .rt-tfoot {
    background-color: $color-pastel-blue-light;
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      border-bottom: 0px none;
      .rt-td {
        font-size: 12px;
        text-align: center;
        line-height: 33px;
        padding: 0;
        border-right: 0px none;
        border-bottom: 1px solid $color-pastel-blue-light;
        box-shadow: none !important;
      }
    }
  }

  .rt-tbody .rt-td:last-child {
    border-right: 0 !important;
  }

  &.xScrollHidden {
    .rt-tbody {
      overflow: initial;
    }
  }
  &.heightOverFlowAllowed {
    > .rt-table {
      > .rt-tbody {
        max-height: initial !important;
      }
    }
  }
}
.newCustomTable.fixedFirstColumTableLastRowWhite {
  .rt-thead {
    .rt-th {
      &:first-child {
        @include fixedColumnTHDesign;
      }
    }
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &:first-child {
          @include fixedColumnTDDesign;
        }
      }
    }
  }
  > .rt-table {
    > .rt-tbody {
      max-height: calc(100vh - 400px);
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            background-color: rgb(255, 255, 255);
            &:first-child {
              .rt-expander {
                display: none;
              }
            }
            &:nth-child(2) {
              color: $secondary;
              // font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.newCustomTable.fixedFirstColumTable {
  .rt-thead {
    .rt-th {
      &:first-child {
        @include fixedColumnTHDesign;
      }
    }
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &:first-child {
          @include fixedColumnTDDesign;
        }
      }
    }
  }
  > .rt-table {
    > .rt-tbody {
      max-height: calc(100vh - 400px);
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            background-color: $color-pastel-blue-light;
            &:first-child {
              .rt-expander {
                display: none;
              }
            }
            &:nth-child(2) {
              color: $secondary;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}


.newCustomTable.fixedFirstColumTableSummaryView {
  .rt-thead {
    .rt-th {
      &:first-child {
        @include fixedColumnTHDesign;
      }
    }
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &:first-child {
          @include fixedColumnTDDesign;
        }
      }
    }
  }
  > .rt-table {
    > .rt-tbody {
      max-height: calc(100vh - 400px);
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            background-color:white;
            // &:first-child {
            //   .rt-expander {
            //     display:inline-block;
            //   }
            // }
            &:nth-child(2) {
              color: $secondary;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}



.orderbook{
  .rt-tbody{
    .rt-tr-group{
      .rt-tr{
        .rt-td:nth-child(2), .rt-td:nth-child(3){
    white-space: nowrap!important;
    overflow: hidden!important;
    text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp: 1;
    // -webkit-box-orient: vertical;
    padding-left:  10px!important;
    padding-right:  10px!important;
  }
}
}
}
}

.orderbook{
  .rt-tbody{
    .rt-tr-group{
      .rt-tr{
        .rt-td:nth-child(2):hover, .rt-td:nth-child(3):hover{
          white-space: break-spaces!important;
          overflow: visible!important;
        }
    }
  }
}
}


.newCustomTable.noFixtColumTable {
  > .rt-table {
    > .rt-tbody {
      max-height: calc(100vh - 400px);
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            background-color: rgb(223, 217, 251);
            &:first-child {
              .rt-expander {
                display: none;
              }
            }
            &:nth-child(2) {
              color: $secondary;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
.newCustomTable.twoColumFixedTable {
  .rt-thead {
    .rt-th {
      &.rthfc-th-fixed {
        @include fixedColumnTHDesign;
      }
      &:nth-child(2).rthfc-th-fixed {
        left: 300px !important;
      }
    }
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &.rthfc-td-fixed {
          @include fixedColumnTDDesign;
        }
        &:nth-child(2).rthfc-td-fixed {
          left: 300px !important;
        }
      }
    }
  }
  > .rt-table {
    > .rt-tbody {
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            &.rthfc-td-fixed {
              .rt-expander {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.newCustomTable.fourColumFixedTable {
  .rt-thead {
    .rt-th {
      &.rthfc-th-fixed {
        @include fixedColumnTHDesignPABValidation;
      }
      &:nth-child(2).rthfc-th-fixed {
        left: 120px !important;
      }
      &:nth-child(3).rthfc-th-fixed {
        left: 240px !important;
      }
      &:nth-child(4).rthfc-th-fixed {
        left: 360px !important;
      }
    }
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &.rthfc-td-fixed {
          @include fixedColumnTDDesignPABValidation();
        }
        &:nth-child(2).rthfc-td-fixed {
          left: 120px !important;
        }
        &:nth-child(3).rthfc-td-fixed {
          left: 240px !important;
        }
        &:nth-child(4).rthfc-td-fixed {
          left: 360px !important;
        }
      }
    }
  }
  > .rt-table {
    > .rt-tbody {
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            &.rthfc-td-fixed {
              .rt-expander {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
.newCustomTable.fixedFirstColumTable.expandableRows.plantView{
  .rt-thead {
    .rt-tr{
      width: fit-content;
    }
    .rt-th.rt-resizable-header:nth-child(n+3) {
      width: 200px !important;
          max-width: 200px !important;
    }
  }

  .rt-tbody {
    width: fit-content;
    .rt-tr-group {
    .rt-td:nth-child(n+3) {
        width: 200px !important;
            max-width: 200px !important;
      }
    }
  }
}

.newCustomTable.fixedFirstColumTable.expandableRows {
  .rt-tr[data-qnt="0"] {
    .rt-expander {
      display: none;
    }
  }

  .rt-expander {
    border: 1px solid #423970;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 08px;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #423970;
    }
  }
  .rt-thead {
    .rt-th {
      &:first-child {
        width: 50px !important;
        max-width: 50px !important;
        padding-left: 0;
        position: sticky;
        left: 0 !important;
        z-index: 99;
        border-right: 0px none;
        text-align: center;
      }
      &:nth-child(2) {
        color: $secondary;
        text-align: left;
        padding-left: 3px;
        width: 300px !important;
        max-width: 300px !important;
        border-right: 2px solid $secondary;
        left: 50px !important;
        .rt-resizer {
          display: none;
        }
      }
    }
  }
  .rt-tbody {
    .rt-tr-group {
      .rt-td {
        &:first-child {
          width: 50px !important;
          max-width: 50px !important;
          padding-left: 0;
          position: sticky;
          left: 0 !important;
          border-right: 0px none;
          text-align: center;
        }
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          width: 300px !important;
          max-width: 300px !important;
          border-right: 2px solid $secondary;
          left: 50px !important;
          // white-space: normal;
          .rt-resizer {
            display: none;
          }
        }
      }
    }
    .bupl-SubTable {
      border: 0px none;
      .rt-table {
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
      .rt-tr-group {
        .rt-td {
          &:first-child {
            width: 75px !important;
            max-width: 75px !important;
          }
          &:nth-child(2) {
            width: 275px !important;
            max-width: 275px !important;
            left: 75px !important;
          }
        }
      }
      .bupl-SubTable {
        .rt-tr-group {
          .rt-td {
            &:first-child {
              width: 100px !important;
              max-width: 100px !important;
            }
            &:nth-child(2) {
              width: 250px !important;
              max-width: 250px !important;
              left: 100px !important;
            }
          }
        }
        .bupl-SubTable {
          .rt-tr-group {
            .rt-td {
              &:first-child {
                width: 125px !important;
                max-width: 125px !important;
              }
              &:nth-child(2) {
                width: 225px !important;
                max-width: 225px !important;
                left: 125px !important;
              }
            }
          }
          .bupl-SubTable {
            .rt-tr-group {
              .rt-td {
                &:first-child {
                  width: 150px !important;
                  max-width: 150px !important;
                }
                &:nth-child(2) {
                  width: 200px !important;
                  max-width: 200px !important;
                  left: 150px !important;
                }
              }
            }
            .bupl-SubTable {
              .rt-tr-group {
                .rt-td {
                  &:first-child {
                    width: 175px !important;
                    max-width: 175px !important;
                  }
                  &:nth-child(2) {
                    width: 175px !important;
                    max-width: 175px !important;
                    left: 175px !important;
                  }
                }
              }
              .bupl-SubTable {
                .rt-tr-group {
                  .rt-td {
                    &:first-child {
                      width: 200px !important;
                      max-width: 200px !important;
                    }
                    &:nth-child(2) {
                      width: 150px !important;
                      max-width: 150px !important;
                      left: 200px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



.newCustomTable.fixedFirstColumTableSummaryView.expandableRows {
  .rt-tr[data-qnt="0"] {
    .rt-expander {
      display: none;
    }
  }

  .rt-expander {
    border: 1px solid #423970;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 08px;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #423970;
    }
  }
  .rt-thead {
    .rt-th {
      &:first-child {
        width: 50px !important;
        max-width: 50px !important;
        padding-left: 0;
        position: sticky;
        left: 0 !important;
        z-index: 99;
        border-right: 0px none;
        text-align: center;
      }
      &:nth-child(2) {
        color: $secondary;
        text-align: left;
        padding-left: 3px;
        width: 300px !important;
        max-width: 300px !important;
        border-right: 2px solid $secondary;
        left: 50px !important;
        .rt-resizer {
          display: none;
        }
      }
    }
  }
  .rt-tbody {
    .rt-tr-group {
      .rt-td {
        &:first-child {
          width: 50px !important;
          max-width: 50px !important;
          padding-left: 0;
          position: sticky;
          left: 0 !important;
          border-right: 0px none;
          text-align: center;
        }
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          width: 300px !important;
          max-width: 300px !important;
          border-right: 2px solid $secondary;
          left: 50px !important;
          // white-space: normal;
          .rt-resizer {
            display: none;
          }
        }
      }
    }
    .bupl-SubTable {
      border: 0px none;
      .rt-table {
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
      .rt-tr-group {
        .rt-td {
          &:first-child {
            width: 75px !important;
            max-width: 75px !important;
          }
          &:nth-child(2) {
            width: 275px !important;
            max-width: 275px !important;
            left: 75px !important;
          }
        }
      }
      .bupl-SubTable {
        .rt-tr-group {
          .rt-td {
            &:first-child {
              width: 100px !important;
              max-width: 100px !important;
            }
            &:nth-child(2) {
              width: 250px !important;
              max-width: 250px !important;
              left: 100px !important;
            }
          }
        }
        .bupl-SubTable {
          .rt-tr-group {
            .rt-td {
              &:first-child {
                width: 125px !important;
                max-width: 125px !important;
              }
              &:nth-child(2) {
                width: 225px !important;
                max-width: 225px !important;
                left: 125px !important;
              }
            }
          }
          .bupl-SubTable {
            .rt-tr-group {
              .rt-td {
                &:first-child {
                  width: 150px !important;
                  max-width: 150px !important;
                }
                &:nth-child(2) {
                  width: 200px !important;
                  max-width: 200px !important;
                  left: 150px !important;
                }
              }
            }
            .bupl-SubTable {
              .rt-tr-group {
                .rt-td {
                  &:first-child {
                    width: 175px !important;
                    max-width: 175px !important;
                  }
                  &:nth-child(2) {
                    width: 175px !important;
                    max-width: 175px !important;
                    left: 175px !important;
                  }
                }
              }
              .bupl-SubTable {
                .rt-tr-group {
                  .rt-td {
                    &:first-child {
                      width: 200px !important;
                      max-width: 200px !important;
                    }
                    &:nth-child(2) {
                      width: 150px !important;
                      max-width: 150px !important;
                      left: 200px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.newCustomTable.lastRowDefaultBGColor {
  .rt-tbody {
    .rt-tr-group:last-child {
      .rt-td {
        background-color: #fff !important;
        &:nth-child(2) {
          font-weight: initial !important;
        }
      }
    }
  }
}
.newCustomTable {
  .ReactTable.bupl-SubTable {
    .rt-table {
      overflow: inherit !important;
    }
  }
  .commentContainerTable {
    margin-top: 7px;
    margin-right: 5px;
    margin-bottom: 7px;
    .commentField {
      height: 60px;
    }
    .uploadedFilePreview {
      margin: 10px 10px 0;
    }
    .commentArea {
      margin-top: 7px;
      height: 50px;
      font-size: 12px;
      overflow-y: auto;
      @include customScrollBar;
      > div {
        margin-bottom: 5px;
        a {
          color: $primary-dark;
        }
      }
      .date {
        font-size: 80%;
        color: $color-gray;
      }
    }

    .commentInputSection {
      padding: 0px 10px;
      .MuiTextField-root {
        width: 80%;
        text-align: center;
        .MuiFormLabel-root {
          font-size: 12px;
        }
        .MuiInputBase-root {
          // height: 30px;
          border-radius: 20px;
          padding: 10px;
          font-size: 12px;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
        }
      }
      .MuiButtonBase-root {
        .material-icons {
          font-size: 20px;
        }
      }
    }
  }
}

.total-sales-report{
  .rt-td, .rt-th{
    span, div{
    white-space: break-spaces;
    word-break: break-word;
    overflow: "visible";
    }
  }
}

.modalData {
  .ReactTable{
    box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
    border-radius: 5px;
    .rt-table {
      @include customScrollBar;
    }
    .rt-thead {
      .-header {
        box-shadow: unset;
      }

      .rt-tr {
        text-align: center;
        color: $color-koamaru-deep !important;
        background: $color-pastel-blue-light !important;
        border-bottom: 0px none;
      }

      .rt-resizable-header {
        overflow: visible;
        border-right: unset;
        border-bottom: unset;
      }
    }

    .rt-tbody {
      overflow-x: hidden !important;
      .rt-tr-group {
        border-bottom: unset !important;
        .rt-td {
          border-bottom: solid 1px rgba(0, 0, 0, 0.1);
          border-right: unset;
        }
      }
    }
  }
}

.budgetAnalysis {
  @include flexbox();
  > div {
    &:first-child {
      width: 300px;
      margin-right: 20px;
    }
    &:last-child {
      flex-grow: 1;
    }
  }
}

.multi-gl-container {
  table {
    // width: calc(100vw - 100px) !important;
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    //thead {
      // width: calc(100% - 1em);
    //}
    tbody {
      max-height: calc(100vh - 350px) !important;
      overflow: auto;
      display: block;
      tr.hasChild {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05) !important;
        td {
          div {
            display: none;
          }
          border-right: 0px;
          &:nth-child(2) {
            div {
              position: relative;
              left: 40vw;
              width: 100px;
              font-weight: bold;
              font-size: 17px;
              display: table;
            }
          }
        }
      }
      td {
        width: auto;
        div {
          font-size: 12px;
          width: auto;
          white-space: normal;
          overflow: hidden;
        }
        div:hover {
          overflow: visible;
          overflow-wrap: break-word;
        }
      }
      max-height: calc(100vh - 350px);
    }
  }
}
.multi-gl-container-one-new-chart {
  overflow-x: auto;
  table {
    // width: calc(100vw - 100px) !important;
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    thead {
        padding-right: 17px;
      th{
        width: 100px;
      }
      // width: calc(100% - 1em);
      th:last-child {
        width: 250px;
      }
      th:nth-child(2) {
        width: 60px;
      }
    }
    tbody {
      max-height: calc(100vh - 350px) !important;
      overflow-x: auto;
      overflow-y: scroll;
      display: block;
      tr.hasChild {
        cursor: pointer;
        // width: 100px;
        background-color: rgba(0, 0, 0, 0.05) !important;
      }
      td {
        width: 100px;
        div {
          font-size: 12px;
          width: auto;
          white-space: normal;
          overflow: hidden;
        }
        div:hover {
          overflow: visible;
          overflow-wrap: break-word;
        }
      }
      td:last-child {
        width: 250px;
      }
      td:nth-child(2) {
        width: 60px;
      }

      max-height: calc(100vh - 350px);
    }
  }
  .dropDownSection {
    width: 50px;
  }
  .commentSection {
    // width: 300px;
    display: flex;
    align-items: center;
    border: 1px solid grey;
    padding: 15px;
    background: rgba(0, 0, 0, 0.05);
    .comments {
      height: 95px;
      overflow: auto;
      padding-right: 20px;
      margin-right: 20px;
      flex-grow: 1;
      @include customScrollBar;
      h6 {
        font-size: 12px;
      }
      p {
        margin-top: 0;
      }
    }
  }
  &.isWindows {
    table {
      tbody {
        td:last-child {
          width: 250px;
        }
      }
    }
  }
}

.customer-group-drilldown{
  thead {
  th:nth-child(2) {
    width: 160px!important;
  }
}
  tbody {
    td:nth-child(2) {
      width: 160px!important;
    }
  }
}

.multi-gl-container-one-new {
  table {
    // width: calc(100vw - 100px) !important;
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    thead {
      padding-right: 2px;
      // width: calc(100% - 1em);
      th {
        div {
          span {
            font-size: 12px !important;
            width: auto;
            white-space: normal;
            overflow: hidden;
          }
        }
      }
      th:last-child {
        width: 250px;
      }
      th:nth-child(2) {
        width: 60px;
      }
    }
    tbody {
      max-height: calc(100vh - 350px) !important;
      overflow-x: auto;
      overflow-y: scroll;
      display: block;
      tr.hasChild {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05) !important;
      }
      td {
        width: auto;
        div {
          font-size: 12px;
          width: auto;
          white-space: normal;
          overflow: hidden;
        }
        div:hover {
          overflow: visible;
          overflow-wrap: break-word;
        }
      }
      td:last-child {
        width: 235px !important;
      }
      td:nth-child(2) {
        width: 60px;
      }

      max-height: calc(100vh - 350px);
    }
  }
  .dropDownSection {
    width: 50px;
  }
  .commentSection {
    // width: 500px;
    display: flex;
    align-items: center;
    border: 1px solid grey;
    padding: 15px;
    background: rgba(0, 0, 0, 0.05);
    .comments {
      height: 95px;
      overflow: auto;
      padding-right: 20px;
      margin-right: 20px;
      flex-grow: 1;
      @include customScrollBar;
      h6 {
        font-size: 12px;
      }
      p {
        margin-top: 0;
      }
    }
  }
  &.isWindows {
    table {
      tbody {
        td:last-child {
          width: 485px;
        }
      }
    }
  }
}
.multi-gl-container-one-new-chartv2 {
  table {
    // width: calc(100vw - 100px) !important;
    thead,
    tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; /* even columns width , fix width of table too*/
    }
    thead {
      padding-right: 2px;
      // width: calc(100% - 1em);
      th:last-child {
        width: 350px;
      }
      th:nth-child(2) {
        width: 60px;
      }
    }
    tbody {
      max-height: calc(100vh - 350px) !important;
      overflow-x: auto;
      overflow-y: scroll;
      display: block;
      tr.hasChild {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05) !important;
      }
      td {
        width: auto;
        div {
          font-size: 12px;
          width: auto;
          white-space: normal;
          overflow: hidden;
        }
        div:hover {
          overflow: visible;
          overflow-wrap: break-word;
        }
      }
      td:last-child {
        width: 350px;
      }
      td:nth-child(2) {
        width: 60px;
      }

      max-height: calc(100vh - 350px);
    }
  }
  .dropDownSection {
    width: 50px;
  }
  .commentSection {
    // width: 500px;
    display: flex;
    align-items: center;
    border: 1px solid grey;
    padding: 15px;
    background: rgba(0, 0, 0, 0.05);
    .comments {
      height: 95px;
      overflow: auto;
      padding-right: 20px;
      margin-right: 20px;
      flex-grow: 1;
      @include customScrollBar;
      h6 {
        font-size: 12px;
      }
      p {
        margin-top: 0;
      }
    }
  }
  &.isWindows {
    table {
      tbody {
        td:last-child {
          width: 335px;
        }
      }
    }
  }
}


.chart-div {
  max-height: calc(100vh - 275px);
  margin-bottom: 10px !important;
  background: #fff;
  margin-top: 10px !important;
  padding: 0px !important;
  .top-chart {
    height: calc((100vh - 300px) / 2);
  }
  .border-top-gray {
    border-top: 5px solid #f0f0f0;
    height: calc((100vh - 300px) / 2);
  }

  div[id^="highcharts-"] {
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
  }

  .highcharts-background,
  .highcharts-root,
  .highcharts-container {
    max-height: calc((100vh - 300px) / 2);
    max-width: 100%;
  }
}

.country-view-info {
  color: #654193 !important;
  margin-bottom: 5px;
  margin-left: 30px;
}

.NewAnalyticsBoxDesign {
  border: 1px solid $primary;
  padding: 8px 16px;
  background: $color-white;
  border-radius: 8px;
}

.fa-cell-header, .emp-cell-header{
  width : 200px!important;
}

.fa-cell-body{
  width: 200px !important;
  text-align: left!important;
  padding-left: 10px!important;
}

.emp-cell-body{
  width: 200px !important;
  padding-left: 10px!important;
}


.accordion-head {
  // background-color: #cc66ff !important;
  background-color: #8306ce !important;
  color: white !important;
  min-height: 35px !important;
  height: 40px !important;
  .MuiAccordionSummary-content {
    .MuiTypography-root {
      font-size: 12px !important;
    }
  }
}

.accordion-item {
  height: 12px;
  padding: 15px 16px 30px 16px!important;
  font-size: 12px;
}

.accordion-item:hover {
  background-color: #c0c0c0;
}

.ad-hoc-label {
  padding: 0 0 8px 0 !important;
  margin-bottom: 20px !important;
}

.blockDesignSidebar {
  .header.ad-hoc-label {
    margin: 0 -20px;
    .ad-hoc-label-text {
      margin: 0 24px;
    }
  }
  
}


.back-button{
  height: 12px;
  min-width: 30px!important;
  padding: 14px 0 !important;
  font-size: 12px;
  border-radius: 15px!important;
}

.dass-aop-tornado-container {
  margin: 20px 0;
}

.dass-aop-tornado-item {
  border: solid black 1px;
}

.revenue-geo-table {
  height: 500px;
}