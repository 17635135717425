.svg-icon-black{
    filter: invert(11%) sepia(51%) saturate(0%) hue-rotate(199deg) brightness(100%) contrast(87%)
}

.svg-icon-white{
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%)
}

.svg-icon-orange{
    filter: invert(77%) sepia(69%) saturate(6401%) hue-rotate(346deg) brightness(97%) contrast(91%);
}