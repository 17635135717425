
.report-chart-analyticStudio{
    background-color: white;
    margin: 2rem 0;
}

.report-chart-analyticStudio-header{
    margin: 0 0.5rem;
}

.analytic-studio-chart{

}