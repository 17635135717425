.ReactTable .rt-tr-group {
    min-height: 50px; 
  }
  
  .ReactTable .rt-tr {
    height: 50px; 
    line-height: 50px;
  }

  .height-unset{
    height: unset !important;
    max-height: 500px;
    overflow: scroll;
  }


.segment-container{
    height: 650px;
    display: flex;
}