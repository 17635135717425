@import '../../../utility.scss';
@import '../IncomeStatement.scss';


.dataValidation {
  .MuiTabs-root a {
    background-color: $color-koamaru-deep !important;
  }
  .MuiFormGroup-root {
    display: flex;
    flex-basis: 100%;
    flex-wrap: initial !important;
    flex-direction: initial !important;
    margin-bottom: 10px;
  }

  .MuiFormControlLabel-root {
    .MuiRadio-root {
      display: none;
    }
    margin-left: 0 !important;
    .MuiFormControlLabel-label {
      display: inline-block;
      min-width: 200px;
      padding: 5px;
      text-align: center;
      border: 1px solid grey;
    }
    .Mui-checked ~ span {
      background-color: $color-koamaru-deep;
      color: #fff;
    }
  }
}

.hfmDataTable {
  .tableHeader {
    margin: 30px 0;
    display: flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: space-between;
    span {
      font-weight: 500;
      margin-right: 50px;
    }
    hr {
      flex-grow: 1;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  .ReactTable {

    .rt-table{
      //@include customScrollBar;
    }

    .rt-thead {

      .rt-th:first-child {
        color: grey !important;
      }

      .rt-resizable-header-content {
        color: $color-koamaru-deep !important;
        // line-height: 35px !important;
      }

      .rt-th { 
        border-right: none;
        box-shadow: none;
        background: $color-pastel-blue-light !important;
      }
      
    }

  }

  .commentSection {
    display: flex;
    align-items: center;
    border: 1px solid grey;
    padding: 15px;
    background: rgba(0, 0, 0, 0.05);
    .comments {
      height: 95px;
      overflow: auto;
      padding-right: 20px;
      margin-right: 20px;
      flex-grow: 1;
      //@include customScrollBar;
      h6 {
        font-size: 12px;
      }
      p {
        margin-top: 0;
      }
    }
  }
}

.xOverFlowHidden {

  .ReactTable .rt-tbody {
    overflow-x: hidden !important;
    }
}

.firstChildBorder {
  
  .ReactTable .rt-thead .rt-th:first-child{
    border-right: 2px solid $color-koamaru-deep;
  }
}