@import "../../utility.scss";

.customModal.automationModal {
  max-width: 650px;
  .report-date-picker {
    margin-top: 0.5rem;
  }
  table {
    width: 100%;
    tr {
      text-align: center;
      td,
      th {
        &:first-child {
          border-right: 0px none;
          .MuiIconButton-root {
            padding: 0;
          }
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
      th {
        font-weight: 500;
      }
      td {
        font-size: 13px;
        &:nth-child(2) {
          span {
            display: block;
          }
        }
      }
    }
  }
}

.roleTable {
  
  .rt-thead .rt-th:first-child {
   width: 200px !important;
   border-right: 2px solid $color-blue-mostly-dark;
   position: sticky;
    }

     .rt-tbody .rt-tr-group .rt-td:first-child, .rt-tbody .rt-tr .rt-td:first-child,  .rt-tfoot .rt-tr-group .rt-td:first-child, .rt-tfoot .rt-tr .rt-td:first-child {
     width: 200px !important;
     border-right: 2px solid $color-blue-mostly-dark  !important;
     position: sticky;
    }

    .rt-tfoot {
     box-shadow: none !important; 
    } 

}

.dsoTable {
  .rt-thead .rt-resizable-header-content {
    overflow: visible;
    white-space: break-spaces;
    text-overflow: ellipsis;
    padding-left: 5px;
    flex: 200 0 auto;
    max-width: 200px;
    line-height: 18px;
}
}

.iconStyle {

  svg.MuiSvgIcon-root.primary-dark.MuiSvgIcon-fontSizeSmall {
    margin-top: 6px;
}
  .PrivateSwitchBase-root-16 {
    padding: 0px;
}
}

.payment-dropzone {
  min-height: 0px !important;
  .MuiTypography-h5 {
    font-size: 1rem;
    color: grey;
  }
  .MuiDropzoneArea-icon {
    color: grey;
  }
  .MuiDropzonePreviewList-imageContainer {
    .MuiTypography-body1 {
      font-size: 0.7rem;
    }
  }
  .MuiDropzonePreviewList-imageContainer {
    margin-top: -12%;
    margin-left: 2%;
    padding-bottom: 10% !important;
  }
  outline: none;
}

.customModal.roleMasterModal {
  max-width: 680px;
  table {
    width: 100%;
    tr {
      text-align: center;
      td,
      th {
        &:first-child {
          border-right: 0px none;
          .MuiIconButton-root {
            padding: 0;
          }
        }
        &:nth-child(2) {
          text-align: left;
        }
      }
      th {
        font-weight: 500;
      }
      td {
        font-size: 13px;
        &:nth-child(2) {
          span {
            display: block;
          }
        }
      }
    }
  }
}

.customModal.addUserModal {
  max-width: 650px;
}
