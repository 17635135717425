.customModal {
    // width: 40% !important;
    // .modal-content{
    //     padding: 0px !important;
    // }
}

.main-header-div{
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: 15px;
    .main-header{
        margin-top: 0px;
        margin-right: 10px;
        width: 150px;
    }
    .dropdown2-select{
        width: 150px;
    }
}


.sub-section {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

    .compare-what-section, .compare-with-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        .sub-header-div{
            margin-top: 5px;
            margin-bottom: 30px;
            .dropdown2-select{
                width: 150px;
            }
        }
        .sub-header-list{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            .dropdown2-select{
                width: 150px;
            }
        }
    }
}

.MuiPopover-root .MuiMenu-paper{
    width: 150px;
}