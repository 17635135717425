@import "../../../../utility.scss";

:root {
	--fr-column-width: 200px
}

.summary-table-pab{
	height: 60vh;
	overflow: scroll;
	.newNewCustomTable {
		thead {
			 position: sticky; top: 0; z-index: 4;
			tr{
				th:nth-child(1),
				th:nth-child(2) {/*first cell in the header*/
				  min-width: var(--fr-column-width) !important;
				  position: sticky !important;
				}
				th:nth-child(1){
					// width: 150px;
					width: var(--fr-column-width);
				    text-align: center;
				    left: 0px;
				}
				th:nth-child(2){
					// width: 150px;
					width: var(--fr-column-width);
				    // left: 150px;
				    left: 200px !important;
				}

				th.header-border-right:nth-child(1),
				th.header-border-right:nth-child(2){
					border-bottom: unset !important;
					border-right: 1px solid #423970 !important;
				}
				// th.header-border-right:nth-child(4){
				// 	border-bottom: unset !important;
				// 	border-right: 2px solid #423970 !important;
				// }
			}
		}
		tbody {
			tr{
				td:nth-child(1),
				td:nth-child(2) {/*first cell in the header*/
				//   min-width: 150px !important;
				  min-width: var(--fr-column-width) !important;
				  position: sticky !important;
				  background-color: #fff;
				  text-align: center;
				  padding: 0px;
				}
				td:nth-child(1){
					// width: 150px;
					width: var(--fr-column-width);
				    // left: 0px;
				    border-right: unset !important;
				    left: 0px !important;
				}
				td:nth-child(2){
					// width: 150px;
					width: var(--fr-column-width);
				    // left: 450px;
				    left: 200px !important;
				    border-right: 1px solid #423970 !important;
				}
			}
		}
	}
	
}

.summary-table {
	height: 65vh;
}

.frontlog-container {
	margin-top: 20px;
	.table-container {
		height: 50vh;
		.MuiGrid-root {
			height: 100%;
			.ReactTable {
				height: 100%;
				table-layout: fixed;
				.rt-thead { 
					flex: unset;
				}		
			}
		}
	}
}