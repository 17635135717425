// aesl = add-edit-simulation-logic
.aesl-section {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: calc(100vh - 130px);
    margin-bottom: 30px;

    .aesl-header-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        .aesl-header-section-left {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            #simulation-name {
                width: 200px;
                background-color: #ffffff;
            }
        }

        .aesl-header-section-right {
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
    }

    .aesl-table-section{
        padding: 15px 10px;
        margin-left: 0px;
        min-height: 100px;
    }

    .normalize-actual-tooltip{
        margin-left: 5px;
        padding-top: 5px;
        margin-bottom: -5px;
    }

    .aesl-content-section {
        display: flex;
        flex: 1;

        .aesl-content-menu-section {
            background-color: #F4F6F9;

            .aesl-content-menu-section-wrapper {
                width: 100%;
                height: 80vh;
                overflow-y: auto;
            }
        }

        .aesl-content-main-section {
            .aesl-content-main-section-wrapper {
                min-height: 100px;
                width: 100%;
                background-color: rgb(224, 223, 223);
                padding: 15px 10px;
                margin-left: 10px;

                .draggable-section {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    height: 40px;
                    background-color: #ffffff;
                    border: 1px solid rgb(202, 202, 202);
                    border-radius: 3px;
                    overflow: hidden;

                    .right {
                        display: flex;
                        flex: 1;
                        flex-direction: row;
                        align-items: center;
                        overflow-y: auto;
                        padding: 0 4px;

                        /* width */
                        &::-webkit-scrollbar {
                            width: 3px;
                            height: 3px;
                        }

                        /* Track */
                        &::-webkit-scrollbar-track {
                            background: #f1f1f1;
                        }

                        /* Handle */
                        &::-webkit-scrollbar-thumb {
                            background: #888;
                            border-radius: 10px;
                        }

                        /* Handle on hover */
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }

                        .MuiChip-root {
                            margin: 0 1px;
                        }
                    }
                }
            }
        }
    }
}


.new-autocomplete {
    .MuiAutocomplete-inputRoot {
        padding-right: 25px !important;
        font-size: 14px !important;
    }

    .MuiAutocomplete-endAdornment{
        display: block !important;
        .MuiAutocomplete-clearIndicator{
            display: none !important;
        }
    }
}