$color-koamaru-deep : #062565;
$color-pastel-blue : #b1bace;
$color-pastel-blue-light : #DEE3EC;


.simulation-tabel{
    .MuiFormControl-fullWidth{
        padding: 5px;
    }

     .MuiOutlinedInput-input {
        padding-top: 4px;
        padding-bottom: 4px;
        font-size: 14px;

    }
}


.menu-container {
    background-color: #F4F6F9 !important;

    .side-menu-container {
        background-color: #F4F6F9 !important;
        width: 100%;
        height: 85vh;
        overflow: scroll;
    }
}

.rt-main-header{
    margin-left: -20px;
}

.right-chart-label{
   left: calc(53vw - 210px)!important; 
}

.left-chart-label{
    left: calc(45.8vw - 210px)!important;
    width: 100px;
    span{
        left: unset!important;
        right: 0px;
    }
 }

.top-header{
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.main-container {
    .main-container-wrapper {
        margin-left: 10px;
        width: calc(100% - 10px);
        height: 100%;

        .columns-draggable-section,
        .rows-draggable-section {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 40px;
            background-color: #ffffff;
            border: 1px solid rgb(202, 202, 202);
            border-radius: 3px;

            .left {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 110px;
                border-right: 1px solid rgb(202, 202, 202);

                .row-column-icon {
                    font-size: 20px;
                    margin-left: 10px;
                    cursor: unset !important;
                }

                .text {
                    font-size: 12px;
                    // font-weight: 500;
                    line-height: normal;
                    margin-left: 10px;
                }
            }

            .right {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                overflow-y: auto;
                padding: 0 4px;

                /* width */
                &::-webkit-scrollbar {
                    width: 3px;
                    height: 3px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #888;
                    border-radius: 10px;
                }

                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }

                .MuiChip-root {
                    margin: 0 1px;
                }
            }
        }

        .columns-draggable-section .row-column-icon {
            rotate: 90deg;
        }

        .rows-draggable-section .MuiChip-colorSecondary {
            background-color: #ff9800;
        }

        .rows-draggable-section .MuiChip-colorSecondary {
            background-color: #ff9800;
        }
    }

    .newNewCustomTable {
        tbody {
            tr {
                td:first-child {
                    position: sticky !important;
                    z-index: 3 !important;
                    left: 0px !important;
                }
            }
        }
    }
    
}

.ebitdaCustomTable.fixedFirstTwoColumTable {
    width: 100%;
	border-collapse: separate;
    thead {
		tr {
			th {
				min-width: 100px;
				line-height: 35px;
				padding: 0;
				font-weight: bold;
				font-size: 13px;
				border: 0;
				color: $color-koamaru-deep;
				background: $color-pastel-blue-light;
				box-shadow: none;

				&:nth-child(1){
					position: sticky;
					z-index: 3;
					left: 0px;
					min-width: 300px;
					//border-right: 1px solid $color-koamaru-deep;
					background-color: $color-pastel-blue-light !important;
				}

                &:nth-child(2){
                    border-right: 2px solid $color-koamaru-deep !important;
                    z-index: 3;
                    position: sticky;
					z-index: 3;
					left: 300px;
                    background-color: $color-pastel-blue-light !important;
                }

				&.header-border-right:not(:nth-child(1)):not(:nth-child(2)){
					border-right: 1px solid $color-koamaru-deep !important;
					border-bottom: 1px solid $color-pastel-blue-light !important;
				}
			}
		}
	}

	tbody {
		tr {
			&:nth-child(even){
				background-color: #ffffff;
			}

			&:nth-child(odd){
				background-color: #F7F7F7;
			}
			
			td {
				font-size: 12px;
				text-align: center;
				line-height: 33px;
				padding: 0;
				border-right: 0;
				border-bottom: 1px solid $color-pastel-blue-light;
				box-shadow: none!important;
				
				&:first-child {
                    position: sticky !important;
                    z-index: 3 !important;
                    left: 0px !important;
					text-align: left;
					padding-left: 50px;
					width: 300px!important;
					max-width: 300px!important;
					// border-right: 1px solid #423970;
					color: rgba(0,0,0,.87);
					font-size: 12px;
					font-weight: 500;
					background-color: #ffffff !important;
				}

                &:nth-child(2) {
                    position: sticky !important;
                    z-index: 3 !important;
                    left: 300px !important;
					width: 100px!important;
					max-width: 300px!important;
					border-right: 2px solid #423970;
					color: rgba(0,0,0,.87);
					font-size: 12px;
					font-weight: 500;
					background-color: #ffffff !important;
				}
			}
		}
        
	}
}

.planningCustomTable.fixedFirstSixColumTable {
    width: 100%;
	border-collapse: separate;
    thead {
		tr {
			th {
				min-width: 100px;
				line-height: 35px;
				padding: 0;
				font-weight: bold;
				font-size: 13px;
				border: 0;
				color: $color-koamaru-deep;
				background: $color-pastel-blue-light;
				box-shadow: none;

				&:nth-child(1){
					position: sticky;
					z-index: 3;
					left: 0px;
					min-width: 300px;
					//border-right: 1px solid $color-koamaru-deep;
					background-color: $color-pastel-blue-light !important;
				}

                &:nth-child(2){
                    border-right: 2px solid $color-koamaru-deep !important;
                    z-index: 3;
                    position: sticky;
					z-index: 3;
					left: 300px;
                    background-color: $color-pastel-blue-light !important;
                }

                // &:nth-child(3){
                //     border-right: 2px solid $color-koamaru-deep !important;
                //     z-index: 3;
                //     position: sticky;
				// 	z-index: 3;
				// 	left: 600px;
                //     background-color: $color-pastel-blue-light !important;
                // }

                // &:nth-child(4){
                //     border-right: 2px solid $color-koamaru-deep !important;
                //     z-index: 3;
                //     position: sticky;
				// 	z-index: 3;
				// 	left: 900px;
                //     background-color: $color-pastel-blue-light !important;
                // }

                // &:nth-child(5){
                //     border-right: 2px solid $color-koamaru-deep !important;
                //     z-index: 3;
                //     position: sticky;
				// 	z-index: 3;
				// 	left: 1200px;
                //     background-color: $color-pastel-blue-light !important;
                // }

                // &:nth-child(6){
                //     border-right: 2px solid $color-koamaru-deep !important;
                //     z-index: 3;
                //     position: sticky;
				// 	z-index: 3;
				// 	left: 1500px;
                //     background-color: $color-pastel-blue-light !important;
                // }

				&.header-border-right:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(4)):not(:nth-child(5)):not(:nth-child(6)){
					border-right: 1px solid $color-koamaru-deep !important;
					border-bottom: 1px solid $color-pastel-blue-light !important;
				}
			}
		}
	}

	tbody {
		tr {
			&:nth-child(even){
				background-color: #ffffff;
			}

			&:nth-child(odd){
				background-color: #F7F7F7;
			}
			
			td {
				font-size: 12px;
				text-align: center;
				line-height: 33px;
				padding: 0;
				border-right: 0;
				border-bottom: 1px solid $color-pastel-blue-light;
				box-shadow: none!important;
				
				&:first-child {
                    position: sticky !important;
                    z-index: 3 !important;
                    left: 0px !important;
					text-align: left;
					padding-left: 50px;
					width: 300px!important;
					max-width: 300px!important;
					// border-right: 1px solid #423970;
					color: rgba(0,0,0,.87);
					font-size: 12px;
					font-weight: 500;
					background-color: #ffffff !important;
				}

                &:nth-child(2) {
                    position: sticky !important;
                    z-index: 3 !important;
                    left: 300px !important;
					width: 100px!important;
					max-width: 300px!important;
					border-right: 2px solid #423970;
					color: rgba(0,0,0,.87);
					font-size: 12px;
					font-weight: 500;
					background-color: #ffffff !important;
				}
			}
		}
        
	}
}






.popper-box {
    background-color: white;
    padding: 20px 20px 30px 20px;
    border-radius: 10px;
    width: 300px !important;
    margin-top: 5px;
    border: 1px solid rgba(180, 180, 180, 0.5);
    .commonfilter-box-option {
        width: 100% !important;
        margin: auto auto 3vh auto;
    }
}

.simulation-name-cell{
    display: flex;
    align-items: center;
    justify-content: center;
    .custom-tooltip{
        max-width: 500px;
    }
    .info-icon{
        margin-left: 5px;
        font-size: 1rem;
    }
}

.MuiPopover-paper {
    .MuiListItem-button:hover{
        background-color: rgba(131, 6, 206, 0.10) !important;
    }
    
    .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected {
        background-color: rgba(131, 6, 206, 0.6) !important;
    }
}