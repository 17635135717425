// @import "../../../global.scss";
@import "../../../../../App.scss";

@mixin fixedColumnTHDesign {
	//color: $secondary;
	text-align: left;
	padding-left: 50px;
	width: 300px !important;
	max-width: 300px !important;
	border-right: 1px solid $secondary;
  
	&.maxWidth150 {
	  max-width: 150px !important;
	  padding-left: 0;
	  text-align: center;
  
	  &~.rthfc-th-fixed,
	  &~.rthfc-td-fixed {
		left: 150px !important;
	  }
	}
  
	.rt-resizer {
	//   display: none;
	}
  }
  
  @mixin fixedColumnTDDesign {
	@include fixedColumnTHDesign;
	//color: $color-white;
	font-size: 12px;
	font-weight: 500;
	background-color: inherit;
  }
  
  .sort-icon{
	display: inline;
    margin-bottom: -5px;
  }

//Pure Tables CSS
//Analytics Studio Table - Basic Pure Table css
.xpna-table-wrapper{
   .table-container{
        max-height: calc(100vh - 310px);
        overflow: scroll;
		scrollbar-width: thin;
		table{
			width : 100%
		}
        thead{
            position: sticky;
            top: 0px;
            margin: 0 0 0 0;
            z-index: 999;
            th{
            	background: #43A9E1 !important;
            	color: #fff !important;
               font-size: 14px;
               font-weight:500;
            }
			// th:first-child {
			// 	position: sticky !important; 
			// 	left: 0px;
			// 	z-index: 1000;
			// }
        }
        tbody{
            tr:nth-child(even) {
                background: #cee9f75c;
            }
            tr{
                &:hover {
                    background: #cee9f75c !important;
                }
            }
            td{
               font-size: 14px;
               font-weight:400;
               a {
                    color: #021e7f;
                }
            }
            // td:first-child {position: sticky; left: 0px}
        }

		// tr{
		// 	th:first-child,td:first-child {
		// 	  position: sticky;
		// 	  left: 0;
		// 	  z-index: 3;
		// 	}
		// }

		tr:nth-child(odd) td {
		   background: #fff;
		}

		tr:nth-child(even) td {
		   background: #edf7fc;
		}

    }
    .table-container::-webkit-scrollbar
    {
        width: 6px;
        height: 6px;
        background-color: #F5F5F5;
    }

    .table-container::-webkit-scrollbar-thumb
    {
        border-radius: 6px;
    	-webkit-box-shadow: inset 0 0 6px #abb2b6;
    	background-color: #abb2b6;
    } 

	.loading{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform: -webkit-translate(-50%, -50%);
		transform: -moz-translate(-50%, -50%);
		transform: -ms-translate(-50%, -50%);
		color:darkred;
	}
}

.xpna-table-wrapper-drilldown{
	.loading{
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transform: -webkit-translate(-50%, -50%);
		transform: -moz-translate(-50%, -50%);
		transform: -ms-translate(-50%, -50%);
		color:darkred;
	}
	.table-container{
		 max-height: calc(100vh - 310px);
		 overflow: scroll;
		 table{
			 width : 100%
		 }
		 thead{
			 position: sticky;
			 top: 0px;
			 margin: 0 0 0 0;
			 z-index: 999;
			 th{
				 background: #43A9E1 !important;
				 color: #fff !important;
				font-size: 14px;
				font-weight:500;
			 }
			//  th:first-child , td:nth-child(2), td:nth-child(3){
			// 	 position: sticky !important; 
			// 	 left: 0px;
			// 	 z-index: 1000;
			//  }

			th:first-child {width: 130px!important}
			th:nth-child(2) {width: 133px!important}
			th:nth-child(3) {width: 130px!important}

			th:first-child {position: sticky!important; left: 0px; z-index: 1000;}
			th:nth-child(2) {position: sticky!important; left: 130px; z-index: 1000;}
			th:nth-child(3) {position: sticky!important; left: 260px; z-index: 1000;}
		 }
		 tbody{
			 tr:nth-child(even) {
				 background: #cee9f75c;
			 }
			 tr{
				 &:hover {
					 background: #cee9f75c !important;
				 }
			 }
			 td{
				font-size: 14px;
				font-weight:400;
				a {
					 color: #021e7f;
				 }
			 }
			 td:first-child {width: 130px!important}
			 td:nth-child(2) {width: 130px!important}
			 td:nth-child(3) {width: 130px!important}

			 td:first-child {position: sticky; left: 0px}
			 td:nth-child(2) {position: sticky; left: 130px}
			 td:nth-child(3) {position: sticky; left: 260px}
		 }
 
		 // tr{
		 // 	th:first-child,td:first-child {
		 // 	  position: sticky;
		 // 	  left: 0;
		 // 	  z-index: 3;
		 // 	}
		 // }
 
		 tr:nth-child(odd) td {
			background: #fff;
		 }
 
		 tr:nth-child(even) td {
			background: #edf7fc;
		 }
 
	 }
	 .table-container::-webkit-scrollbar
	 {
		 width: 6px;
		 height: 6px;
		 background-color: #F5F5F5;
	 }
 
	 .table-container::-webkit-scrollbar-thumb
	 {
		 border-radius: 6px;
		 -webkit-box-shadow: inset 0 0 6px #abb2b6;
		 background-color: #abb2b6;
	 } 
 }

 .xpna-table-wrapper-drilldown-employee-chart{
	.table-container{
		 max-height: calc(100vh - 310px);
		 overflow: scroll;
		 table{
			 width : 100%
		 }
		 thead{
			 position: sticky;
			 top: 0px;
			 margin: 0 0 0 0;
			 z-index: 999;
			 th{
				 background: #43A9E1 !important;
				 color: #fff !important;
				font-size: 14px;
				font-weight:500;
			 }
			//  th:first-child , td:nth-child(2), td:nth-child(3){
			// 	 position: sticky !important; 
			// 	 left: 0px;
			// 	 z-index: 1000;
			//  }
		 }
		 tbody{
			 tr:nth-child(even) {
				 background: #cee9f75c;
			 }
			 tr{
				 &:hover {
					 background: #cee9f75c !important;
				 }
			 }
			 td{
				font-size: 14px;
				font-weight:400;
				a {
					 color: #021e7f;
				 }
			 }

			 td:first-child div {justify-content: left!important;}
			 td:nth-child(2) div {justify-content: left!important;}
			 td:nth-child(4) div {justify-content: left!important;}
		 }
 
		 // tr{
		 // 	th:first-child,td:first-child {
		 // 	  position: sticky;
		 // 	  left: 0;
		 // 	  z-index: 3;
		 // 	}
		 // }
 
		 tr:nth-child(odd) td {
			background: #fff;
		 }
 
		 tr:nth-child(even) td {
			background: #edf7fc;
		 }
 
	 }
	 .table-container::-webkit-scrollbar
	 {
		 width: 6px;
		 height: 6px;
		 background-color: #F5F5F5;
	 }
 
	 .table-container::-webkit-scrollbar-thumb
	 {
		 border-radius: 6px;
		 -webkit-box-shadow: inset 0 0 6px #abb2b6;
		 background-color: #abb2b6;
	 } 
 }

.tb-no-scroll{
   .table-container{
        max-height: 100% !important;
        overflow: inherit !important;
        width: 100%;
        thead{
        	width: 100%;
            margin: 0 0 0 0;
            th{
            //    position: sticky;
			//    top: 120px;
			   background: #43A9E1;
               color: #fff;
			   z-index: 9999;
    		   white-space: nowrap;
               font-size: 14px;
               font-weight:500;
            }
            tr th:nth-child(1) { width: 5vw; }
			tr th:nth-child(2) { width: 10vw; } 
			tr th:nth-child(3) { width: 10vw; }
			tr th:nth-child(4) { width: 25vw; }
			tr th:nth-child(5) { width: 30vw; }
			tr th:nth-child(6) { width: 10vw; }
			tr th:nth-child(7) { width: 10vw; }

        }
        tbody{
        	width: 100%;
            tr:nth-child(even) {
                background: #cee9f75c;
            }
            tr{
                &:hover {
                    background: #cee9f75c !important;
                }
            }
            tr td:nth-child(1) { width: 5vw; }
			tr td:nth-child(2) { width: 10vw; } 
			tr td:nth-child(3) { width: 10vw; }
			tr td:nth-child(4) { width: 25vw; }
			tr td:nth-child(5) { width: 30vw; }
			tr td:nth-child(6) { width: 10vw; }
			tr td:nth-child(7) { width: 10vw; }
            td{
               font-size: 14px;
               font-weight:400;
               a {
                    color: #021e7f;
                }
            }
            td:first-child { text-align: center; }
        }
    }
}

.tb-table-wrapper{
	.table-container{
		 max-height: 350px;
		 overflow: scroll;
		 thead{
			 background: #43A9E1;
			 color: #fff;
			 position: sticky;
			 top: 0px;
			 margin: 0 0 0 0;
			 th{
				font-size: 14px;
				font-weight:500;
			 }
		 }
		 tbody{
			 tr:nth-child(even) {
				 background: #cee9f75c;
			 }
			 tr{
				 &:hover {
					 background: #cee9f75c !important;
				 }
			 }

			 td{
				font-size: 14px;
				font-weight:400;
				a {
					 color: #021e7f;
				 }
			 }
		 }
	 }
	 .table-container::-webkit-scrollbar
	 {
		 width: 10px;
		 background-color: #F5F5F5;
	 }
 
	 .table-container::-webkit-scrollbar-thumb
	 {
		 border-radius: 10px;
		 -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
		 background-color: #021e7f;
	 } 
 }

.xpna-pure-table{
	width: 100%;
	border-collapse: separate;

	thead {
		tr {
			background:$color-pastel-blue-light;

			th {
				min-width: 40px;
				max-width: 350px;
				line-height: 35px;
				padding: 0;
				font-weight: normal;
				font-size: 13px;
				border: 0;
				color: $color-koamaru-deep ;
				//background:$color-pastel-blue-light;
				box-shadow: none;

				&:first-child{
					// position: sticky;
					// z-index: 3;
					// left: 0px;
					// min-width: 300px;
					// border-right: 1px solid #423970;
					//background-color: $color-pastel-blue-light !important;
				}

				&.header-border-right:not(:first-child){
					// border-right: 1px solid #423970 !important;
					//border-bottom: 1px solid #423970 !important;
				}
			}
		}
	}

	tbody {
		tr {
			&:nth-child(even){
				background-color: #ffffff;
				td {
				font-size: 12px;
				text-align: center;
				line-height: 40px;
				padding: 0;
				//border-right: 0;
				border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
				box-shadow: none!important;
				
				&:first-child {
					text-align: left;
					position: sticky;
					z-index: 3;
					left: 0px;
					// padding-left: 50px;
					// width: 300px!important;
					// min-width: 300px;
					//border-right: 1px solid #423970;
					color: rgba(0,0,0,.87);
					font-size: 12px;
					font-weight: 500;
					background-color: #ffffff !important;
				}
				}        
			}

			&:nth-child(odd){
						background-color: #F7F7F7;
				td {
				font-size: 12px;
				text-align: center;
				line-height: 40px;
				padding: 0;
				//border-right: 0;
				border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
				box-shadow: none!important;
				
				&:first-child {
					text-align: left;
					position: sticky;
					z-index: 3;
					left: 0px;
					//border-right: 1px solid #423970;
					color: rgba(0,0,0,.87);
					font-size: 12px;
					font-weight: 500;
					background-color: #F7F7F7 !important;
				}
				}
        
			}

      
			
			
		}
	}
}
