@import "../../utility.scss";

.loginEmbedMainContainer {
  display: flex;
  justify-content:center;
}

.loginEmbedHidebanner {
  display: none;
}

.loginPage {
  min-height: 100vh;
  .bannerImage,
  .bannerImageResetPage {
    background-image: url(/login-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .bannerImageResetPage {
    background-image: url(/login-bg.png);
  }
  .loginFormContainer {
    padding: 0 30px;
    position: relative;
    top: calc(50% - 100px);
    transform: translateY(-50%);
    form {
      width: 85%;
      max-width: 400px;
      margin: 30px auto 0;
      .MuiOutlinedInput-root {
        border-radius: 20px;
      }
      .btn-login {
        // background-color: $secondary;
        background-color: $primary-alt !important;
        min-width: 130px;
      }
      .passwordField {
        margin-top: 16px !important;
        width: 100%;
        .MuiInputLabel-outlined {
          transform: translate(14px, 13px) scale(1);
          &.MuiInputLabel-shrink {
            transform: translate(14px, -6px) scale(0.75);
          }
        }
        .MuiOutlinedInput-input {
          padding: 10.5px 14px;
        }
        .MuiIconButton-root {
          padding: 0;
        }
      }
    }
  }
}

.resetPage {
  .resetPasswordBtn {
    margin-top: 20px;
    background-color: #000 !important;
    &:disabled {
      opacity: 0.3;
      color: #fff;
    }
  }
  .MuiFormControl-root {
    width: 100%;
    &:first-of-type {
      margin-bottom: 20px;
    }
  }
  .message {
    padding: 5px 5px;
    margin-top: 10px;
  }
}

.changePasswordModal {
  max-width: 650px;
}


.error {
  font-size: small;
  color: red;
  padding-bottom: 10px;
}
