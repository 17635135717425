


.dt-pagination-container {
  display: flex;
  flex: 1;
  padding: 10px;
  background-color: #fafafa;
  border: 0;
  //position: sticky;
  //bottom: 0;
  //z-index: 5;

  .dt-pagination-container-left {
    display: flex;
    flex: 1;
    align-items: center;
  }

  .dt-pagination-container-right-center {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .material-icons {
      font-size: 16px !important;
    }
  }

  .dt-pagination-container-right {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  

  p {
    font-size: 12px;
    color: gray;
    padding: 0;
    margin: 5px 0;
  }

  .dt-pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 8px 0;

    > li {
      display: inline-block;
      cursor: pointer;
      list-style: none;
      outline: 0;
      min-width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      vertical-align: middle;

      > a {
        &:hover {
          border: 1px solid #40a9ff !important;
          color: #40a9ff !important;
        }

        &:focus {
          outline: 0;
        }
      }
    }

    .dt-page-link {
      font-size: 13px;
      color: rgba(0, 0, 0, .85);
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 5px 10px;
      margin: 0 5px;
    }

    .dt-next-page-link {
      font-size: 13px;
      color: rgba(0, 0, 0, .85);
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 5px 10px;
      margin: 0 5px;
    }

    .dt-prev-page-link {
      font-size: 13px;
      color: rgba(0, 0, 0, .85);
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 5px 10px;
      margin: 0 5px;
    }

    .dt-next-page.disabled {
      a {
        cursor: not-allowed;
        border: 1px solid #d9d9d9 !important;
        color: #d9d9d9 !important;
      }
    }

    .dt-prev-page.disabled {
      a {
        cursor: not-allowed;
        border: 1px solid #d9d9d9 !important;
        color: #d9d9d9 !important;
      }
    }

    > li.active {
      a {
        border: 1px solid #40a9ff !important;
        color: #40a9ff !important;
      }
    }
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 15px;
  width: calc(100% - 30px);
  z-index: 100;
  //transform: translateX(15px);
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.db-draggable {
  position: absolute;
  z-index: 4;
}

div.dt-th:has(div.dt-header-cell > div.dropdown.active) {
  background-color: #000 !important;
}
