.ai-search{
    background-color: white;
    height: calc(100vh - 142px);
    &.first{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    &.subsequent{
        padding: 20px;
    }
    .query{
        margin-bottom: 10px ;
    }
}