@import "../../utility.scss";

.summaryPage {
  .firstContainer-wrapper {
    position: sticky;
    top: -16px;
    z-index: 2;
    background: $color-gray-light;
  }
  .firstContainer {
   
    .MuiGrid-root {
      border: 1px solid $color-gray-medium-light;
      border-bottom: 0px none;
      &:first-child {
        border-right: 0px none;
      }
      &:last-child {
        border-left: 0px none;
      }
    }
  }
  .title {
    display: inline-block;
    border: 1px solid $color-gray;
    padding: 5px 10px;
    margin: 30px 0 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
  .ReactTable {
    box-shadow : 0 1px 3px $color-box-shadow1, 0 1px 2px $color-box-shadow2;
    border: unset;
    border-radius: 5px;
    background : $color-white;
    .rt-tbody {
      .rt-tr-group:last-child .rt-td {
        cursor: initial !important;
      }
    }
    .rt-tbody{
      .rt-tr-group{
        border-bottom: unset;
      
        .rt-td {
          border-bottom: 1px solid $color-pastel-blue;
          border-right: unset;
        }
      }
      .rt-tr .rt-td:first-child {
        border-right: 2px solid $color-blue-mostly-dark;
        }
    }
  }
  
  .ReactTable .rt-tbody .rt-tr-group:last-child .rt-td {
    cursor: initial !important;
    background: $color-pastel-blue;
  }

}

.editImprovementPlanModel {
  .dropdown-pip-container {
    .MuiOutlinedInput-notchedOutline {
      border-color: $color-gray-medium !important;
    }
  }
}

.externalComponentPage {
  .ReactTable {
    width: 100%;
    margin-top: 10px;
    border: 0px none;
    .rt-table {
      //@include customScrollBar;
      .rt-thead {
        min-width: auto !important;
      }
    }
    .row-span-div {
      padding: 5px;
      border-bottom: 1px solid rgba($color-black, 0.05);
    }
    .row-normal-div {
      padding: 7px 5px;
    }
    .pip-green {
      color: rgb(1, 160, 1);
    }
    .pip-red {
      color:$color-red;
    }
    .pip-orange {
      color: orange;
    }
  }
}

.dayReportPage {
  .ReactTable {
    width: 100%;
    margin-top: 10px;
    border: 0px none;
    .rt-table {
      //@include customScrollBar;
      .rt-thead {
        min-width: auto !important;
      }
    }
    .row-span-div {
      padding: 5px;
      border-bottom: 1px solid rgba($color-black, 0.05);
    }
    .row-normal-div {
      padding: 7px 5px;
    }
    .pip-green {
      color: rgb(1, 160, 1);
    }
    .pip-red {
      color:$color-red;
    }
    .pip-orange {
      color: orange;
    }
  }

  .kactionOwnerTabelSelect {
    height: 80px;
    width:150px;
    border-radius: 10px;
    input {
      font-size: 12px;
    }
    .MuiFormControl-fullWidth {
      width: 100%;
      padding-left: 5px;
      .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
        padding-left: 6px;
        padding: 0px;
    }
  }
  }

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    /* padding-right: 65px; */
    // height: 30px;
    width: 130px;
    border-radius: 20px;
    align-items: center;
  }

  .kcommentContainerTable {
    margin-top: 0px;
    .commentField {
      height: 60px;
    }
    .uploadedFilePreview {
      margin: 10px 10px 0;
    }
    .kcommentArea {
      margin-top: 7px;
      height: 50px;
      font-size: 12px;
      overflow-y: auto;
      @include customScrollBar;
      > div {
        margin-bottom: 5px;
        a {
          color: $primary-dark;
        }
      }
      .date {
        font-size: 80%;
        color: $color-gray;
      }
    }

    .kcommentInputSection {
      padding: 0px 10px;
      .MuiTextField-root {
        width: 80%;
        text-align: center;
        .MuiFormLabel-root {
          font-size: 12px;
        }
        .MuiInputBase-root {
          // height: 30px;
          border-radius: 20px;
          padding: 10px;
          font-size: 12px;
        }
        .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
        }
      }
      .MuiButtonBase-root {
        .material-icons {
          font-size: 20px;
        }
      }
    }
 }
}
.pip-legend {
  .MuiTypography-gutterBottom {
    margin-bottom: 0px;
    margin-right: 20px;
  }
  .MuiCardContent-root:last-child {
    padding: 10px;
  }
  width: 100%;
  margin-top: 20px;

  .pip-legend-box {
    padding: 5px;
    margin-right: 0px;
    margin-bottom: -2px;
  }
  .green {
    background-color: rgb(1, 160, 1);
  }
  .red {
    background-color:$color-red;
  }
  .orange {
    background-color: orange;
  }
  .pip-legend-label {
    font-size: 11px;
    margin-right: 15px;
  }
}

.ageingPageDropdown {
  .MuiAutocomplete-tag {
    max-width: 50%;
    height: 24px;
    line-height: 24px;
    .MuiSvgIcon-root {
      width: 18px;
    }
  }
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
  .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 0;
  }
  .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
    padding-right: 30px !important;
    min-height: 30px;
  }
  .MuiFormControl-root {
    overflow: hidden;
    // overflow-y: auto;
    width: 100%;
    max-width: 200px;
    margin-right: 10px;
    @include customScrollBar;
    .MuiFormLabel-root {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    }
  }
  > .MuiButtonBase-root {
    min-width: auto;
    padding: 2px 10px;
    font-size: 12px;
  }
}

.customModal.forecastModal {
  max-width: 700px;
}

.customModal.addImprovementPlanModel {
  max-width: 700px;
  .MuiOutlinedInput-root {
    border-radius: unset;
  }
}

.listItem{

  li {
    font-size: 14px;
    list-style-type: disc;
 }

}

.customModal.commentModal {
  bottom: 0;
  right: 0;
  height: 315px;
  max-width: 350px;
  transform: none;
  top: initial;
  left: initial;
  .modal-content {
    padding: 20px 10px;

    
    .commentsSection {
      height: 190px;
      overflow-y: auto;
      @include customScrollBar;
      padding: 0 20px;
      margin-top: 20px;
      font-size: 12px;
      .chat-right {
        margin: 30px 0;
        padding-left: 75px;
        > div:last-child {
          background-color: $color-blue-moderate-dark;
          padding: 10px;
          border-radius: 10px;
          color: $color-white;
        }
        .date {
          text-align: right;
        }
        a {
          color: $color-white;
          word-break: break-word;
        }
      }
      .chat-left {
        padding-right: 75px;
        margin-bottom: 20px;
        > div:last-child {
          background-color: $color-gray;
          padding: 10px;
          border-radius: 10px;
          color: $color-white;
        }
        a {
          color: $color-white;
          word-break: break-word;
        }
      }
    }
  }
}

.modalData{
  .ReactTable { 
    box-shadow: 0 1px 3px $color-box-shadow1, 0 1px 2px $color-box-shadow2;
    border-radius: 5px;
    .rt-table{
      @include customScrollBar;;
    }
    .rt-thead {

      .-header {
         box-shadow: unset; }

      .rt-tr {
        text-align: center;
        color: $color-koamaru-deep !important;
        background: $color-pastel-blue !important;   
       }
      
       .rt-resizable-header {
        overflow: visible;
        border-right: unset;
        border-bottom: unset;
      } 
    }

    .rt-tbody .rt-tr-group .rt-td {
      border-bottom: solid 1px rgba($color-black, 0.1);
      border-right: unset;
    }
  }

  .ReactTable .rt-thead.-header {
     box-shadow: unset; 
   }
}

.commentInputSection {
  padding: 0 10px;
  .MuiTextField-root {
    width: 80%;
    .MuiFormLabel-root {
      font-size: 13px;
    }
    .MuiInputBase-root {
      border-radius: 20px;
      padding: 10px;
      font-size: 13px;
    }
    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
    }
  }
  .MuiButtonBase-root {
    .material-icons {
      font-size: 20px;
    }
  }
}

.dso-input {
  .MuiInputBase-root {
    border-radius: 20px;
    font-size: 13px;
  }
}

.commentContainerTable {
  margin-top: 10px;
  .uploadedFilePreview {
    margin: 10px 10px 0;
  }
  .commentArea {
    margin-top: 10px;
    height: 100px;
    font-size: 12px;
    overflow-y: auto;
    @include customScrollBar;
    > div {
      margin-bottom: 20px;
      a {
        color: $primary-dark;
      }
    }
    .date {
      font-size: 80%;
      color: $color-gray;
    }
  }
  .commentModalIcon {
    span {
      line-height: 10px;
      height: 10px;
    }
  }
}
.editModalDSO {
  width: 50%;
}
.uploadedFilePreview {
  padding: 10px;
  display: flex;
  align-items: center;
  background: $primary-dark;
  margin: 0 0 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  color: $color-white;
  span {
    margin-left: 10px;
    max-width: 100px;
    word-break: break-all;
  }
  .MuiButtonBase-root {
    color: $color-white;
  }
  img {
    object-fit: cover;
    &[src=""] {
      display: none;
    }
  }
}

.menu-list-action-owner {
  width: 300px !important;
  .role-name {
    float: right;
    color: $color-gray-medium;
  }
}

.MuiSelect-selectMenu {
  .menu-list-action-owner {
    margin-left: -70px;
  }
}

.actionOwnerTabelSelect {
  input {
    font-size: 12px;
  }
}

.pipSelect {
  input {
    font-size: 16px;
  }
}

.usernameCreditBlock {
  background-color: $color-gray-medium-light;
  font-weight: 700;
  padding: 5px;
  .positive {
    color: $success-color;
  }
  .negative {
    color: $error-color;
  }
}