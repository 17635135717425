@import '../../utility.scss';

$backgroundColor : #072565;
$textColor : #fff;
$borderColor : #F59629;


// .bottomBanner {
// 	background: $secondary-light;
// 	padding: 20px 10px;
// 	text-align: center;
// 	font-weight: 600;
// 	margin-top: 30px;
// 	max-width: 400px;
// 	margin: 0 auto;
// }

.dropdown-filter {
  width: 100%;
  display: flex;

  .filter-section {
    width: 60%;
    display: flex;
    justify-content: left;
  }

  .btn-section {

    // width: 20%;
    // display: flex;
    // justify-content: flex-end;
    .btn-custom {
      .MuiButton-label {
        text-transform: none;
      }
    }

    .btn-apply {
      margin-right: 10px !important;
      background-color: #11783d !important;
    }
  }
}


.icon-filter {
  &:hover {
    cursor: pointer;
  }
}

.list-filter-dimention,
.list-filter-masurement {
  z-index: 4 !important;
  position: relative;
  padding: 10px !important;
  min-width: 200px !important;
}

.analytic-report-table {
  th:first-child {
    z-index: unset !important;
    min-width: unset !important;
  }

  tbody {
    tr {
      td:first-child {

        // width: 50% !important;
        // max-width: 50% !important;
        // min-width: 25% !important;
        div {
          margin-left: 10px !important;
        }

        span {
          margin-left: 10px !important;

          span {
            margin-left: 0px !important;
          }
        }
      }

      td {
        z-index: unset !important;

        // width: 50% !important;
        // max-width: 50% !important;
        // min-width: 25% !important;
        div {
          margin-left: 10px !important;
        }

        span {

          // margin-left: 10px !important;
          span {
            margin-left: 0px !important;
          }
        }
      }
    }
  }
}

.improvementPlan {
  .MuiStepper-root {
    background: transparent;
    padding: 0;

    // .MuiStepConnector-root {
    //     display: none;
    // }
    // .MuiStep-root {
    //     width: 33%;
    // }
    .MuiStepButton-root {
      background: $secondary;
      width: 100%;
      margin: 0;
      padding: 10px;

      &.Mui-disabled {
        background-color: #d4d4d4 !important;
        color: #000000 !important;
        cursor: crosshair;
      }

      .MuiStepLabel-iconContainer {
        display: none;
      }

      .MuiStepLabel-label {
        &.MuiStepLabel-active {
          color: $color-white;
          // font-weight: 700;
        }
      }
    }
  }
}

.cadenceBoard {
  .hideShow {
    background: $color-white;
    padding: 16px;

    button {
      color: $color-white;
      // &:nth-child(2) {
      // 	background-color: grey;
      // }
      // &:nth-child(3) {
      // 	background-color: #4d93fb;
      // }
      // &:nth-child(4) {
      // 	background-color: #4caf50;
      // }
      // &:nth-child(5) {
      // 	background-color: #054405;
      // }
    }
  }

  .contentArea {
    margin: 50px 0 30px;

    .block {
      width: 24%;

      .head {
        text-align: center;
        padding: 15px 0;
        color: $color-white;
        font-weight: 700;
        background: grey;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          right: -30px;
          width: 0;
          height: 0;
          top: 0;
          z-index: 9;
          border-top: 25px solid transparent;
          border-bottom: 25px solid transparent;
          border-left: 30px solid grey;
        }
      }

      .body {
        height: 130px;
        display: flex;
        align-items: center;
        color: $error-color;
        padding: 0 15px;
        border-left: 1px solid $color-gray-medium-dark;
        border-right: 1px solid $color-gray-medium-dark;
        max-width: 90%;
        margin: 0 auto;
        font-size: 12px;
      }

      .bottom {
        color: $color-white;
        height: 150px;
        display: flex;
        align-items: center;
        padding: 0 15px;
        background: grey;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 700;
      }

      &.two {

        .head,
        .bottom {
          background: #4d93fb;

          &::after {
            border-left-color: #4d93fb !important;
          }
        }
      }

      &.three {

        .head,
        .bottom {
          background: #4caf50;

          &::after {
            border-left-color: #4caf50 !important;
          }
        }
      }

      &.four {

        .head,
        .bottom {
          background: #054405;

          &::after {
            content: none;
          }
        }
      }
    }
  }

  .blank-btn {
    background: $color-american-yellow  !important;
  }

  .late-btn {
    background: $color-cinnabar-red !important;
  }

  .completed-btn {
    background: $color-pigment-green !important;
  }

  .ahead-btn {
    background: $color-quick-silver !important;
  }

  .box-total-cadence {
    background: #fff;
    justify-content: center;
    height: 5em;
    flex-direction: column;
    align-items: center;
    border: 1px solid #5fa0d6;
    color: #2c6fa0;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    margin-left: 15px;

    .total-header {
      margin-top: -20px;
      line-height: 40px;
      font-size: 13px;
    }

    .total-value {
      font-weight: 700;
    }
  }
}

//cadence-board 
.cadence-board {
  .dnd-cadence-board {
    border: 1px solid #dddada !important;
    background: #fff;

    .dnd-cadence-board-child {
      background: #ffffff !important;
      padding: 0 !important;
      width: 25%;

      .header-card {
        display: flex;
        font-size: 12px;
        width: 100%;
        padding: 0px !important;
        height: 3em;
        position: relative;
        background: rgba(236, 105, 39, 1);
        text-align: center;
        vertical-align: middle;
        margin: 10px 0px 10px 0px;
        justify-content: center;
        align-items: center;
        color: #fff;
      }

      .header-card:before {
        content: '';
        position: absolute;
        right: -2em;
        bottom: 0;
        width: 0;
        height: 0;
        z-index: 1;
        border-left: 2em solid rgba(236, 105, 39, 1);
        border-top: 1.5em solid transparent;
        border-bottom: 1.5em solid transparent;
      }

      .header-card:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 2em solid #fff;
        border-top: 1.5em solid transparent;
        border-bottom: 1.5em solid transparent;
      }

      .header-card.title-Ideation {
        background: #a1a1a1 !important;
      }

      .header-card.title-Ideation:before {
        border-left: 2em solid #a1a1a1 !important;
      }

      .header-card.title-Feasible {
        background: #5fa0d6 !important;
      }

      .header-card.title-Feasible:before {
        border-left: 2em solid #5fa0d6 !important;
      }

      .header-card.title-Under.Execution {
        background: #2c6fa0 !important;
      }

      .header-card.title-Under.Execution:before {
        border-left: 2em solid #2c6fa0 !important;
      }

      .header-card.title-Achieved {
        background: #003b6d !important;
      }

      .header-card.title-Achieved:before {
        border-left: 2em solid #003b6d !important;
      }

      .header-card.title-Closed {
        background: #5fb236 !important;
      }

      .header-card.title-Closed:before {
        border-left: 2em solid #5fb236 !important;
      }

      .header-card.title-Closed {
        margin-right: 25px !important;
        width: 90% !important;
        text-align: left;
      }

      .total-card {
        background: #d4d4d4;
        padding: 10px;
        text-align: left;
        font-size: 12px;
        border-radius: 5px;
        text-transform: inherit;
        margin-bottom: 5px;
        font-weight: bold;
      }

      .list-item-card {
        overflow-anchor: none;
        border-radius: 6px;
        background: #d4d4d4;
        padding: 10px;
        min-height: 50vh;

        .list-card-Blank {
          background: #fcedbf !important;
          grid-gap: 10px !important;
          text-align: left;
        }

        .list-card-Late {
          background: #f6d0cd !important;
        }

        .list-card-Completed {
          background: #c3ead3 !important;
        }

        .list-card-Ahead {
          background: #e7e7e7 !important;
        }

        .list-header-card-Blank {
          background: #f3b700 !important;
          padding: 5px;
          color: #fff;
          margin: -10px;
          border-top: 1px solid #f3b700;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          margin-bottom: 0px;
        }

        .list-header-card-Late {
          background: #db4437 !important;
          padding: 5px;
          color: #fff;
          margin: -10px;
          border-top: 1px solid #db4437;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
        }

        .list-header-card-Completed {
          background: #10aa50 !important;
          padding: 5px;
          color: #fff;
          margin: -10px;
          border-top: 1px solid #10aa50;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
        }

        .list-header-card-Ahead {
          background: #a1a1a1 !important;
          padding: 5px;
          color: #fff;
          margin: -10px;
          border-top: 1px solid #a1a1a1;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
        }

        .list-header-card-None {
          background: #e5e5e5 !important;
          padding: 5px;
          color: #fff;
          margin: -10px;
          border-top: 1px solid #a1a1a1;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
        }
      }
    }
  }
}

//modal 
.cadenceModal {
  .cmModal {
    .header {
      margin: 0px !important;
    }
  }

  .MuiDialogTitle-root {
    background: $backgroundColor;
    color: #fff !important;
  }

  .form-cadence {
    display: flex;
    align-items: center;
    margin: 10px 0px;
    font-size: 13px !important;

    .label-input {
      width: 180px;
      color: #3d3d3d;
      font-weight: 500;
    }

    .input-date-picker {
      margin-left: -45px;
      margin-top: -10px;
      margin-bottom: 20px;
      width: 200px;
    }
  }

  .form-cadence:nth-child(2) {
    margin-top: 20px !important;
  }

  .history-content {
    background: #e2e2e2;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 5px;

    .history-header {
      font-weight: bold;
      line-height: 2.5em;
      font-size: 14px;
    }

    .history-edit-description {
      padding-left: 30px;
      font-size: 14px;
    }

    .history-detail {
      margin-left: 20px;
      font-size: 13px;
    }
  }

}

.labelClass {
  g.highcharts-label.highcharts-data-label.highcharts-data-label-color-undefined {
    /* fill: unset; */
    /* color: black; */

    text {
      fill: unset !important;

      /* font-weight: 200 !important; */
      tspan.highcharts-text-outline {
        stroke: unset;
        // fill: unset !important;
        // color: black !important;
        font-weight: 100 !important;
        /* fill: none !important; */
        /* stroke-linejoin: bevel; */
      }
    }
  }
}

// .pointer-benchmark {
// 	display: inline-block;
// 	font-size: 12px; //Width of pointer
// 	width: 270px;
// 	height: 4em;
// 	position: relative;
// 	background: rgba($primary-alt, 0.7);
// 	text-align: center;
// 	vertical-align: middle;
// 	margin: 0.6em;
// }
// .pointer-benchmark:after {
// 	content: '';
// 	position: absolute;
// 	left: 0;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid $body-background-color;
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }
// .pointer-benchmark:before {
// 	content: '';
// 	position: absolute;
// 	right: -1.25em;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid rgba($primary-alt, 0.7);
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }

// .pointer-ideation {
// 	display: inline-block;
// 	font-size: 12px; //Width of pointer
// 	width: 460px;
// 	height: 4em;
// 	position: relative;
// 	background: rgba($primary-alt, 0.7);
// 	text-align: center;
// 	vertical-align: middle;
// 	margin: 0.6em;
// }
// .pointer-ideation:after {
// 	content: '';
// 	position: absolute;
// 	left: 0;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid $body-background-color;
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }
// .pointer-ideation:before {
// 	content: '';
// 	position: absolute;
// 	right: -1.25em;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid rgba($primary-alt, 0.7);
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }

// .pointer-improvement {
// 	display: inline-block;
// 	font-size: 12px; //Width of pointer
// 	width: 320px;
// 	height: 4em;
// 	position: relative;
// 	background: rgba($primary-alt, 0.7);
// 	text-align: center;
// 	vertical-align: middle;
// 	margin: 0.6em;
// }
// .pointer-improvement:after {
// 	content: '';
// 	position: absolute;
// 	left: 0;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid $body-background-color;
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }
// .pointer-improvement:before {
// 	content: '';
// 	position: absolute;
// 	right: -1.25em;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid rgba($primary-alt, 0.7);
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }

// .cnt {
// 	position: relative;
// 	top: 0.25em;
// 	left: 1em;
// 	color: $primary-alt;
// }
// .cnt p {
// 	//font-size:30pt;
// }

// .pointer-self-service {
// 	display: inline-block;
// 	font-size: 12px; //Width of pointer
// 	width: 220px;
// 	height: 4em;
// 	position: relative;
// 	background: rgba($primary-alt, 0.7);
// 	text-align: center;
// 	vertical-align: middle;
// 	margin: 0.6em;
// }
// .pointer-self-service:after {
// 	content: '';
// 	position: absolute;
// 	left: 0;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid $body-background-color;
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }
// .pointer-self-service:before {
// 	content: '';
// 	position: absolute;
// 	right: -1.25em;
// 	bottom: 0;
// 	width: 0;
// 	height: 0;
// 	border-left: 1.25em solid rgba($primary-alt, 0.7);
// 	border-top: 2em solid transparent;
// 	border-bottom: 2em solid transparent;
// }

.cnt {//this one
  position: relative;
  top: 1.25em;
  left: 1em;
  // font-weight: 600;
  color: $color-white;
}

.poly {
  width: 200px;
  //height: 200px;
  shape-rendering: geometricPrecision;
  text-rendering: geometricPrecision;
  image-rendering: optimizeQuality;
  fill-rule: evenodd;
  clip-rule: evenodd;
  display: block;
}

.fil {
  fill: #4d4d4d;
}

.str {
  stroke: none;
}

.cmModal {
  top: auto;
  left: auto;
  height: 300px;

  .header {
    color: #fff;
    margin: 15px 0 0 0;
    padding: 7px 15px;
    background-color: #062565;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }

  .modal-content {
    padding: 20px 10px;
    background-color: #fff;
    max-height: calc(100vh - 100px);
    overflow: auto;
  }
}



@mixin fixedColumnTHDesign {
  color: $secondary;
  text-align: left;
  padding-left: 50px;
  width: 300px !important;
  max-width: 300px !important;
  border-right: 2px solid $secondary;

  &.maxWidth150 {
    max-width: 150px !important;
    padding-left: 0;
    text-align: center;

    &~.rthfc-th-fixed,
    &~.rthfc-td-fixed {
      left: 150px !important;
    }
  }

  .rt-resizer {
    display: none;
  }
}

@mixin fixedColumnTDDesign {
  @include fixedColumnTHDesign;
  color: $textColor;
  font-size: 12px;
  font-weight: 500;
}

.MuiPopover-root .MuiMenu-paper {
  max-height: 175px;
}


.simpleCustomTable {
  margin-top: -15px;
  width: 100%;
  border-radius: 5px !important;
  background-color: $color-white;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  border: 0px none;

  .rt-table {
    @include customScrollBar;
    border-radius: 5px;

    .enableDrillDown {
      cursor: pointer;
    }

    .rt-tbody {
      @include customScrollBar;
      overflow: unset !important;
    }
  }

  .rt-thead.-headerGroups {
    background-color: $backgroundColor;
  }

  .rt-thead {
    background-color: $backgroundColor;
    box-shadow: none !important;
    position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
    position: sticky;
    top: 0;
    z-index: 1;

    // .rt-tr{
    // 	// justify-content: space-between !important;
    // 	// align-items: center !important;
    // }
    .rt-th {
      padding: 10px 5px;
      font-weight: 500;
      font-size: 13px;
      border: 0px none;
      color: $textColor !important;
      background: $backgroundColor !important;
      box-shadow: none !important;

    }

    .rt-th:last-child,
    .ReactTable .rt-thead .rt-td:last-child {
      border-right: 0 !important;
    }
  }

  .rt-tfoot {
    background-color: $backgroundColor;
  }

  .rt-tbody,
  .rt-tfoot {

    .rt-tr-group,
    .rt-tr {
      border-bottom: 0px none !important;

      .rt-td {
        font-size: 12px;
        text-align: center;
        line-height: 33px;
        padding: 0;
        border-right: 0px none;
        box-shadow: none !important;
      }
    }
  }

  .rt-tbody .rt-td:first-child {
    border-right: 2px solid $backgroundColor;
    text-align: left !important;
    padding-left: 30px;
  }

  .rt-tbody .rt-td:last-child {
    border-right: 0 !important;
  }

  .rt-tbody {
    .dropdown-column {
      .MuiFormControl-root {
        padding: 5px !important;

        .MuiFormLabel-root {
          font-size: 12px !important;
        }
      }
    }
  }
}

.newCustomTable-analyticStudio {
  width: 100%;
  border-radius: 5px !important;
  background-color: #fff;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  border: 0px none;

  .rt-table {
    @include customScrollBar;
    border-radius: 5px;

    .percentageRowData {
      background-color: $backgroundColor;
      display: block;
    }

    .enableDrillDown {
      cursor: pointer;
    }

    .rt-tbody {
      @include customScrollBar;
      overflow: unset !important;
    }
  }

  .rt-thead {
    background-color: $backgroundColor;
    box-shadow: none !important;
    position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
    position: sticky;
    top: 0;
    z-index: 1;
    max-height: initial !important;

    .rt-tr {
      background-color: $backgroundColor;
      justify-content: space-between !important;
      align-items: center !important;
    }

    .rt-th {
      padding: 10px 5px;
      font-weight: 500;
      font-size: 13px;
      border: 0px none;
      color: $textColor !important;
      background: $backgroundColor !important;
      box-shadow: none !important;

    }

    .rt-th:last-child,
    .ReactTable .rt-thead .rt-td:last-child {
      border-right: 0 !important;
    }
  }

  .rt-tfoot {
    background-color: $backgroundColor;
  }

  .rt-tbody,
  .rt-tfoot {

    .rt-tr-group,
    .rt-tr {
      // justify-content: center;
      // align-items: center;
      // border-bottom: 0px none !important;

      .rt-td {
        font-size: 12px;
        text-align: center;
        line-height: 33px;
        padding: 0;
        border-right: 0px none;
        box-shadow: none !important;

        .kcommentContainerTable {
          padding: 10px;

          .kcommentInputSection {
            .MuiFormControl-root {
              .MuiFormLabel-root {
                font-size: 12px;
              }

              .MuiOutlinedInput-root {
                border-radius: 20px;

                textarea::-webkit-input-placeholder {
                  font-size: 12px;
                }
              }
            }

          }

          .commentModalIcon.text-center {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .actionOwnerTabelSelect {
          padding: 10px 5px;

          .MuiFormControl-fullWidth {
            .MuiInputBase-root.MuiOutlinedInput-root {
              padding: 5px !important;
            }
          }

          // .MuiFormControl-root{
          //   .MuiAutocomplete-inputRoot {
          //       flex-wrap: inherit;
          //   }
          // }
        }
      }
    }
  }

  .rt-tbody .rt-td:last-child {
    border-right: 0 !important;
  }


  &.xScrollHidden {
    .rt-tbody {
      overflow: initial;
    }
  }

  &.heightOverFlowAllowed {
    >.rt-table {
      >.rt-tbody {
        max-height: initial !important;
      }
    }
  }
}

.analyticStudioTable {

  .rt-tbody,
  .rt-tfoot {

    .rt-tr-group,
    .rt-tr {
      justify-content: space-between !important;
      align-items: inherit !important;
      border-bottom: 0px none !important;
    }
  }
}

.dashboard-table {

  .rt-tbody,
  .rt-tfoot {

    .rt-tr-group,
    .rt-tr {
      justify-content: space-between !important;
      align-items: inherit !important;
      border-bottom: 0px none !important;
    }
  }
}

.newCustomTable-analyticStudio.fixedFirstColumTable {
  .rt-thead {
    .rt-th {
      &:first-child {
        @include fixedColumnTHDesign;
      }
    }
  }

  .rt-tbody,
  .rt-tfoot {

    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &:first-child {
          @include fixedColumnTDDesign
        }
      }
    }
  }

  >.rt-table {
    >.rt-tbody {
      max-height: calc(100vh - 400px);

      >.rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            background-color: $backgroundColor;

            &:first-child {
              .rt-expander {
                display: none;
              }
            }

            &:nth-child(2) {
              color: $secondary;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.newCustomTable-analyticStudio.twoColumFixedTable {
  .rt-thead {
    .rt-th {
      &.rthfc-th-fixed {
        @include fixedColumnTHDesign;
      }

      &:nth-child(2).rthfc-th-fixed {
        left: 300px !important;
      }
    }
  }

  .rt-tbody,
  .rt-tfoot {

    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &.rthfc-td-fixed {
          @include fixedColumnTDDesign;
        }

        &:nth-child(2).rthfc-td-fixed {
          left: 300px !important;
        }
      }
    }
  }

  >.rt-table {
    >.rt-tbody {
      >.rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            &.rthfc-td-fixed {
              .rt-expander {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.newCustomTable-analyticStudio.fixedFirstColumTable.expandableRows {
  .rt-tr[data-qnt="0"] {
    .rt-expander {
      display: none;
    }
  }

  .rt-expander {
    border: 1px solid #423970;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 08px;

    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #423970;
    }
  }

  .rt-thead {
    .rt-th {
      &:first-child {
        width: 50px !important;
        max-width: 50px !important;
        padding-left: 0;
        position: sticky;
        left: 0 !important;
        z-index: 99;
        border-right: 0px none;
        text-align: center;
      }

      &:nth-child(2) {
        color: $secondary;
        text-align: left;
        padding-left: 3px;
        width: 300px !important;
        max-width: 300px !important;
        border-right: 2px solid $secondary;
        left: 50px !important;

        .rt-resizer {
          display: none;
        }
      }
    }
  }

  .rt-tbody {
    .rt-tr-group {
      .rt-td {
        &:first-child {
          width: 50px !important;
          max-width: 50px !important;
          padding-left: 0;
          position: sticky;
          left: 0 !important;
          border-right: 0px none;
          text-align: center;
        }

        &:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          width: 300px !important;
          max-width: 300px !important;
          border-right: 2px solid $secondary;
          left: 50px !important;

          // white-space: normal;
          .rt-resizer {
            display: none;
          }
        }
      }
    }

    .bupl-SubTable {
      border: 0px none;

      .rt-table {
        &::-webkit-scrollbar {
          height: 0px;
        }
      }

      .rt-tr-group {
        .rt-td {
          &:first-child {
            width: 75px !important;
            max-width: 75px !important;
          }

          &:nth-child(2) {
            width: 275px !important;
            max-width: 275px !important;
            left: 75px !important;
          }
        }
      }

      .bupl-SubTable {
        .rt-tr-group {
          .rt-td {
            &:first-child {
              width: 100px !important;
              max-width: 100px !important;
            }

            &:nth-child(2) {
              width: 250px !important;
              max-width: 250px !important;
              left: 100px !important;
            }
          }
        }

        .bupl-SubTable {
          .rt-tr-group {
            .rt-td {
              &:first-child {
                width: 125px !important;
                max-width: 125px !important;
              }

              &:nth-child(2) {
                width: 225px !important;
                max-width: 225px !important;
                left: 125px !important;
              }
            }
          }

          .bupl-SubTable {
            .rt-tr-group {
              .rt-td {
                &:first-child {
                  width: 150px !important;
                  max-width: 150px !important;
                }

                &:nth-child(2) {
                  width: 200px !important;
                  max-width: 200px !important;
                  left: 150px !important;
                }
              }
            }

            .bupl-SubTable {
              .rt-tr-group {
                .rt-td {
                  &:first-child {
                    width: 175px !important;
                    max-width: 175px !important;
                  }

                  &:nth-child(2) {
                    width: 175px !important;
                    max-width: 175px !important;
                    left: 175px !important;
                  }
                }
              }

              .bupl-SubTable {
                .rt-tr-group {
                  .rt-td {
                    &:first-child {
                      width: 200px !important;
                      max-width: 200px !important;
                    }

                    &:nth-child(2) {
                      width: 150px !important;
                      max-width: 150px !important;
                      left: 200px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.newCustomTable-analyticStudio.lastRowDefaultBGColor {
  .rt-tbody {
    .rt-tr-group:last-child {
      .rt-td {
        background-color: #fff !important;

        &:nth-child(2) {
          font-weight: initial !important;
        }
      }
    }
  }
}

.newCustomTable-analyticStudio {
  .ReactTable.bupl-SubTable {
    .rt-table {
      overflow: inherit !important;
    }
  }

  .commentContainerTable {
    margin-top: 7px;
    margin-right: 5px;
    margin-bottom: 7px;

    .commentField {
      height: 60px;
    }

    .uploadedFilePreview {
      margin: 10px 10px 0;
    }

    .commentArea {
      margin-top: 7px;
      height: 50px;
      font-size: 12px;
      overflow-y: auto;
      @include customScrollBar;

      >div {
        margin-bottom: 5px;

        a {
          color: $primary;
        }
      }

      .date {
        font-size: 80%;
        color: $color-gray;
      }
    }

    .commentInputSection {
      padding: 0px 10px;

      .MuiTextField-root {
        width: 80%;
        text-align: center;

        .MuiFormLabel-root {
          font-size: 12px;
        }

        .MuiInputBase-root {
          // height: 30px;
          border-radius: 20px;
          padding: 10px;
          font-size: 12px;
        }

        .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
        }
      }

      .MuiButtonBase-root {
        .material-icons {
          font-size: 20px;
        }
      }
    }
  }
}

.custom-table-manual {
  .minw-300 {
    min-width: 300px !important;
  }

  .pl-20 {
    padding-left: 20px !important;
  }

  .pl-30 {
    padding-left: 30px !important;
  }

  .pl-100 {
    padding-left: 100px !important;
  }

  .w-60 {
    max-width: 60px !important;
  }

  .total {
    background-color: #a2afc2 !important;
    font-weight: 600 !important;
  }

  .rt-tbody {
    .rt-tr:nth-child(even) {
      background: #f7f7f7
    }

    .rt-tr:nth-child(odd) {
      background: #FFF
    }
  }

}

.close-icon {
  width: 10em;
  height: 10em;
}

.circle-menu {
  .sc-gKclnd {
    font-size: 13px;
    background: $primary;
    color: #fff;
  }

  .cZHNhx {
    background: #fff;
  }

  .sc-gsDKAQ {
    border: 2px solid #fff;
  }

  .sc-gKclnd[_highlight=true] {
    color: white;
    background: radial-gradient(transparent 50px, #4e6eb6 50px);
    cursor: pointer;
    border: 1px solid #fff;
  }

  .sc-jRQBWg {
    margin-top: -15px;
  }

  .icon-plus {
    font-size: 6rem;
    color: #062565;
    font-weight: 800;
  }

  .menu-title {
    display: table-caption;
  }
}


.analyticStudioTable {
  >.rt-table {
    >.rt-tbody {
      height: calc(100vh/2.15);
    }
  }
}

.analytic-studio-chart {
  margin-top: 5px;
  height: calc(100vh/2.4);
}

.bar-icon {
  -ms-transform: rotate(90deg);
  /* IE 9 */
  transform: rotate(90deg);
}

.top-button {
  /* margin: 2vh 1vw !important; */
  /* height: 3vh; */
  margin-top: 15px !important;
  height: 12px;
  padding: 12px 0 !important;
  font-size: 12px;
}

/* ANALYTIC STUDIO */
.chart-title {
  font-size: 1.5em;
  margin-bottom: 3vh !important;
  font-weight: bold;
}

.share-button {
  height: 12px;
  min-width: 30px !important;
  padding: 14px 0 !important;
  font-size: 12px;
  /* border-radius: 10px!important; */
}

.header-center {
  text-align: center;
}

.option-box {
  border: 1px solid black;
  /* height: 200%; */
  padding: .5vh 0;
  height: 8vh;
}


.option-box-gap {
  border-right: 1px solid grey;
  width: 100%;
  font-size: .9em;
  text-align: center;
  /* padding: 2vh 0; */
  margin: auto auto 3vh auto;
}

.analytic-studio-checkbox {
  font-size: .8em;
}

.close-icon {
  width: 10em;
  height: 10em;
}

.tab-container {
  height: auto;
}

.commonfilter-box {
  height: calc(100vh/2);
  overflow-y: auto;
}

.commonfilter-container {
  margin: auto;
  text-align: center;
}

.commonfilter-box-option {
  width: 70% !important;
  margin: auto auto 3vh auto;
}

.commonfilter-box-option-lined {
  border: 1px solid grey;
}

.dnd-item {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  // padding: 2vh 1vh 2vh;
  // height: 7vh;
  // background-color: white;
  /* width: 50% !important; */
}

.dnd-item:hover {
  /* padding: 1vh; */
  background-color: rgba(0, 0, 0, 0.04);
  /* width: 50% !important; */
}

.close-icon {
  width: 55% !important;
  height: 55% !important;
}

/* .tab-header {
  padding: 5% 0;
} */

.box-dnd {
  font-size: 12px !important;
  // box-shadow: none !important;
  box-shadow: 0px 2px #dbdbdb !important // 0px 1px 1px 0px rgb(0 0 0 / 14%), 
    // 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}

.box-dnd .MuiTypography-body1 {
  font-size: 13px !important;
}

.chart-container {
  font-size: 12px !important;
}

/* .refresh-button ..MuiButton-root:hover {
  background-color: #4472c4 !important;
} */

/* .refresh-button {
  background-color: #4472c4 !important;
} */

.chart-content {
  /* height: 200vh; */
  /* flex: auto; */
  padding: 0 4px !important;
  /* overflow: auto; */
}

.chart-content-body {
  height: 100%;
}

.chart-content-table {
  height: 100%;
}

.newCustomTable-analyticStudio {
  height: 100%;
  /* overflow: scroll; */
  /* table-layout: fixed; */
}

/* .MuiGrid-root .MuiGrid-container{
  height: 100%;
  overflow: hidden;
} */

.commonfilter-content {
  width: 100%;
}

.commonfilter-content .MuiButton-containedPrimary {
  width: 100%;
  margin-top: 1vh;
}

.top-button {
  /* margin: 2vh 1vw !important; */
  /* height: 3vh; */
  margin-top: 15px !important;
  height: 12px;
  padding: 12px 0 !important;
  font-size: 12px;
}

.edit-button {
  background-color: #054405 !important;
  color: #ffff !important;
  margin-left: .5vw !important;
}

.edit-button ::hover {
  background-color: #4caf50 !important;
  color: #ffff !important;
}

.ReactModal__Content {
  margin: auto;
  height: 25vh;
  width: 35vw;
  padding: 5vh 5vw;
}

.user-list {
  margin: 3vh 0;
}

.close-modal .close-icon {
  width: 1em !important;
  height: 1em !important;
  float: right;
}

.modal-save-button {
  width: 100%;
  margin-top: 20px !important;
}

.icon-table {
  margin: .3vh 1.5vw -1vh 1.5vw;
}

.MuiAutocomplete-endAdornment {
  display: none;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  min-width: 1px !important;
}


.newCustomTable-analyticStudio {
  .rt-td {
    margin-left: 10px;
  }
}

.newCustomTable-analyticStudio {
  .rt-th {
    margin-left: 10px;
  }
}

.bold-title {
  font-weight: bold;
  /* font-size: .5rem !important; */
  width: 100%;
}

.MuiAccordionSummary-content {
  width: 100%;
  padding-left: 5px;
}

.newCustomTable-analyticStudio .rt-tbody .rt-tr-group .rt-td,
.rt-tbody .rt-tr .rt-td,
.rt-tfoot .rt-tr-group .rt-td,
.rt-tfoot .rt-tr .rt-td {
  font-size: 12px;
  text-align: center;
  line-height: 33px;
  padding: 0;
  border-right: 0px none;
  // border-bottom: none !important;
  box-shadow: none !important;
}

.rrt-buttons-holder {
  button {
    text-align: center;
  }
}

.sub-option-analytic-studio-report {
  padding-left: 20px;
  margin-left: 20px;
  // background-color: gray;
  border-bottom: solid 1px gray;
}

.option-box-report {
  border: 1px solid grey;
  /* height: 200%; */
  padding: .5vh 0;
  height: 175px;
  overflow: auto;
  margin: 5px !important;
  @include customScrollBar;
  border-radius: 10px;
}

.child-option-box-report {
  min-height: 160px;
}

.common-filter-report {
  border: 1px solid grey;
  /* height: 200%; */
  padding: .5vh 0;
  height: 380px;
  width: 100%;
  margin: 5px !important;
}

.table-box-report {
  border: 1px solid grey;
  /* height: 200%; */
  padding: .5vh 0;
  height: 100px;
  width: 100%;
  margin: 5px !important;
}

.refresh-button {
  width: 95%;
  margin-left: 5px !important;
}

.table-label-report {
  margin-left: 10px;
}

.item-report:hover {
  font-size: 12px;

  .item-options-report {
    display: block;
    font-size: 12px;
  }

  .item-options-report:hover {
    display: block;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.09);
  }
}

.item-options-report {
  font-size: 12px;
  display: none;
  cursor: pointer;
  height: auto;
}

.selected {
  background-color: rgba(0, 0, 0, 0.09) !important;
}

.dnd-item-report {
  font-size: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 2vh 1vh 2vh;
  height: 6vh;
  background-color: white;

  /* width: 50% !important; */
  &:hover {
    cursor: pointer;
    background: #dedaee;
  }

  .dnd-item-label {
    font-size: 12px !important;
  }

}

.dnd-item-report.active {
  background: #dedaee;
}

.list-item-report {
  max-height: 200px;
  overflow: auto;
  border-top: 1px solid #b5b5b5;
  @include customScrollBar;

  .chk-item-report {
    width: 150px;

    .MuiTypography-root {
      font-size: 12px !important;
    }

    &:hover {
      background: #dedaee;
    }
  }

}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #685aa9 !important;
}


.div-wait-report {
  text-align: center;
  font-size: 12;
  color: rgba(0, 0, 0, 0.5);
}

.option-box-report-type {
  border: 1px solid grey;
  /* height: 200%; */
  padding: 3vh 0;
  height: auto;
  overflow: auto;
  margin: 5px !important;
  border-radius: 10px;
}

.new-table-column {
  padding: 0 !important;

  .flex {
    display: block !important;
  }
}

// MuiButtonBase-root MuiListItem-root MuiMenuItem-root Mui-selected MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button Mui-selected
// .MuiListItem-root {
//   word-wrap: break-word !important;
// }

// .MuiListItem-button {
//   word-wrap: break-word !important;
// }

// .Mui-selected{
//   word-wrap: break-word !important;
// }

.MuiPopover-paper {
  max-width: 300px !important;

  ul {
    //width: inherit !important;
    display: block !important;
    margin: 0 !important;

    li {
      width: 100% !important;
      display: inline-block !important;
      word-break: break-all;
      white-space: unset !important;
    }
  }
}

.newNewCustomTable.expandableRows.analytic-report-table {
  thead {
    .header-border-right {
      div {
        margin: 0 10px 0 15px !important;
      }
    }
  }
}

.new-analytic-accordion {
  font-size: 12px;
  background-color: #F4F6F9 !important;

  .dnd-item-selected {
    width: 100%;
    height: 100%;
    background-color: #d9d9d9 !important;
    border-radius: 25px;
    padding: 5px 100% 5px 10px;
    margin-left: -10px;
  }
}