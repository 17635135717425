.custom-table-filter-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 200px;
    min-height: 100px;
    height: 100%;
    padding: 10px 20px 20px;

    .custom-table-filter-header-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .custom-table-filter-header-section-left {
            display: flex;
            flex-direction: row;
            width: 50%;

            .custom-table-filter-header-section-left-title {
                font-size: 16px;
                font-weight: 400;
                padding: 0;
                margin: 0;
            }
        }

        .custom-table-filter-header-section-right {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 50%;
        }
    }

    .custom-table-filter-content-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        .MuiInputBase-root{
            font-size: 0.7rem;
        }
        .demo-mutiple-name-label{
            font-size: 0.6rem;
        }
    }

    .custom-table-filter-actions-section {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 20px;

        .filter-btn {
            display: flex;
            flex: 1;
            margin-right: 5px;
        }

        .clear-btn {
            display: flex;
            flex: 1;
            margin-left: 5px;
        }
    }

    .center {
        text-align: center;
    }
    .hidden {
        display: none;
    }
}