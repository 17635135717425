@import "./utility.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: SCProsperSans !important;
  background: $background-color !important;
}

.app {
  overflow-x: hidden;
}

.app-header {
  background: linear-gradient(90deg, #07174E 0%, #0061C7 100%);
  position: sticky !important;

  .MuiToolbar-root {
    padding: 0 1rem 0 1rem;
  }

  .header-formControls {
    width: 90%;
    margin: 0 auto;

    .MuiOutlinedInput-root {
      background: white;
      border-radius: 20px;
      font-size: 14px;
    }

    .fieldset {
      border: 0px none;
    }

    .MuiFormLabel-root {
      background-color: $color-white;
      padding: 0 5px;
    }
  }
}

.notificationMenu {
  ul {
    padding: 0 5px 0 0 !important;
    width: 100% !important;

    >div {
      padding: 0 16px;
      border-bottom: 1px dotted $color-gray-medium-light;

      p {
        font-size: 12px;
        margin: 10px 0 5px;
      }

      &:last-child {
        border-bottom: 0px;
      }
    }
  }
}

.userSettingsMenu {
  .MuiMenu-paper {
    margin: 25px 0px;
  }

  .userNameTitle {
    border-bottom: 1px dotted $color-gray-medium-light;
    padding-bottom: 5px;
    margin-bottom: 5px;
    color: $color-gray-medium-dark;
    text-align: center;
  }
}

.MuiPopover-root {
  .MuiMenu-paper {
    margin: 25px 0px;

    ul li {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.app-breadcrumbs {
  margin-bottom: 1px !important;
  width: 100%;
  background: $color-white;
  padding: 5px 0 5px 52px;
  font-weight: 500 !important;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);

  li {
    font-size: 12px;
    cursor: pointer;

    a.active {
      color: $primary;
      font-size: 12px;
      cursor: initial;
      text-decoration: none !important;
    }
  }
}

.app-content {
  background: $background-color;
  height: calc(100vh - 110px);
  overflow: auto;
  @include customScrollBar;
  flex-grow: 1;
  padding: 16px 20px;
}

.app-sidebar {
  height: calc(100vh - 93px);

  div[class*="makeStyles-drawerClose-"] {

    .MuiListSubheader-root,
    .MuiButtonBase-root {
      white-space: nowrap !important;
    }
  }

  .MuiDrawer-paper {
    position: relative;

    nav {

      >.MuiListSubheader-root,
      >.MuiButtonBase-root {
        // border-bottom: 1px solid $color-gray-medium-light;
        white-space: initial;
        font-family: SCProsperSans;
        font-size: 12px;
        padding: 6.5px 16px;
      }

      >.MuiListSubheader-root {
        font-size: 13.5px;
      }

      .MuiButtonBase-root {
&:hover {
          // background-color: #eae5f3;
        }

        &.Mui-selected {
          background-color: $primary-dark;
          color: $color-white;
        }

        .MuiListItemIcon-root {
          min-width: 40px;
        }

        .MuiTypography-root {
          font-size: 12px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-family: SCProsperSans;
        }
      }
    }
  }
}

.sandboxCTA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-left: 9vw;
}
.eaciitTooltipText {
  // text-align: center;
  
  margin-top: 50px;
  padding: 20px;
  position: relative;
  left: 0;
  width: 100%;
  text-align: center;

  span {
    font-size: 14px !important;
    font-weight: 500;
    color: $primary;
    font-size: 10px;
    cursor: pointer;
  }

  .platform-text{
    p {
      font-size: 14px !important;
    }
  }
}

.homeBlockDesign {
  min-height: calc(100vh - 130px);

  .left-navigation {
    display: flex;
    margin-top: 100px;
    margin-right: 100px;
    flex-direction: column;
    gap: 10px;
    padding-left: 10px;

    .small-box {
      width: 100px;
      height: 70px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .title {
        color: #fff;
        font-weight: 600;
      }
    }

    .small-box.img-1 {
      background-image: url('./images/landingpage/1.png');
      background-size: cover;
    }

    .small-box.img-2 {
      background-image: url('./images/landingpage/2.png');
      background-size: cover;
    }

    .small-box.img-3 {
      background-image: url('./images/landingpage/3.png');
      background-size: cover;
    }

    .small-box.img-4 {
      background-image: url('./images/landingpage/4.png');
      background-size: cover;
    }

    .small-box.img-5 {
      background-image: url('./images/landingpage/5.png');
      background-size: cover;
    }
  }

  .middle-navigation {
    padding: 0 20px 0 0px;
    margin-top: 50px;
  }

  .right-navigation {
    border-left: 3px solid $primary;

    .chevron-nav {
      display: flex;
      font-size: 14px; //Width of pointer
      width: 85%;
      align-items: center;
      flex-grow: 0;
      height: 6em;
      position: relative;
      background-image: url('./images/cashflow/tabelchart.png');
      background-size: contain;
      text-align: left;
      vertical-align: middle;
      margin: 0.6em;
      border-top: 2px solid $primary;
      border-bottom: 2px solid $primary;

      .cnt {
        top: 0px !important;
        left: 0px !important;
      }

      .chevron-text {
        padding-left: 10px;
        display: flex;
        width: 40%;
        align-items: center;
        flex-grow: 0;
        height: 6em;
        position: relative;
        background: rgba($primary, 1);
        text-align: left;
        vertical-align: middle;
        // margin: 0.6em;
      }

      .chevron-text:before {
        content: '';
        position: absolute;
        right: -1.25em;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 1.25em solid rgba($primary, 1);
        border-top: 3em solid transparent;
        border-bottom: 3em solid transparent;
      }
    }

    .chevron-nav:before {
      content: '';
      position: absolute;
      right: -1.25em;
      bottom: 0;
      width: 0;
      height: 0;
      border-left: 1.25em solid rgba($primary, 1);
      border-top: 3em solid transparent;
      border-bottom: 3em solid transparent;
    }
  }

  .header-chevron {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // margin: 0 10rem;

    .chevron-child {
      display: inline-block;
      font-size: 15px; //Width of pointer
      font-weight: bolder;
      flex-grow: 0;
      // max-width: 25%;
      // flex-basis: 25%;
      height: 2em;
      position: relative;
      text-align: center;
      vertical-align: middle;

      .cnt {
        color: $primary  !important;

        p {
          display: flex;

          .MuiSvgIcon-root {
            margin-right: 5px;
            margin-left: -20px;
          }
        }
      }
    }
  }

  .box-menu {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }

  .box-menu.upper-section {
    margin: 0 5rem;
  }

  .bottom-section {
    display: flex;
    flex-direction: column;

    .vertical-solution {
      color: #0C0047;
      margin: 2rem 0 0;
      font-weight: 600;
      font-size: 16px;
    }
  }

  .horizontal-solution {
    margin: 1rem 0;
    color: #0C0047;
    margin: 90px 0 25px 0;
    font-weight: 600;
    font-size: 16px;
  }

  .bottom-img {
    margin-top: 25px;
    text-align: center !important;

    img {
      transition: all 0.3s ease 0s;

      &:hover {
        cursor: pointer;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }


  .headerText {
    font-size: 24px;
    font-weight: normal;
    margin: 75px 0 0;
    text-align: center;
    color: $primary-alt;
  }

  .blockContainer {
    margin: 30px auto 0;
    max-width: 90%;
    min-height: 60vh;
  }

  .blockContainer-home {
    max-width: 95%;
    min-height: 60vh;
    margin: 0px auto;
  }

  .bottom-img {
    img {
      width: 158px;
      height: 148px;
    }
  }

  .block {
    //border: 2px solid $color-gray-medium-light;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    display: block;
    min-height: 160px;
    max-width: 160px;
    background-color: #ffffff;
    box-shadow: 4px 4px 10px #A1A1A1;
    border: 1px solid #ffffff;

    @media only screen and (max-width: 767px) {
      max-width: 100%;
    }

    img {
      width: 60px;
      height: 60px;
      margin: 20px 0 25px;
      // background-color: $primary-alt;
      //border-radius: 80px;
    }

    &:hover {
      border: 1px solid $primary;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        0 3px 10px 0 rgba(0, 0, 0, 0.1);
    }

    &:hover {
      background: rgba($secondary, 0.1);
      transition: all 300ms;
    }

    a {
      text-decoration: none;
      display: block;
    }

    h5 {
      margin: 0 auto 15px;
      // text-transform: uppercase;
      font-weight: 600;
      color: $color-black;
      line-height: 20px;
      font-size: 13px;
    }
  }

  .child {
    //border: 1px solid $color-gray-medium-light;
    padding: 15px 10px;
    border-radius: 5px;
    text-align: center;
    background-color: #ffffff;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    transition: 250ms;
    margin-top: 15px;

    &:hover {
      box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
        rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    }
  }
}

.blockDesignSidebar {
  width: 100%;

  .header {
    padding: 12px 20px;
    border-bottom: 1px solid $color-gray-medium-light;

    button {
      padding: 0;
    }

    span{
      font-size: 16px;
    }
  }

  .box {
    width: 160px;
    height: 160px;
    margin: 20px 10px;
    background: #FFFFFF;
    box-shadow: 2px 2px 5px #cacaca;
    border-radius: 10px;
    text-align: center;
    cursor: pointer;

    &:hover {
      transition: all 300ms;
      border: 1px solid #3D5C8C;
      transform: scale(1.1);
    }

    img{
      width: 60px;
      height: 60px;
      margin: 20px 0 20px;
    }

    h5 {
      margin: 0 5px 15px 5px;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  .plReportAlignment {
    max-width: 700px;
    flex-wrap: wrap;
  }

  .kpiBasedAlignment {
    max-width: 400px;
    flex-wrap: wrap;
  }

  .adHocAlignment {
    max-width: 500px;
    flex-wrap: wrap;
  }

  .simPlanAlignment {
    max-width: 100%;
    flex-wrap: wrap;
  }
}

.blockTabs.MuiTabs-root {
  min-height: 30px !important;
  border-bottom: 0px none !important;

  .MuiTab-root {
    margin-right: 20px;
    min-width: 200px;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid $color-gray  !important;
    height: 30px;
    min-height: 30px;
    text-transform: capitalize;
    line-height: 30px;
    font-weight: normal;
    font-size: 13px;

    &:hover {
      background-color: rgba(131, 6, 206, 0.04);
      border: 1px solid $primary-dark  !important;
      color: $primary-dark;
      transition: all 300ms;
    }

    &.Mui-selected.MuiTab-textColorPrimary {
      background-color: $primary;
      color: #fff !important;
      border: 0px none !important;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.blockRadio {
  flex-direction: initial !important;

  .MuiFormControlLabel-root {
    position: relative;
    margin: 0 10px 10px 0;

    .MuiFormControlLabel-label {
      border: 1px solid $secondary;
      color: $secondary;
      padding: 5px 20px;
      border-radius: 20px;
    }

    .MuiButtonBase-root {
      position: absolute;
      left: 0;
      opacity: 0;

      &.Mui-checked {
        &~.MuiFormControlLabel-label {
          background-color: $secondary;
          color: $color-white;
        }
      }
    }
  }
}

.customModalSingleLine {
  background-color: $color-white;
  width: 40%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  &:focus {
    outline: none;
  }

  .header {
    color: $color-white;
    padding: 15px 20px;
    background-color: $secondary;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    h2 {
      margin: 0;
    }
  }

  .modal-content {
    padding: 30px;
    max-height: calc(100vh - 100px);
    overflow: auto;
    @include customScrollBar;
  }

  .ReactTable {
    .rt-tbody {
      max-height: calc(100vh - 350px);
      overflow: auto !important;
      @include customScrollBar;
    }
  }
}

.customModal {
  background-color: $color-white;
  width: 98%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;

  &:focus {
    outline: none;
  }

  .header {
    color: $color-white;
    padding: 15px 20px;
    background-color: $secondary;
    @include flexbox();
    @include align-items(center);
    @include justify-content(space-between);

    h2 {
      margin: 0;
    }
  }

  .modal-content {
    padding: 30px;
    max-height: calc(100vh - 100px);
    overflow: auto;
    @include customScrollBar;
  }

  .ReactTable {
    .rt-tbody {
      max-height: calc(100vh - 350px);
      overflow: auto !important;
      //@include customScrollBar;
    }
  }
}

.profileModal {
  max-width: 750px;

  .profilePic {
    text-align: center;

    img {
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      object-fit: cover;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05),
        0 10px 10px rgba(0, 0, 0, 0.02);
      cursor: pointer;
    }
  }

  .profileRightSide {
    width: 65%;
    margin-left: 20px;
  }

  .profileLeftSide {
    flex-grow: 1;
  }

  .toggleButton {
    min-width: 180px;
    margin-left: 30px;
  }
}

#compatibilityBanner {
  font-size: 12px;
  background-color: #efef69;
  text-align: center;
}

.radioGroupHorizontal {
  flex-direction: initial !important;
}

.ReactTable {
  .rt-resizer {
    width: 1px !important;
    right: 0 !important;
  }

  .Table__pagination {
    padding: 10px;
  }

  .rt-thead {
    flex: 0 0 auto;
  }

  .rt-tbody {
    .rt-td {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    }
  }
}

// Tabs
.MuiTabs-root {
  min-height: 37px !important;
  border-bottom: 1px solid #c9d1d9;
  color: #2c2c2c !important;
  .MuiTabs-scroller {
    .MuiButtonBase-root {
      min-height: 30px;
    }

    .PrivateTabIndicator-colorPrimary-19 {
      background-color: #024aa2  !important;
    }

    .MuiTab-textColorPrimary.Mui-selected {
      color: #024aa2  !important;
    }

    .MuiTab-wrapper{
      font-family: SCProsperSans;
    }
  }
}

.MuiTabs-root.nested-tabs.main-tabs{
  width: max-content !important;
  max-height: 58px !important;
  border-bottom: 0 !important;
  border-radius: 15px;
  padding: 7.5px;
  background-color: #fff !important;
  font-weight: 500 !important;
  line-height: 1.8 !important;
  letter-spacing: 0.00938em !important;
  color: #2c2c2c !important;
  .MuiTabs-scroller{
     width: auto !important;
    .MuiTabs-flexContainer{
      .MuiTab-root{
        background-color: #fff !important;
        margin-right: 5px;
        border: 0 !important;
        max-height: 38px;
        color: #2c2c2c !important;
        .MuiTab-wrapper{
          font-size: 14px;
        }
        &:hover {
          background-color: #dee3ec !important;
          color: $primary-dark;
          transition: all 300ms;
          border-radius: 10px;
        }
      }
      .MuiTab-root.Mui-selected{
        background-color: $primary-dark !important;
        font-weight: 600 !important;
        height: 40px !important;
        z-index: 2;
        color: #fff !important;
        border-radius: 10px;
      }
    }
  }
  .MuiTabs-indicator{
    display: none;
  }
}

.tabs-radio{
  flex-direction: row !important;
  .MuiFormControlLabel-root{
    .MuiTypography-root{
      font-size: 12px !important;
      font-family: SCProsperSans;
    }
  }
}
//CARD
.MuiCard-root {
  .MuiCardHeader-root {
    padding: 10px 15px !important;

    .MuiCardHeader-content {
      .MuiTypography-h5 {
        font-size: 16px !important;
      }
    }
  }

  .MuiCardContent-root {
    padding: 0px !important;
  }
}


// Disabled Input
.MuiInputBase-root.Mui-disabled fieldset {
  background-color: rgba(0, 0, 0, 0.06);
}

.MuiFormLabel-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.38) !important;

  &~.MuiFormHelperText-root {
    display: none;
  }
}

// Helper text line height is too much
.MuiFormHelperText-root {
  line-height: 1.2 !important;
}

// Font size for Radio Buttons
.MuiFormControlLabel-root {
  .MuiTypography-root {
    font-size: 14px;
  }

  .MuiSvgIcon-root {
    font-size: 20px;
  }
}

// Select - Small Height
.selectOutlineSmall {
  .MuiSelect-outlined {
    font-size: 13px;
  }

  .MuiOutlinedInput-input {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .MuiInputLabel-outlined {
    font-size: 14px;
    transform: translate(14px, 10px) scale(1) !important;

    &.MuiInputLabel-shrink {
      transform: translate(14px, -4px) scale(0.75) !important;
    }
  }
}

// Font size for all inputs in form-control (reducing from 16 to 14)
.MuiFormControl-root {

  .MuiFormLabel-root,
  .MuiSelect-root {
    font-size: 14px;
  }
}

// Custom Scroll for Pop-ups
.MuiPaper-root.MuiPopover-paper {
  @include customScrollBar;
}

// Full Page Loader
.progressfullpage {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;

  >div {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  >p {
    color: $color-white;
    position: absolute;
    left: 52%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

// Pagination
.Table__pagination {
  display: flex;
  justify-content: flex-end;
  padding: 5px 10px;

  .Table__pageButton {
    font-size: 15px;
    color: $color-gray-medium-dark;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      color: $color-gray-medium-light;
    }

    &.Table__pageButton--active {
      color: $primary-dark;
      font-weight: bold;
    }
  }
}

// Comment section in Tbble design

.customModal.commentModal {
  bottom: 0;
  right: 0;
  height: 315px;
  max-width: 350px;
  transform: none;
  top: initial;
  left: initial;

  .modal-content {
    padding: 20px 10px;

    .commentsSection {
      height: 190px;
      overflow-y: auto;
      @include customScrollBar;
      padding: 0 20px;
      margin-top: 20px;
      font-size: 12px;

      .chat-right {
        margin: 30px 0;
        padding-left: 75px;

        >div:last-child {
          background-color: #685aa9;
          padding: 10px;
          border-radius: 10px;
          color: $color-white;
        }

        .date {
          text-align: right;
        }

        a {
          color: $color-white;
          word-break: break-word;
        }
      }

      .chat-left {
        padding-right: 75px;
        margin-bottom: 20px;

        >div:last-child {
          background-color: $color-gray;
          padding: 10px;
          border-radius: 10px;
          color: $color-white;
        }

        a {
          color: $color-white;
          word-break: break-word;
        }
      }
    }
  }
}

.commentInputSection {
  padding: 0 10px;

  .MuiTextField-root {
    width: 80%;

    .MuiFormLabel-root {
      font-size: 13px;
    }

    .MuiInputBase-root {
      border-radius: 20px;
      padding: 10px;
      font-size: 13px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
    }
  }

  .MuiButtonBase-root {
    .material-icons {
      font-size: 20px;
    }
  }
}

.dso-input {
  .MuiInputBase-root {
    border-radius: 20px;
    font-size: 13px;
  }
}

.commentContainerTable {
  margin-top: 10px;

  .uploadedFilePreview {
    margin: 10px 10px 0;
  }

  .commentArea {
    margin-top: 10px;
    height: 100px;
    font-size: 12px;
    overflow-y: auto;
    line-height: 1.4;
    @include customScrollBar;

    >div {
      margin-bottom: 20px;

      a {
        color: $primary-dark;
      }
    }

    .date {
      font-size: 80%;
      color: $color-gray;
    }
  }

  .commentModalIcon {
    span {
      line-height: 10px;
      height: 10px;
    }
  }
}

.uploadedFilePreview {
  padding: 10px;
  display: flex;
  align-items: center;
  background: $primary-dark;
  margin: 0 0 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  color: $color-white;

  span {
    margin-left: 10px;
    max-width: 100px;
    word-break: break-all;
  }

  .MuiButtonBase-root {
    color: $color-white;
  }

  img {
    object-fit: cover;

    &[src=""] {
      display: none;
    }
  }
}

//Custom Switch
.custom-switch {
  .MuiButtonBase-root.MuiSwitch-colorPrimary {
    color: $color-sapphire !important;
  }

  .MuiSwitch-colorPrimary+.MuiSwitch-track {
    background-color: $color-sapphire !important;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
    color: $color-sapphire !important;
  }
}

.table-wrapper {
  border-radius: 5px !important;
  background-color: #fff;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  border: 0;
  // overflow-x: auto;
  @include customScrollBar;
  thead {
    position: sticky;
    top: 0;
    z-index: 4;
  }
}

.bu-column-stack-chart {
  min-width: 300px;
  max-width: 300px;
  margin: 0 1em 5em;
}

.bu-stack-chart-div {
  display: flex;
  max-width: 1400px;
  overflow-x: scroll;
  flex-direction: row;
  justify-content: space-between;
  // flex-wrap: wrap;
}

.hoverIconsDashboard {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  cursor: pointer;
  width: 120px;
  border-style: solid;
  border-color: #BBC6D7;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px #484747;
  border-radius: 5px;
  .videoIcon{
    border-right: 1px solid #BBC6D7;
    height: 40px;
  }

}

.sendEmail-PopUp {
  width: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}



.ReactTable.-striped .rt-tr.-odd{
  background: #f7f7f7 !important;
}

.ReactTable.-striped .rt-tr.-even{
  background: #fff !important;
}