@import "../../utility.scss"; 

$color-primary: #2778C8 ;
$secondary: #062565;

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.route-profitability{
	background: #F4F6F9;
	min-height: 100vh;
	.login-page{
		background-image: url('../../images/KuwaitAirways/login_bg.png');
		background-size: cover;
		background-repeat: no-repeat;
    	height: 100vh;
    	opacity: 1;
		  animation-name: fadeInOpacity;
		  animation-iteration-count: 1;
		  animation-timing-function: ease-in;
		  animation-duration: 2s;
    	.login-bg{
		    min-width: 600px;
		    height: 380px;
		    background: rgba(10, 55, 121, 1);
		    border-radius: 7px;
		    mix-blend-mode: multiply;
		    position: absolute;
    	}
    	.login-form{
    		position: relative;
	    	padding: 0px 30px;
	    	.header-login{
	    		display: flex;
			    color: #fff;
			    justify-content: left;
			    align-items: center;
	    		.title{
	    			font-size: 30px;
				    margin-left: 20px;
				    margin-top: 50px;
	    		}
	    		.logo{
		    		width: 80px;
		    		height: 80px;
		    		margin-left: 30px;
		    		mix-blend-mode: luminosity;
		    	}
		    	.icon-user{
		    		margin-left: 135px;
				    margin-top: 10px;
				    text-align: right;
		    	}
	    	}
	    	.form-group{
	    		padding: 35px;
    			margin-right: 50px;
    			margin-top: 10px;
    			.MuiOutlinedInput-root {
				    position: relative;
				    border-radius: 4px;
				    border: 1px solid #fff;
				}
    			.MuiFormControl-root .MuiFormLabel-root, 
    			.MuiFormControl-root .MuiSelect-root {
				    font-size: 14px;
				    color: #fff;
				}
				.MuiOutlinedInput-inputMarginDense {
				    color: #fff;
				    font-size: 12px;
				}
				.btn-login{
					width: 250px;
				    background-color: #EC6927 !important;
				    color: #fff !important;
				    text-transform: initial;
				    margin-top: 10px;
				}
	    }
	    	
		}
	}
	.route-profitability-bar{
  	height: 60px;
    .MuiAppBar-root{
    	height: 60px;
      .top-navbar{
      	padding-left: 0px;
        justify-content: space-between;
	    	background: #fff;
	    	background-image: linear-gradient(to right,#0B1E60, #00AEEF);
	    	color: #fff;
	    	z-index: 9;
	    	height: 60px;
			  .logo {
				    display: flex;
				    align-items: center;
				    font-size: 20px;
				    .img-logo {
                	height: 60px;
                	margin-right: 30px;
            	}
				}
        .menu-navigation{
          display: flex;
          align-items: center;
          .user-icon{
            margin-left: 30px;
          }
          .top-menu{
            margin: 20px;
            color: #fff;
            line-height: 21px;
            text-decoration: none;
            .active.menu-item{
              color: #fff !important;
              font-weight: 600;
              border-bottom: 3px solid #fff;
            }

            &:hover {
              cursor: pointer;
              color: #fff !important;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .panel-page{
    padding: 80px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .header-panel{
      text-align: center;
      color: $color-primary;
      font-size: 24px;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 0.4px;
    }
    .box-list {
    	display: flex;
	    align-items: center;
	    justify-content: center;
	    grid-gap: 2%;
	    margin-top: 20px;
	    width: 80%;
	    flex-direction: row;
	    flex-wrap: wrap;
        .box {
        	  margin-top: 40px;
            background: #fff;
            padding: 30px 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 300px;
            height: 170px;
            border-radius: 6px;
            border: 1px solid $color-primary;
            text-decoration: none;
            &:hover {
              cursor: pointer;
              border: 1px solid $color-primary;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
              background: rgba($secondary, 0.1);
              transition: all 300ms;
            }
            .icon-panel {
                width: 75px;
                margin-bottom: 10px;
            }
            .box-title{
              color: $color-primary;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.3;
              text-align: center;

            }
        }
    }
  }

  .content-wrapper{
  	.side-navigation{
  		.MuiDrawer-paper{
  			margin-top: 65px;
  		}
  	}
  	.drawer-sidebar-filter{
  		.MuiDrawer-paper{
  			margin-top: 65px;
  			width: 240px;
  			.parent-toolbar {
				    display: flex;
				    justify-content: left;
				    align-items: center;
				    font-size: 16px;
				    color: #2778C8;
				    font-weight: 600;
				}
				.MuiDivider-root {
			    background-color: #2778C8;
				}
				.MuiListItemText-root{
					color: $color-primary !important;
				}
				.region-list{
					text-align: center;
					.btn-region{
						margin-right: 10px;
						margin-left: 10px;
						margin-bottom: 10px;
						color: #0B1E60;
						text-transform: none;
						background: #E5ECF5;
						box-shadow: none;
						border-radius: 0;
					}
				}
  		}
  	}
  	.btn-custom{
  		text-transform: none;
  		background: #1EA1E3 !important;
  		color: #fff !important;
  		border-radius: 0px;
  		min-width: auto;
  		.MuiSvgIcon-root{
  			width: 0.75em;
  		}
  	}
  	.custom-tabs{
  		background: #f4f6f9 !important;
      box-shadow: none !important;
      .MuiTab-wrapper {
      	color: #062565 !important;
      	font-weight: 600 !important;
      	text-transform: initial;
      }
  	}
  	.custom-tabs-box{
  		background: #f4f6f9 !important;
      box-shadow: none !important;
      border-bottom: 2px solid #2778C8;
      .MuiTab-root {
      	text-transform: none;
      	font-weight: 600 !important;
      	color: #1A173B;
      }
      .MuiButtonBase-root {
			    min-height: 30px;
			    border: 1px solid #C4C4C4;
			    border-bottom: none;
			    background: #fff;
			    max-width: 180px;
			}
			.Mui-selected{
				background: #1EA1E3;
				color: #fff !important;
			}
			.MuiTabs-indicator {
			    background-color: #ffffff !important;
			    height: 0px !important;
			}
  	}
  	.tab-panel{
  		width: 100%;
  		.MuiBox-root {
  			padding: 10px;
  		}
  		.filter{
  			max-width: 15%;
    		margin-right: 20px;
  		}
  	}
  	.dashboard-content{
  		.map-img{
  			width: 100%;
  			&:hover{
  				cursor: pointer;
  			}
  		}
  		.form-label-custom{
  			.MuiFormControlLabel-root,.MuiTypography-root{
  				font-size: 14px !important;
			    font-weight: bold !important;
			    color: #0B1E60 !important;
  			}
  			.MuiRadio-colorSecondary.Mui-checked {
				    color: #093c7d !important;
				}
			}
			.form-filter{
				display: flex;
				flex-direction: unset !important;
				justify-content: left;
				align-items: center;
				.MuiFormLabel-root {
				    width: 30%;
				    font-size: 14px !important;
				    font-weight: bold !important;
				    color: #0B1E60 !important;
				    text-align: right;
				    margin-right: 10px;
				    margin-left: -10px;
				}
				.MuiInputBase-root{
					 width: 60%;
				}
			}
			.custom-table{
        .MuiTableHead-root {
            background: #E5ECF5;
            color: #0B1E60;
            border-bottom: 2px solid #3D5C8C;
        }
        .MuiTableBody-root{
          background: #fff;
        }
        .MuiTableCell-head{
          text-align: center;
          font-weight: 600;
          padding: 6px !important;
        }
        .MuiTableCell-sizeSmall {
            font-size: 13px;
            padding: 5px 10px;
        }
        .MuiTableCell-root{
            font-family: 'SCProsperSans' !important;
            border: 1px solid rgba(164, 164, 164, 0.25);
        }
        .action {
          color: $color-primary !important;
        }
        .progress {
          margin: 10px auto;
          padding: 0;
          width: 70%;
          height: 10px;
          overflow: hidden;
          background: #e5e5e5;
          border-radius: 6px;
        }

        .bar {
          position:relative;
          float:left;
          min-width:1%;
          height:100%;
        }

        .esp-cell{
          width: 120px;
        }

        .text-primary{
          color: $color-primary !important;
        }
      }
  	}
  	.datasources-content{
  		padding-left: 210px;
  		padding-right: 30px;
  	}
  	.analytics-content{
  		padding-left: 210px;
  		padding-right: 30px;
  		.btn-dark-blue{
				text-transform: none;
				background: $color-primary !important;
				color: #fff !important;
				border-radius: 0px;
	  		min-width: auto;
	  		.MuiSvgIcon-root{
	  			width: 0.75em;
  			}
			}
			.custom-table{
        .MuiTableHead-root {
            background: #E5ECF5;
            color: #0B1E60;
            border-bottom: 2px solid #3D5C8C;
        }
        .MuiTableBody-root{
          background: #fff;
        }
        .MuiTableCell-head{
          text-align: center;
          font-weight: 600;
          padding: 6px !important;
        }
        .MuiTableCell-sizeSmall {
            font-size: 13px;
            padding: 5px 10px;
        }
        .MuiTableCell-root{
            font-family: 'SCProsperSans' !important;
            border: 1px solid rgba(164, 164, 164, 0.25);
        }
        .action {
          color: $color-primary !important;
        }
        .progress {
          margin: 10px auto;
          padding: 0;
          width: 70%;
          height: 10px;
          overflow: hidden;
          background: #e5e5e5;
          border-radius: 6px;
        }

        .bar {
          position:relative;
          float:left;
          min-width:1%;
          height:100%;
        }

        .esp-cell{
          width: 120px;
        }

        .text-primary{
          color: $color-primary !important;
        }
      }
  	}
  	.key-initiatives-content{
  		  margin-right: 20px;
		    padding: 20px;
		    border: none;
		    border-radius: unset;
		    background: #fff;
		    .list-plan {
				    display: flex;
				    .panel-plan {
						    width: 20%;
						    background: #EFF5FC;
						    padding: 0px;
						    border: 1px solid #B1B3B6;
								border-radius: 4px;
						    .header-plan {
								    color: #2778C8;
								    font-weight: 600;
								    text-transform: initial;
								    font-size: 16px;
								    line-height: 1.8;
								    border-bottom: 1px solid #BBC6D7;
								    padding: 10px;
								    display: flex;
								    justify-content: space-between;
								    align-items: center;
								}
								.list-item-plan {
								    padding: 10px;
								    .header-item{
								    	padding: 10px;
										  color: #fff;
										  border-radius: 2px 2px 0px 0px;
								    }
								    .nextWeek{
											background: #F3B700;
										}
										.overdue {
										  background: #DB4437;
										}
										.completed{
											background: #10AA50;
										}
										.plan-detail {
										    padding: 10px;
										    margin-top: -10px;
										}
								}
						}
				}
  	}
  	.action-hide-show {
		    background: #fff;
		    padding: 10px;
		    margin-right: 20px;
		    margin-bottom: 20px;
		    display: grid;
		    text-align: center;
		}
		.nextWeek{
			background: #F3B700 !important;
		}
		.overdue {
		  background: #DB4437 !important;
		}
		.completed{
			background: #10AA50 !important;
		}
  }
}

.text-left{
	text-align: left !important;
}

.text-primary-custom{
  color: #2778C8 !important;
}


  .cmModal {
	  top: auto;
	  left: auto;
	  height: 200px;
	}

	.cmModal .header {
	  margin: 15px 0 0 0;
	  padding: 12px 15px;
	  background: #DEE3EC !important;
  		color: #2C3D63 !important;
	  display: -webkit-box !important;
	  display: -ms-flexbox !important;
	  display: flex !important;
	  -webkit-box-align: center;
	      -ms-flex-align: center;
	          align-items: center;
	  -webkit-box-pack: justify;
	      -ms-flex-pack: justify;
	          justify-content: space-between;
	}

	.cmModal .modal-content {
	  padding: 20px 10px;
	  background-color: #fff;
	  max-height: calc(100vh - 100px);
	  overflow: auto;
	}

	.customModal.commentModal {
	  bottom: 0;
	  right: 0;
	  height: 315px;
	  max-width: 350px;
	  transform: none;
	  top: initial;
	  left: initial;
	  .header{
	  	background: #1EA1E3 !important;
	  	color: #fff !important;
	  }
	  .modal-content {
	    padding: 20px 10px;
	    .commentInputSection{
	    	.MuiIconButton-colorPrimary {
				    color: #1ea1e3;
				}
	    }
	    .commentsSection {
			height: 190px;
	      overflow-y: auto;
	      padding: 0 20px;
	      margin-top: 20px;
	      font-size: 12px;
	      .chat-right {
	        margin: 30px 0;
	        padding-left: 75px;
	        > div:last-child {
	          background-color: $secondary;
	          padding: 10px;
	          border-radius: 10px;
	          color: $color-white;
	        }
	        .date {
	          text-align: right;
	        }
	        a {
	          color: #fff;
	          word-break: break-word;
	        }
	      }
	      .chat-left {
	        padding-right: 75px;
	        margin-bottom: 20px;
	        > div:last-child {
	          background-color: $color-gray;
	          padding: 10px;
	          border-radius: 10px;
	          color: $color-white;
	        }
	        a {
	          color: $color-white;
	          word-break: break-word;
	        }
	      }
	    }
  }
}

.plan-modal{
	.MuiDialogTitle-root{
  	background: #1EA1E3 !important;
	  color: #fff !important;
  }
  .MuiDialogContent-root{
  	min-height: 250px;
  }
  .MuiDialogActions-root{
  	justify-content: center;
  }

  .btn-custom{
		text-transform: none;
		background: #1EA1E3 !important;
		color: #fff !important;
	}
	.btn-cancel{
		text-transform: none;
	}
}
