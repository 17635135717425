.new-autocomplete{
    .MuiAutocomplete-inputRoot {
        padding-right: 25px !important;
        font-size: 14px !important;
    }

    .MuiAutocomplete-endAdornment{
        display: block !important;
        .MuiAutocomplete-clearIndicator{
            display: none !important;
        }
    }
}