.MuiIconButton-root{
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}


.MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px !important;
}

.MuiAccordionSummary-content{
    margin-top : 0px !important;
    margin-bottom : 0px !important;
    margin-left : 0px !important;


}

.MuiAccordion-root.Mui-expanded{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

