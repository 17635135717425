@import '../../../../utility.scss';
@import '../../IncomeStatement.scss';

@mixin fixedColumnTHDesign {
	color: $secondary;
	text-align: left;
	padding-left: 50px;
	width: 300px !important;
	max-width: 300px !important;
	border-right: 2px solid $secondary;
	&.maxWidth150 {
		max-width: 150px !important;
		padding-left: 0;
		text-align: center;
		& ~ .rthfc-th-fixed,
		& ~ .rthfc-td-fixed {
			left: 150px !important;
		}
	}
	resizer {
		display: none;
	}
}

@mixin fixedColumnTHDesignPABValidation {
	color: $secondary;
	// text-align: left;
	// padding-left: 10px;
	text-align: center;
	width: 120px !important;
	max-width: 120px !important;
	border-right: 2px solid $secondary;
	&.maxWidth150 {
		max-width: 150px !important;
		padding-left: 0;
		text-align: center;
		& ~ .rthfc-th-fixed,
		& ~ .rthfc-td-fixed {
			left: 150px !important;
		}
	}
	resizer {
		display: none;
	}
}

@mixin fixedColumnTDDesign {
	@include fixedColumnTHDesign;
	color: rgba(0, 0, 0, 0.87);
	font-size: 12px;
	font-weight: 500;
}

@mixin fixedColumnTDDesignPABValidation {
	@include fixedColumnTHDesignPABValidation();
	text-align: center;
	margin-left: 0px;
	padding-left: 0px;
	color: rgba(0, 0, 0, 0.87);
	font-size: 12px;
	font-weight: 500;
	.totalLabel {
		font-weight: bold;
		font-size: 13px;
	}
}

.rt-expanded-class {
    border: 1px solid #423970;
    border-radius: 50%;
    height: 15px;
    width: 15px;
	margin-right: 0.25rem;
	vertical-align: middle;
	display: inline-block;
	position: relative;
    &:after {
		content: "";
		position: absolute;
		top: 50%;left:50%;
		transform:translate(-50%, -50%);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #423970;
    }
  }

  .rt-collapsed-class {
    border: 1px solid #423970;
    border-radius: 50%;
    height: 15px;
	margin-right: 0.25rem;
    width: 15px;
	vertical-align: middle;
	display: inline-block;
	position: relative;
    &:after {
		content: "";
		position: absolute;
		top: 50%;left:50%;
		transform:translate(-50%, -50%);
		border-top: 4px solid transparent;
		border-bottom: 4px solid transparent;
		
		border-left: 6px solid #423970;
    }
  }

table.newNewCustomTable{
	width: 100%;
	border-collapse: separate;

	thead {
		tr {
			th {
				min-width: 100px;
				line-height: 35px;
				padding: 0;
				font-weight: bold;
				font-size: 13px;
				border: 0;
				color: $color-koamaru-deep;
				background: $color-pastel-blue;
				box-shadow: none;

				&:first-child{
					position: sticky;
					z-index: 3;
					left: 0px;
					min-width: 300px;
					border-right: 2px solid $color-koamaru-deep;
					background-color: $color-pastel-blue !important;
				}

				&.header-border-right:not(:first-child){
					border-right: 1px solid $color-koamaru-deep !important;
					border-bottom: 1px solid $color-pastel-blue !important;
				}
			}
		}
	}

	tbody {
		tr {
			&:nth-child(even){
				background-color: #ffffff;
			}

			&:nth-child(odd){
				background-color: rgba(0,0,0,.03);
			}
			
			td {
				font-size: 12px;
				text-align: center;
				line-height: 33px;
				padding: 0;
				border-right: 0;
				border-bottom: 1px solid $color-pastel-blue-light;
				box-shadow: none!important;
				
				&:first-child {
					text-align: left;
					padding-left: 50px;
					width: 300px!important;
					max-width: 300px!important;
					border-right: 2px solid #423970;
					color: rgba(0,0,0,.87);
					font-size: 12px;
					font-weight: 500;
					background-color: #ffffff !important;
				}
			}
		}
	}
}

// .newNewCustomTable {
// 	margin-top: 10px;
// 	width: 100%;
// 	border-radius: 5px !important;
// 	background-color: $color-white;
// 	box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
// 	border: 1px solid;

// 	table {
// 		@include customScrollBar;
// 		border-radius: 5px;
		.percentageRowData {
			background-color: $color-pastel-blue-light;
			display: block;
		}
// 		.enableDrillDown {
// 			cursor: pointer;
// 		}
// 		.colorRowData {
// 			background-color: #dfd9fb;
// 			display: block;
// 		}
// 	}

// 	thead {
// 		background-color: rgb(223, 217, 251);
// 		box-shadow: none !important;
// 		max-height: 35px;
// 		th {
// 			line-height: 35px;
// 			padding: 0;
// 			font-weight: 500;
// 			font-size: 13px;
// 			border: 0px none;
// 			// height: 30px;
// 			color: #8306ce !important;
// 			background: #dfd9fb !important;
// 			box-shadow: none !important;
// 		}

// 		th:last-child,
// 		.ReactTable thead td:last-child {
// 			border-right: 0 !important;
// 		}
// 	}
// 	tfoot {
// 		background-color: rgb(223, 217, 251);
// 	}
// 	tbody,
// 	tfoot {
// 		tr-group,
// 		tr {
// 			border-bottom: 0px none;
// 			td {
// 				font-size: 12px;
// 				text-align: center;
// 				line-height: 33px;
// 				padding: 0;
// 				border-right: 0px none;
// 				border-bottom: 1px solid rgb(223, 217, 251);
// 				box-shadow: none !important;
// 			}
// 		}
// 	}

// 	tbody td:last-child {
// 		border-right: 0 !important;
// 	}

// 	&.xScrollHidden {
// 		tbody {
// 			overflow: initial;
// 		}
// 	}
// 	&.heightOverFlowAllowed {
// 		> table {
// 			> tbody {
// 				max-height: initial !important;
// 			}
// 		}
// 	}
// }

// .newNewCustomTable.fixedFirstColumTable {
// 	thead {
// 		th {
// 			&:first-child {
// 				@include fixedColumnTHDesign;
// 			}
// 		}
// 	}
// 	tbody,
// 	tfoot {
// 		tr-group,
// 		tr {
// 			td {
// 				&:first-child {
// 					@include fixedColumnTDDesign;
// 				}
// 			}
// 		}
// 	}
// 	> table {
// 		> tbody {
// 			max-height: calc(100vh - 400px);
// 			> tr-group {
// 				&:last-child:not(:only-child) {
// 					td {
// 						background-color: rgb(223, 217, 251);
// 						&:first-child {
// 							expander {
// 								display: none;
// 							}
// 						}
// 						&:nth-child(2) {
// 							color: $secondary;
// 							font-weight: 600;
// 							font-size: 12px;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .newNewCustomTable.fixedFirstColumTableSummaryView {
// 	thead {
// 		th {
// 			&:first-child {
// 				@include fixedColumnTHDesign;
// 			}
// 		}
// 	}
// 	tbody,
// 	tfoot {
// 		tr-group,
// 		tr {
// 			td {
// 				&:first-child {
// 					@include fixedColumnTDDesign;
// 				}
// 			}
// 		}
// 	}
// 	> table {
// 		> tbody {
// 			max-height: calc(100vh - 400px);
// 			> tr-group {
// 				&:last-child:not(:only-child) {
// 					td {
// 						background-color: white;
// 						// &:first-child {
// 						//   expander {
// 						//     display:inline-block;
// 						//   }
// 						// }
// 						&:nth-child(2) {
// 							color: $secondary;
// 							font-weight: 600;
// 							font-size: 12px;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .newNewCustomTable.noFixtColumTable {
// 	> table {
// 		> tbody {
// 			max-height: calc(100vh - 400px);
// 			> tr-group {
// 				&:last-child:not(:only-child) {
// 					td {
// 						background-color: rgb(223, 217, 251);
// 						&:first-child {
// 							expander {
// 								display: none;
// 							}
// 						}
// 						&:nth-child(2) {
// 							color: $secondary;
// 							font-weight: 600;
// 							font-size: 12px;
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
// .newNewCustomTable.twoColumFixedTable {
// 	thead {
// 		th {
// 			&.rthfc-th-fixed {
// 				@include fixedColumnTHDesign;
// 			}
// 			&:nth-child(2).rthfc-th-fixed {
// 				left: 300px !important;
// 			}
// 		}
// 	}
// 	tbody,
// 	tfoot {
// 		tr-group,
// 		tr {
// 			td {
// 				&.rthfc-td-fixed {
// 					@include fixedColumnTDDesign;
// 				}
// 				&:nth-child(2).rthfc-td-fixed {
// 					left: 300px !important;
// 				}
// 			}
// 		}
// 	}
// 	> table {
// 		> tbody {
// 			> tr-group {
// 				&:last-child:not(:only-child) {
// 					td {
// 						&.rthfc-td-fixed {
// 							expander {
// 								display: none;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .newNewCustomTable.fourColumFixedTable {
// 	thead {
// 		th {
// 			&.rthfc-th-fixed {
// 				@include fixedColumnTHDesignPABValidation;
// 			}
// 			&:nth-child(2).rthfc-th-fixed {
// 				left: 120px !important;
// 			}
// 			&:nth-child(3).rthfc-th-fixed {
// 				left: 240px !important;
// 			}
// 			&:nth-child(4).rthfc-th-fixed {
// 				left: 360px !important;
// 			}
// 		}
// 	}
// 	tbody,
// 	tfoot {
// 		tr-group,
// 		tr {
// 			td {
// 				&.rthfc-td-fixed {
// 					@include fixedColumnTDDesignPABValidation();
// 				}
// 				&:nth-child(2).rthfc-td-fixed {
// 					left: 120px !important;
// 				}
// 				&:nth-child(3).rthfc-td-fixed {
// 					left: 240px !important;
// 				}
// 				&:nth-child(4).rthfc-td-fixed {
// 					left: 360px !important;
// 				}
// 			}
// 		}
// 	}
// 	> table {
// 		> tbody {
// 			> tr-group {
// 				&:last-child:not(:only-child) {
// 					td {
// 						&.rthfc-td-fixed {
// 							expander {
// 								display: none;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }
// .newNewCustomTable.fixedFirstColumTable.expandableRows.plantView {
// 	thead {
// 		tr {
// 			width: fit-content;
// 		}
// 		thresizable-header:nth-child(n + 3) {
// 			width: 200px !important;
// 			max-width: 200px !important;
// 		}
// 	}

// 	tbody {
// 		width: fit-content;
// 		tr-group {
// 			td:nth-child(n + 3) {
// 				width: 200px !important;
// 				max-width: 200px !important;
// 			}
// 		}
// 	}
// }

// .newNewCustomTable.fixedFirstColumTable.expandableRows {
// 	tr[data-qnt='0'] {
// 		expander {
// 			display: none;
// 		}
// 	}

// 	expander {
// 		border: 1px solid #423970;
// 		border-radius: 50%;
// 		height: 15px;
// 		width: 15px;
// 		top: 08px;
// 		&:after {
// 			border-left: 4px solid transparent;
// 			border-right: 4px solid transparent;
// 			border-top: 6px solid #423970;
// 		}
// 	}
// 	thead {
// 		th {
// 			&:first-child {
// 				width: 50px !important;
// 				max-width: 50px !important;
// 				padding-left: 0;
// 				position: sticky;
// 				left: 0 !important;
// 				z-index: 99;
// 				border-right: 0px none;
// 				text-align: center;
// 			}
// 			&:nth-child(2) {
// 				color: $secondary;
// 				text-align: left;
// 				padding-left: 3px;
// 				width: 300px !important;
// 				max-width: 300px !important;
// 				border-right: 2px solid $secondary;
// 				left: 50px !important;
// 				resizer {
// 					display: none;
// 				}
// 			}
// 		}
// 	}
// 	tbody {
// 		tr-group {
// 			td {
// 				&:first-child {
// 					width: 50px !important;
// 					max-width: 50px !important;
// 					padding-left: 0;
// 					position: sticky;
// 					left: 0 !important;
// 					border-right: 0px none;
// 					text-align: center;
// 				}
// 				&:nth-child(2) {
// 					font-size: 12px;
// 					font-weight: 500;
// 					text-align: left;
// 					width: 300px !important;
// 					max-width: 300px !important;
// 					border-right: 2px solid $secondary;
// 					left: 50px !important;
// 					// white-space: normal;
// 					resizer {
// 						display: none;
// 					}
// 				}
// 			}
// 		}
// 		.bupl-SubTable {
// 			border: 0px none;
// 			table {
// 				&::-webkit-scrollbar {
// 					height: 0px;
// 				}
// 			}
// 			tr-group {
// 				td {
// 					&:first-child {
// 						width: 75px !important;
// 						max-width: 75px !important;
// 					}
// 					&:nth-child(2) {
// 						width: 275px !important;
// 						max-width: 275px !important;
// 						left: 75px !important;
// 					}
// 				}
// 			}
// 			.bupl-SubTable {
// 				tr-group {
// 					td {
// 						&:first-child {
// 							width: 100px !important;
// 							max-width: 100px !important;
// 						}
// 						&:nth-child(2) {
// 							width: 250px !important;
// 							max-width: 250px !important;
// 							left: 100px !important;
// 						}
// 					}
// 				}
// 				.bupl-SubTable {
// 					tr-group {
// 						td {
// 							&:first-child {
// 								width: 125px !important;
// 								max-width: 125px !important;
// 							}
// 							&:nth-child(2) {
// 								width: 225px !important;
// 								max-width: 225px !important;
// 								left: 125px !important;
// 							}
// 						}
// 					}
// 					.bupl-SubTable {
// 						tr-group {
// 							td {
// 								&:first-child {
// 									width: 150px !important;
// 									max-width: 150px !important;
// 								}
// 								&:nth-child(2) {
// 									width: 200px !important;
// 									max-width: 200px !important;
// 									left: 150px !important;
// 								}
// 							}
// 						}
// 						.bupl-SubTable {
// 							tr-group {
// 								td {
// 									&:first-child {
// 										width: 175px !important;
// 										max-width: 175px !important;
// 									}
// 									&:nth-child(2) {
// 										width: 175px !important;
// 										max-width: 175px !important;
// 										left: 175px !important;
// 									}
// 								}
// 							}
// 							.bupl-SubTable {
// 								tr-group {
// 									td {
// 										&:first-child {
// 											width: 200px !important;
// 											max-width: 200px !important;
// 										}
// 										&:nth-child(2) {
// 											width: 150px !important;
// 											max-width: 150px !important;
// 											left: 200px !important;
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .newNewCustomTable.fixedFirstColumTableSummaryView.expandableRows {
// 	tr[data-qnt='0'] {
// 		expander {
// 			display: none;
// 		}
// 	}

// 	expander {
// 		border: 1px solid #423970;
// 		border-radius: 50%;
// 		height: 15px;
// 		width: 15px;
// 		top: 08px;
// 		&:after {
// 			border-left: 4px solid transparent;
// 			border-right: 4px solid transparent;
// 			border-top: 6px solid #423970;
// 		}
// 	}
// 	thead {
// 		th {
// 			&:first-child {
// 				width: 50px !important;
// 				max-width: 50px !important;
// 				padding-left: 0;
// 				position: sticky;
// 				left: 0 !important;
// 				z-index: 99;
// 				border-right: 0px none;
// 				text-align: center;
// 			}
// 			&:nth-child(2) {
// 				color: $secondary;
// 				text-align: left;
// 				padding-left: 3px;
// 				width: 300px !important;
// 				max-width: 300px !important;
// 				border-right: 2px solid $secondary;
// 				left: 50px !important;
// 				resizer {
// 					display: none;
// 				}
// 			}
// 		}
// 	}
// 	tbody {
// 		tr-group {
// 			td {
// 				&:first-child {
// 					width: 50px !important;
// 					max-width: 50px !important;
// 					padding-left: 0;
// 					position: sticky;
// 					left: 0 !important;
// 					border-right: 0px none;
// 					text-align: center;
// 				}
// 				&:nth-child(2) {
// 					font-size: 12px;
// 					font-weight: 500;
// 					text-align: left;
// 					width: 300px !important;
// 					max-width: 300px !important;
// 					border-right: 2px solid $secondary;
// 					left: 50px !important;
// 					// white-space: normal;
// 					resizer {
// 						display: none;
// 					}
// 				}
// 			}
// 		}
// 		.bupl-SubTable {
// 			border: 0px none;
// 			table {
// 				&::-webkit-scrollbar {
// 					height: 0px;
// 				}
// 			}
// 			tr-group {
// 				td {
// 					&:first-child {
// 						width: 75px !important;
// 						max-width: 75px !important;
// 					}
// 					&:nth-child(2) {
// 						width: 275px !important;
// 						max-width: 275px !important;
// 						left: 75px !important;
// 					}
// 				}
// 			}
// 			.bupl-SubTable {
// 				tr-group {
// 					td {
// 						&:first-child {
// 							width: 100px !important;
// 							max-width: 100px !important;
// 						}
// 						&:nth-child(2) {
// 							width: 250px !important;
// 							max-width: 250px !important;
// 							left: 100px !important;
// 						}
// 					}
// 				}
// 				.bupl-SubTable {
// 					tr-group {
// 						td {
// 							&:first-child {
// 								width: 125px !important;
// 								max-width: 125px !important;
// 							}
// 							&:nth-child(2) {
// 								width: 225px !important;
// 								max-width: 225px !important;
// 								left: 125px !important;
// 							}
// 						}
// 					}
// 					.bupl-SubTable {
// 						tr-group {
// 							td {
// 								&:first-child {
// 									width: 150px !important;
// 									max-width: 150px !important;
// 								}
// 								&:nth-child(2) {
// 									width: 200px !important;
// 									max-width: 200px !important;
// 									left: 150px !important;
// 								}
// 							}
// 						}
// 						.bupl-SubTable {
// 							tr-group {
// 								td {
// 									&:first-child {
// 										width: 175px !important;
// 										max-width: 175px !important;
// 									}
// 									&:nth-child(2) {
// 										width: 175px !important;
// 										max-width: 175px !important;
// 										left: 175px !important;
// 									}
// 								}
// 							}
// 							.bupl-SubTable {
// 								tr-group {
// 									td {
// 										&:first-child {
// 											width: 200px !important;
// 											max-width: 200px !important;
// 										}
// 										&:nth-child(2) {
// 											width: 150px !important;
// 											max-width: 150px !important;
// 											left: 200px !important;
// 										}
// 									}
// 								}
// 							}
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// .newNewCustomTable.lastRowDefaultBGColor {
// 	tbody {
// 		tr-group:last-child {
// 			td {
// 				background-color: #fff !important;
// 				&:nth-child(2) {
// 					font-weight: initial !important;
// 				}
// 			}
// 		}
// 	}
// }
// .newNewCustomTable {
// 	.ReactTable.bupl-SubTable {
// 		table {
// 			overflow: inherit !important;
// 		}
// 	}
// 	.commentContainerTable {
// 		margin-top: 7px;
// 		margin-right: 5px;
// 		margin-bottom: 7px;
// 		.commentField {
// 			height: 60px;
// 		}
// 		.uploadedFilePreview {
// 			margin: 10px 10px 0;
// 		}
// 		.commentArea {
// 			margin-top: 7px;
// 			height: 50px;
// 			font-size: 12px;
// 			overflow-y: auto;
// 			@include customScrollBar;
// 			> div {
// 				margin-bottom: 5px;
// 				a {
// 					color: $primary-dark;
// 				}
// 			}
// 			.date {
// 				font-size: 80%;
// 				color: $color-gray;
// 			}
// 		}

// 		.commentInputSection {
// 			padding: 0px 10px;
// 			.MuiTextField-root {
// 				width: 80%;
// 				text-align: center;
// 				.MuiFormLabel-root {
// 					font-size: 12px;
// 				}
// 				.MuiInputBase-root {
// 					// height: 30px;
// 					border-radius: 20px;
// 					padding: 10px;
// 					font-size: 12px;
// 				}
// 				.MuiOutlinedInput-notchedOutline {
// 					border-width: 1px;
// 				}
// 			}
// 			.MuiButtonBase-root {
// 				.material-icons {
// 					font-size: 20px;
// 				}
// 			}
// 		}
// 	}
// }
