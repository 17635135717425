$color-white: #ffffff;
$color-black: #000000;
$color-gray: #808080;
$color-red: #ff0000;
$color-blue: #0000ff;
$color-green: #00ff00;


$primary-light: #062565;
$primary-alt: #ec6927;
$primary: #062565;
$primary-dark: #062565;
$secondary: #062565;
$error-color: #d00909;
$warning-color: #ff9502;
$success-color: #4caf50;
$secondary-light: #b1bace;
$body-background-color: $color-white;
$component-background: $color-white;
$background-color: #F3F6F9;

//new colors define from here

//Gray shades
$color-gray-light: #eeeeee;
$color-gray-medium-light: #dddddd;
$color-gray-medium: #c1c1c1;
$color-gray-medium-dark: #5a5a5a;
$color-gray-dark: #4d4d4d;
$color-gray-very-dark: #3d3d3d;
$color-quick-silver: #A1A1A1;
$color-gray-regular: #dddddd;
$color-gray-semi-dark: #7b7b7b;


//Blue shades

$color-blue-very-desaturated-dark: #423970;
$color-blue-moderate: #5fa0d6;
$color-blue-moderate2: #4e6eb6;
$color-blue-dark: #2c6fa0;
$color-blue-mostly-dark: #5a5282;
$color-blue-moderate-dark: #685aa9;
$color-blue-soft: #4d93fb;
$color-blue-grayish: #a2afc2;
$color-koamaru-deep : #062565;
$color-pastel-blue : #b1bace;
$color-pastel-blue-light : #DEE3EC;
$color-sapphire:#015ABB;
$color-light-azure-blue: #70B5FA;
$color-pale-blue: #B6D3F3;
$color-windows-blue: #00A4EF;



//Red Shades
$color-cinnabar-red: #db4437;
$color-red-strong: #d00909;
$color-light-red: #f6d0cd;
$color-deep-chestnut: #BA4742;


//Orange shades
$color-orange-pale: #ffd5bf;
$color-orange-bright: #f59629;
$color-carrot-orange: #ec6927; 


//Yellow Shades
$color-american-yellow: #f3b700;
$color-blond-yellow: #fcedbf;


//Green Shades
$color-green-moderate: #5fb236;
$color-apple-green:#4caf50;
$color-pigment-green: #10aa50;
$color-forest-green:#054405;
$color-limegreen-grayish-cyan-light: #c3ead3;



$color-violet-strong: #8306ce;

$color-box-shadow1: #0000001f;
$color-box-shadow2: #0000003d;

//map colours
$color-tier1 : $color-sapphire;
$color-tier2 : $color-light-azure-blue;
$color-tier3 : $color-pale-blue;


@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex !important;
}

@mixin flex-wrap($value: nowrap) {
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

@mixin flex-grow($int: 1) {
  -webkit-box-flex: $int;
  -moz-box-flex: $int;
  -webkit-flex-grow: $int;
  -ms-flex: $int;
  flex-grow: $int;
}

@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}

@mixin customScrollBar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(88, 38, 38, 0.12);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color-koamaru-deep;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-button:single-button {
    display: block;
    border-style: solid;
    height: 5px;
    width: 10px;
  }
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent $secondary transparent;
  }
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 5px 5px 0 5px;
    border-color: $secondary transparent transparent transparent;
  }
  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    border-width: 5px 5px 5px 0px;
    border-color: transparent $secondary transparent transparent;
  }
  &::-webkit-scrollbar-button:single-button:horizontal:increment {
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent $secondary;
  }
}

@mixin fixedColumnTHDesign {
  //color: $secondary;
  text-align: left;
  padding-left: 50px;
  width: 300px !important;
  max-width: 300px !important;
  border-right: 1px solid $secondary;

  &.maxWidth150 {
    max-width: 150px !important;
    padding-left: 0;
    text-align: center;

    &~.rthfc-th-fixed,
    &~.rthfc-td-fixed {
      left: 150px !important;
    }
  }

  .rt-resizer {
    display: none;
  }
}

@mixin fixedColumnTDDesign {
  @include fixedColumnTHDesign;
  //color: $color-white;
  font-size: 12px;
  font-weight: 500;
  background-color: inherit;
}


// Mixin Function for paddings and margins
$max: 100;
$offset: 1;
$unit: "px";
@mixin spacing-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }
    $i: $i + $offset;
  }
}

// Margins
@include spacing-loop(".mt-", "margin-top");
@include spacing-loop(".mr-", "margin-right");
@include spacing-loop(".mb-", "margin-bottom");
@include spacing-loop(".ml-", "margin-left");
@include spacing-loop(".mx-", "margin");

// Paddings
@include spacing-loop(".pt-", "padding-top");
@include spacing-loop(".pr-", "padding-right");
@include spacing-loop(".pb-", "padding-bottom");
@include spacing-loop(".pl-", "padding-left");
@include spacing-loop(".px-", "padding");


// Common Classes and CSS
.mat-shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.mat-shadow-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.mat-shadow-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.mat-shadow-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.mat-shadow-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 10px;
  font-family: SCProsperSans;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}

.pointer,
.material-icons,
.MuiSvgIcon-root {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.justify-left{
  justify-content: left;
}

.text-right {
  text-align: right;
}

.word-break {
  white-space: break-spaces;
  line-height: 1.5;
}

.nowrap {
  white-space: nowrap;
}

.white-color {
  color: #fff !important;
}

.primary {
    color: $primary !important;
}

.primary-dark {
  color: $primary-dark !important;
}

.dark-grey {
    color: $color-gray-medium-dark !important;
}

.bold {
  font-weight: bold !important;
}

.semi-dark-grey {
    color: $color-gray !important;
}

.error-color {
    color: $error-color !important;
}

.success-color {
    color: $success-color !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.customScrollBar {
  @include customScrollBar;
}

.noBorder {
  border: 0px none !important;
}

.minw-200 {
  min-width: 200px !important;
}

.maxw-150 {
  max-width: 150px !important;
}

.maxw-200 {
  max-width: 200px !important;
}
.maxw-100 {
  max-width: 100px !important;
}

.w-100 {
  width: 100% !important;
}

.w-200 {
  width: 200px;
}

.w-50 {
  width: 50%;
}

.flex {
  @include flexbox();
}

.fg-1 {
  @include flex-grow((1))
}

.ai-center {
  @include align-items(center);
}

.ai-flex-start {
  @include align-items(flex-start);
}

.ai-flex-end {
  @include align-items(flex-end);
}

.jc-center {
  @include justify-content(center);
}

.jc-space-between {
  @include justify-content(space-between);
}

.jc-space-evenly {
  @include justify-content(space-evenly);
}

.jc-flex-end {
  @include justify-content(flex-end);
}

.jc-space-around {
  @include justify-content(space-around);
}

.flex-gap-50 {
  gap: 50px;
}

.flex-gap-100 {
  gap: 100px;
}



//BUTTON CSS handling. Parent Classes for variants are newButton and newButtonOutlined
//Standard Variant
.newButton{
  background-color: $color-sapphire !important;
  color: #FFFFFF !important;
  text-transform: uppercase !important;
  border-radius: 4px; 
  padding: 6px 9px !important;

  &.btnBlue{
    background-color: $color-sapphire !important;
  }
  &.btnGrey{
    background-color: $color-quick-silver !important;
  }
  &.btnRed{
    background-color: $color-deep-chestnut !important;
  }
  &.capitalise{
    text-transform: capitalize !important;
  }
  &.uppercase{
    text-transform: uppercase !important;
  }



  //Specific Button customisations define below
  &.btnBack{
    background-color: $color-windows-blue !important;
  }

}


//Outlined Variant
.newButtonOutlined{
  border-radius: 4px;
  padding: 6px 9px !important;
  text-transform: uppercase !important;

  &.btnBlue{
    &.MuiButton-outlined{
      border: 1px solid $color-sapphire !important;
      color:$color-sapphire !important
    }
  }

  &.btnRed{
    &.MuiButton-outlined{
      border: 1px solid $color-deep-chestnut  !important;
      color:$color-deep-chestnut !important
    }
  }

  &.btnGrey{
    &.MuiButton-outlined{
      border: 1px solid $color-quick-silver  !important;
      color:$color-quick-silver !important
    }
  }


  &.capitalise{
    text-transform: capitalize !important;
  }
  &.uppercase{
    text-transform: uppercase !important;
  }

}



//ICONS CSS FILTERS
.colored-icons{
  &.main-icon{
    filter: invert(77%) sepia(69%) saturate(6401%) hue-rotate(346deg) brightness(97%) contrast(91%);
  }

  &.sidebar-normal{
    filter: invert(11%) sepia(51%) saturate(0%) hue-rotate(199deg) brightness(100%) contrast(87%);
  }

  &.sidebar-highlighted{
    filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(139deg) brightness(103%) contrast(101%);
  }
}


//Radio Buttons CSS
.MuiRadio-colorPrimary.Mui-checked, 
.MuiRadio-colorSecondary.Mui-checked {
  color: $color-sapphire !important;
}


//Dropdown CSS
.xpna-dropdown{
  // .MuiAutocomplete-inputRoot {
  //   padding-right: 25px !important;
  //   font-size: 14px !important;
  // }
  .MuiAutocomplete-endAdornment{
      display: unset !important;
      .MuiAutocomplete-clearIndicator{
          display: unset !important;
      }
  }
}




//Drag and Drop side menu CSS
.side-menu-container {
  background-color: #FFFFFF !important;
  width: 100%;
  height: 100%;
  max-height: 75vh;
  overflow: scroll;
  padding:0px 12px ;
}

.side-menu-item{
  margin-top:2px;
  border-bottom: 1px solid #A1A1A1;
  .MuiPaper-elevation1{
      box-shadow: none;
  }
  .major-menu-title{
      font-size: 15px;
      font-weight: 600;
  }
  .minor-menu-title{
      font-size:14px;
      font-weight: 500;
  }
  .box-dnd{
      box-shadow: none !important;
      //background-color: aqua !important;
  }
  .submenu-item{
      padding-left: 10px;
      //border-bottom: 1px solid #A1A1A1;
  }

  &:nth-last-child(1){
      border-bottom: none;
  }
  .dnd-item-new{
      font-size: 12px;
      &:hover{
          transform: scale(1.05);
          border: 1px solid #015ABB;
          transition: all 300ms;
      }
  }
  .MuiAccordionSummary-root {
      min-height: 36px !important;
  }

}


//Table CSS
//Standard React Table - Multilayered Column Headers
.xpna-react-table{
    box-shadow: 0 1px 3px $color-box-shadow1, 0 1px 2px $color-box-shadow2;
    border-radius: 5px;
    height: 100%;
    overflow: scroll;

    .rt-th, .rt-td{
      div, span{
        white-space: break-spaces;
        word-break: break-word;
      }
    }
    .rt-tr.-odd{
      background: #f7f7f7 !important;
    };
    .rt-tr.-even{
      background: #fff !important;
    } 
    

    .rt-table {
      //@include customScrollBar;
      .rt-thead .rt-tr {
        padding: 0px 0px 0px 0px;
        //font-size: 15px;
      }
      .rt-thead{
        &.-header{
          box-shadow: unset !important;      
          .rt-tr {
            text-align: center;
            color: $color-koamaru-deep !important;
            background: $color-pastel-blue-light !important;
            border-bottom: 0px none;
            .rt-th{
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 13px !important;
              min-height: 35px;
              font-weight: normal !important;
              
            }
          }
        }
        &.-headerGroups{
          box-shadow: unset !important;   
          border-bottom: 0px none;
          .rt-tr {
            text-align: center;
            color: $color-koamaru-deep !important;
            background: $color-pastel-blue-light !important;
            border-bottom: 0px none;
            .rt-th{
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 16px !important;
              font-weight: 500 !important;
            }
          }
        }


        .rt-tr {
          text-align: center;
          color: $color-koamaru-deep !important;
          background: $color-pastel-blue-light !important;
          border-bottom: 0px none;
          .rt-th{
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .rt-tbody {
        overflow-x: hidden !important;
        .rt-tr-group {
          border-bottom: unset !important;
          .rt-td {
            border-bottom: solid 1px rgba(0, 0, 0, 0.1);
            border-right: unset;
            line-height: normal;
            padding: 5px;
            min-height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            
          }
        }



      }
    }


}

//React Table Fixed Columns
.xpna-reactFixed-table.fixedFirstColumTable {
  .rt-tr.-odd{
    background: #f7f7f7 !important;
  };
  .rt-tr.-even{
    background: #fff !important;
  } 
  &.rthfc {
    .rt-th, .rt-td{
      background-color: unset;
    }
  }

 
  
  .rt-thead {
    .rt-th {
      &:first-child {
        @include fixedColumnTHDesign;
      }
    }
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &:first-child {
          @include fixedColumnTDDesign
        }
      }
    }
  }
  > .rt-table {
    > .rt-tbody {
      max-height: calc(100vh - 400px);
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            //background-color: $color-koamaru-deep;
            &:first-child {
              .rt-expander {
                display: none;
              }
            }
            &:nth-child(2) {
              color: $secondary;
              font-weight: 600;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

.xpna-reactFixed-table {
  //margin-top: 10px;
  width: 100%;
  border-radius: 5px !important;
  background-color: $color-white;
  box-shadow: 0 1px 3px #0000001f, 0 1px 2px #0000003d;
  border: 0px none;
  height: 100%;
  overflow: scroll;

  .rt-th, .rt-td{
    div, span{
      white-space: break-spaces;
      word-break: break-word;
    }
  }

  .rthfc-th-fixed-left-last,.rthfc-td-fixed-left-last{
    border-right: 1px solid $color-blue-mostly-dark !important;
  }
  
  .rt-table {
    border-radius: 5px;
    .percentageRowData {
      background-color: $color-pastel-blue-light;
      display: block;
    }
    .enableDrillDown {
      cursor: pointer;
    }
    .colorRowData {
      background-color: $color-pastel-blue-light;
      display: block;
    }
  }

  .rt-thead {
    background-color: $color-pastel-blue-light;
    box-shadow: none !important;
    //max-height: 35px;
    .rt-th {
      line-height: 35px;
      padding: 0;
      font-weight: 500;
      font-size: 13px;
      border: 0px none;
      // height: 30px;
      color: $color-koamaru-deep !important;
      background: $color-pastel-blue-light !important;
      box-shadow: none !important;
    }
    &.-header{
      box-shadow: unset !important;    
      //top:30px !important
      .rt-tr {
        text-align: center;
        color: $color-koamaru-deep !important;
        background: $color-pastel-blue-light !important;
        border-bottom: 0px none;
        .rt-th{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px !important;
          min-height: 35px;
          font-weight: normal !important;
          
        }
      }
    }
    &.-headerGroups{
      box-shadow: unset !important;   
      border-bottom: 0px none;
      div, span{
        white-space: nowrap;
        word-break: unset;
      }
      .rt-tr {
        text-align: center;
        color: $color-koamaru-deep !important;
        background: $color-pastel-blue-light !important;
        border-bottom: 0px none;
        .rt-th{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px !important;
          font-weight: 500 !important;
          &:not(:first-child){
            overflow: hidden !important;
          }
        }
      }
    }

    .rt-th:last-child,
    .ReactTable .rt-thead .rt-td:last-child {
      border-right: 0 !important;
    }
  }
  .rt-tfoot {
    background-color: $color-pastel-blue-light;
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      border-bottom: 0px none;
      .rt-td {
        font-size: 12px;
        text-align: center;
        line-height: 33px;
        padding: 0;
        border-right: 0px none;
        border-bottom: 1px solid $color-pastel-blue-light;
        box-shadow: none !important;
      }
    }
  }

  .rt-tbody .rt-td:last-child {
    border-right: 0 !important;
  }

  &.xScrollHidden {
    .rt-tbody {
      overflow: initial;
    }
  }
  &.heightOverFlowAllowed {
    > .rt-table {
      > .rt-tbody {
        max-height: initial !important;
      }
    }
  }
}

.xpna-reactFixed-table.dataValidationTable.twoRowsFixed{
  .rt-thead {
    .rt-th {
      &.rthfc-th-fixed {
        @include fixedColumnTHDesign;
      }
      &:nth-child(2).rthfc-th-fixed {
        left: 300px !important;
      }
    }
  }
  .rt-tbody,
  .rt-tfoot {
    .rt-tr-group,
    .rt-tr {
      .rt-td {
        &.rthfc-td-fixed{
          @include fixedColumnTDDesign;
        }
        &:nth-child(2).rthfc-td-fixed {
          left: 300px !important;
        }
      }
    }
  }
  > .rt-table {
    > .rt-tbody {
      > .rt-tr-group {
        &:last-child:not(:only-child) {
          .rt-td {
            &.rthfc-td-fixed {
              .rt-expander {
                display: none;
              }
            }
          }
        }
      }
    }
  }
  .rthfc-td-fixed-left-last, .rthfc-th-fixed-left-last  {
    border-right: 1px solid $secondary !important;
  }
}


//Custom React Table CSS Modifiers
.lastRowBlueColored {
  .rt-tfoot{
    background-color: $color-pastel-blue-light;
  }
  .rt-tbody{
    .rt-tr-group:last-child:not(:only-child){
      .rt-td{
      background-color: $color-pastel-blue-light;
      color: $color-koamaru-deep;
      }
    }
  }

}

.xpna-react-table.oneColumnFixed {
  
  .rt-thead{
    .rt-tr{
      .rt-th{
        &:nth-child(1){
          //position: sticky;
          z-index: 3;
          //left: 0px;
          //min-width: 300px;
          border-right: 1px solid $color-koamaru-deep;
          background-color: $color-pastel-blue-light !important;
        }
      } 
    }
  }
  .rt-tbody{
    .rt-tr{
      .rt-td{
        &:nth-child(1){
          //position: sticky;
          z-index: 3;
          //left: 0px;
          //min-width: 300px;
          border-right: 1px solid $color-koamaru-deep;
        }

      }
    }
  }

}



//Pure Tables CSS
//Analytics Studio Table - Basic Pure Table css
.xpna-table-wrapper{
  max-width: 100%;
  overflow: scroll;
  //padding: 5px;
  box-shadow: 0 1px 3px $color-box-shadow1, 0 1px 2px $color-box-shadow2;
  border-radius: 5px;
  table{
    width:100%
  }
}

.xpna-pure-table{
	width: 100%;
	border-collapse: separate;

	thead {
		tr {
			th {
				min-width: 100px;
				line-height: 35px;
				padding: 0;
				font-weight: normal;
				font-size: 13px;
				border: 0;
				color: $color-koamaru-deep ;
				background:$color-pastel-blue-light;
				box-shadow: none;

				&:first-child{
					position: sticky;
					z-index: 3;
					left: 0px;
					min-width: 300px;
					border-right: 1px solid #423970;
					background-color: $color-pastel-blue-light !important;
				}

				&.header-border-right:not(:first-child){
					border-right: 1px solid #423970 !important;
					//border-bottom: 1px solid #423970 !important;
				}
			}
		}
	}

	tbody {
		tr {
			&:nth-child(even){
				background-color: #ffffff;
        td {
          font-size: 12px;
          text-align: center;
          line-height: 40px;
          padding: 0;
          border-right: 0;
          border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
          box-shadow: none!important;
          
          &:first-child {
            text-align: left;
            position: sticky;
            z-index: 3;
            left: 0px;
            // padding-left: 50px;
            // width: 300px!important;
            min-width: 300px;
            border-right: 1px solid #423970;
            color: rgba(0,0,0,.87);
            font-size: 12px;
            font-weight: 500;
            background-color: #ffffff !important;
          }
        }
        
			}

			&:nth-child(odd){
				background-color: #F7F7F7;
        td {
          font-size: 12px;
          text-align: center;
          line-height: 40px;
          padding: 0;
          border-right: 0;
          border-bottom: solid 1px rgba(0, 0, 0, 0.1) !important;
          box-shadow: none!important;
          
          &:first-child {
            text-align: left;
            position: sticky;
            z-index: 3;
            left: 0px;
            // padding-left: 50px;
            // width: 300px!important;
            // max-width: 300px!important;
            min-width: 300px;
            border-right: 1px solid #423970;
            color: rgba(0,0,0,.87);
            font-size: 12px;
            font-weight: 500;
            background-color: #F7F7F7 !important;
          }
        }
        
			}

      
			
			
		}
	}
}

.xpna-fcf-table{
    margin-top: -15px;
    width: 100%;
    border-radius: 5px !important;
    background-color: $color-white;
    box-shadow : 0 1px 3px #0000001f, 0 1px 2px #0000003d;
    border: 0px none;
    .rt-table {
      //@include customScrollBar;
      border-radius: 5px;
      .enableDrillDown {
        cursor: pointer;
      }
      .rt-tbody{
        //@include customScrollBar;
        overflow: unset !important;
      }
    }
    .rt-thead.-headerGroups{
       background-color: $color-pastel-blue-light;
    }
    .rt-thead {
      background-color: $color-pastel-blue-light;
      box-shadow: none !important;
      position: -webkit-sticky; // this is for all Safari (Desktop & iOS), not for Chrome
      position: sticky;
      top: 0;
      z-index: 1;
      .rt-tr{
        justify-content: space-between !important;
        align-items: center !important;
    }
      .rt-th {
        padding: 10px 5px;
        font-weight: 500;
        font-size:13px;
        border: 0px none;
        color: $color-koamaru-deep!important;
        background: $color-pastel-blue-light !important;
        box-shadow: none !important;
        
      }
  
      .rt-th:last-child, .ReactTable .rt-thead .rt-td:last-child {
        border-right: 0 !important;
    }
    }
    .rt-tfoot {
      background-color: $color-pastel-blue-light;
    }
    .rt-tbody,
    .rt-tfoot {
      .rt-tr-group,
      .rt-tr {
        border-bottom: 0px none;
  
        .rt-td {
          font-size: 12px;
          text-align: center;
          line-height: 33px;
          padding: 0;
          border-right: 0px none;
          box-shadow: none !important;
        }
      }
    }
  
    .rt-tbody .rt-td:first-child {
      border-right: 1px solid $color-koamaru-deep;
      text-align: left !important;
      padding-left: 30px;
    }
  
    .rt-tbody .rt-td:last-child {
      border-right: 0 !important;
    }
  
    .rt-tbody{
      .dropdown-column{
        .MuiFormControl-root{
          padding: 5px !important;
          .MuiFormLabel-root {
            font-size: 12px !important;
          }
        }
      }
    }
  

  .minw-300 {
    min-width: 300px !important;
  }

  .pl-20 {
    padding-left: 20px !important;
  }

  .pl-30 {
    padding-left: 30px !important;
  }

  .pl-100 {
    padding-left: 100px !important;
  }

  .w-60 {
    max-width: 60px !important;
  }

  .total{
    background-color: #a2afc2 !important;
    font-weight: 600 !important;
  }

  .rt-tbody{
  .rt-tr:nth-child(even) {background: #f7f7f7}
  .rt-tr:nth-child(odd) {background: #FFF}
  }


 




}

.xpna-pure-table.data-browser-modal{
  thead {
		tr {
			th {
        &:first-child{
          min-width: unset !important;
          width: 50px !important ;
          border-right: 0px;
        }

      }      
    }
  }tbody {
		tr {
      td{
        &:first-child{
          min-width: unset !important;
          width: 50px !important ;
          border-right: 0px;
        }
      }
    }
  }

}
//Additional customisation
.expandableRows{
  tbody{
    tr{
      td:first-child{
        padding-left: 50px;
      }
    }
  }
}

.xpna-reactFixed-table.functional-pl {
  .rt-tr[data-qnt="0"] {
    .rt-expander {
      display: none;
    }
  }

  .rt-expander {
    border: 1px solid $color-blue-very-desaturated-dark;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 08px;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid $color-blue-very-desaturated-dark;
    }
  }
  .rt-thead {
    .rt-th {
      &:first-child {
        width: 50px !important;
        max-width: 50px !important;
        padding-left: 0;
        position: sticky;
        left: 0 !important;
        z-index: 99;
        border-right: 0px none;
        text-align: center;
      }
      &:nth-child(2) {
        color: $secondary;
        text-align: left;
        padding-left: 3px;
        width: 300px !important;
        max-width: 300px !important;
        border-right: 1px solid $secondary;
        left: 50px !important;
        .rt-resizer {
          display: none;
        }
      }
    }
  }
  .rt-tbody {
    .rt-tr-group {
      .rt-td {
        &:first-child {
          width: 50px !important;
          max-width: 50px !important;
          padding-left: 0;
          position: sticky;
          left: 0 !important;
          border-right: 0px none;
          text-align: center;
        }
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          width: 300px !important;
          max-width: 300px !important;
          border-right: 1px solid $secondary;
          left: 50px !important;
          // white-space: normal;
          .rt-resizer {
            display: none;
          }
        }
      }
    }
    .bupl-SubTable {
      border: 0px none;
      .rt-table {
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
      .rt-tr-group {
        .rt-td {
          &:first-child {
            width: 75px !important;
            max-width: 75px !important;
          }
          &:nth-child(2) {
            width: 275px !important;
            max-width: 275px !important;
            left: 75px !important;
          }
        }
      }
      .bupl-SubTable {
        .rt-tr-group {
          .rt-td {
            &:first-child {
              width: 100px !important;
              max-width: 100px !important;
            }
            &:nth-child(2) {
              width: 250px !important;
              max-width: 250px !important;
              left: 100px !important;
            }
          }
        }
        .bupl-SubTable {
          .rt-tr-group {
            .rt-td {
              &:first-child {
                width: 125px !important;
                max-width: 125px !important;
              }
              &:nth-child(2) {
                width: 225px !important;
                max-width: 225px !important;
                left: 125px !important;
              }
            }
          }
          .bupl-SubTable {
            .rt-tr-group {
              .rt-td {
                &:first-child {
                  width: 150px !important;
                  max-width: 150px !important;
                }
                &:nth-child(2) {
                  width: 200px !important;
                  max-width: 200px !important;
                  left: 150px !important;
                }
              }
            }
            .bupl-SubTable {
              .rt-tr-group {
                .rt-td {
                  &:first-child {
                    width: 175px !important;
                    max-width: 175px !important;
                  }
                  &:nth-child(2) {
                    width: 175px !important;
                    max-width: 175px !important;
                    left: 175px !important;
                  }
                }
              }
              .bupl-SubTable {
                .rt-tr-group {
                  .rt-td {
                    &:first-child {
                      width: 200px !important;
                      max-width: 200px !important;
                    }
                    &:nth-child(2) {
                      width: 150px !important;
                      max-width: 150px !important;
                      left: 200px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



.double-layer-header{
  thead{
    tr:first-child{
      th{
        font-size: 16px !important;
      }
    }
    tr:nth-child(2){
      th{
        font-size: 13px !important;
        padding-bottom: 5px;
      }
    }
  }
}

.triple-layer-header{
  thead{
    tr:first-child{
      th{
        font-size: 16px !important;
      }
    }
    tr:nth-child(2){
      th{
        font-size: 14px !important;
        line-height: unset !important;
      }
    }
    tr:nth-child(3){
      th{
        padding-top: 10px;
        font-size: 13px !important;
        line-height: unset !important;
        padding-bottom: 5px;
      }
    }
  }
}

.xpna-aging-table{
  .rt-tr{
    background-color: $color-pastel-blue-light;
    align-items: center;
    .rt-td{
      height: 100%;
    }
  }
  .rt-td{
    font-size: 12px !important;
  }
  .rt-th{
    background-color: $color-pastel-blue-light;
    font-size: 13px !important;
    font-weight: 500 !important;
    color:  $color-koamaru-deep !important;
    padding: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
   
  }
}

.adhoc-table {
  > .rt-table {
    > .rt-tbody {
      max-height: calc(100vh/3.2);
    }
  }
}

.xpna-reactFixed-table.TnEAnalysis {
  .rt-tr[data-qnt="0"] {
    .rt-expander {
      display: none;
    }
  }

  .rt-expander {
    border: 1px solid #423970;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    top: 08px;
    &:after {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 6px solid #423970;
    }
  }
  .rt-thead {
    .rt-th {
      &:first-child {
        width: 50px !important;
        max-width: 50px !important;
        padding-left: 0;
        position: sticky;
        left: 0 !important;
        z-index: 99;
        border-right: 0px none;
        text-align: center;
      }
      &:nth-child(2) {
        color: $secondary;
        text-align: left;
        padding-left: 3px;
        width: 200px !important;
        max-width: 200px !important;
        border-right: 1px solid $secondary;
        left: 50px !important;
        .rt-resizer {
          display: none;
        }
      }
    }
  }
  .rt-tbody {
    .rt-tr-group {
      .rt-td {
        &:first-child {
          width: 50px !important;
          max-width: 50px !important;
          padding-left: 0;
          position: sticky;
          left: 0 !important;
          border-right: 0px none;
          text-align: center;
        }
        &:nth-child(2) {
          font-size: 12px;
          font-weight: 500;
          text-align: left;
          width: 200px !important;
          max-width: 200px !important;
          border-right: 1px solid $secondary;
          left: 50px !important;
          // white-space: normal;
          .rt-resizer {
            display: none;
          }
        }
      }
    }
    .bupl-SubTable {
      border: 0px none;
      .rt-table {
        &::-webkit-scrollbar {
          height: 0px;
        }
      }
      .rt-tr-group {
        .rt-td {
          &:first-child {
            width: 75px !important;
            max-width: 75px !important;
          }
          &:nth-child(2) {
            width: 175px !important;
            max-width: 175px !important;
            left: 75px !important;
          }
        }
      }
      .bupl-SubTable {
        .rt-tr-group {
          .rt-td {
            &:first-child {
              width: 75px !important;
              max-width: 75px !important;
            }
            &:nth-child(2) {
              width: 175px !important;
              max-width: 175px !important;
              left: 75px !important;
            }
          }
        }
        .bupl-SubTable {
          .rt-tr-group {
            .rt-td {
              &:first-child {
                width: 125px !important;
                max-width: 125px !important;
              }
              &:nth-child(2) {
                width: 225px !important;
                max-width: 225px !important;
                left: 125px !important;
              }
            }
          }
          .bupl-SubTable {
            .rt-tr-group {
              .rt-td {
                &:first-child {
                  width: 150px !important;
                  max-width: 150px !important;
                }
                &:nth-child(2) {
                  width: 200px !important;
                  max-width: 200px !important;
                  left: 150px !important;
                }
              }
            }
            .bupl-SubTable {
              .rt-tr-group {
                .rt-td {
                  &:first-child {
                    width: 175px !important;
                    max-width: 175px !important;
                  }
                  &:nth-child(2) {
                    width: 175px !important;
                    max-width: 175px !important;
                    left: 175px !important;
                  }
                }
              }
              .bupl-SubTable {
                .rt-tr-group {
                  .rt-td {
                    &:first-child {
                      width: 200px !important;
                      max-width: 200px !important;
                    }
                    &:nth-child(2) {
                      width: 150px !important;
                      max-width: 150px !important;
                      left: 200px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.rev-summary-table{
	height: unset !important;
	overflow: scroll;
	.xpna-aop-rev-table {
		thead {
			 position: sticky; top: 0; z-index: 4;
			tr{
				th:nth-child(1),
				th:nth-child(2),
				th:nth-child(3),
				th:nth-child(4) {/*first cell in the header*/
				  min-width: 90px !important;
				  position: sticky !important;
				}
				th:nth-child(1){
					// width: 150px;
					width: 90px !important;
				    text-align: center;
				    left: 0px;
				}
				th:nth-child(2){
					// width: 150px;
					width: 90px !important;
				    // left: 150px;
				    left: 90px !important;
				}
				th:nth-child(3){
					// width: 150px;
					width: 90px !important;
				    // left: 300px;
				    left: 180px !important;
				}
				th:nth-child(4){
					// width: 150px;
					width: 90px !important;
				    // left: 450px;
				    left: 270px !important;
				}

				th.header-border-right:nth-child(1),
				th.header-border-right:nth-child(2),
				th.header-border-right:nth-child(3){
					border-bottom: unset !important;
					border-right: 1px solid #423970 !important;
				}
				th.header-border-right:nth-child(4){
					border-bottom: unset !important;
					border-right: 1px solid #423970 !important;
				}
			}
		}
		tbody {
			tr{
				td:nth-child(1),
				td:nth-child(2),
				td:nth-child(3),
				td:nth-child(4) {/*first cell in the header*/
				//   min-width: 150px !important;
				  min-width: 90px !important;
				  position: sticky !important;
				  background-color: #fff;
				  text-align: center;
				  padding: 0px;
				}
				td:nth-child(1){
					// width: 150px;
					width: 90px !important;
				    // left: 0px;
				    border-right: unset !important;
				    left: 0px !important;
				}
				td:nth-child(2){
					// width: 150px;
					width: 90px !important;
				    // left: 150px;
				    left: 90px !important;
				}
				td:nth-child(3){
					// width: 150px;
					width: 90px !important;
				    // left: 300px;
				    left: 180px !important;

				}
				td:nth-child(4){
					// width: 150px;
					width: 90px !important;
				    // left: 450px;
				    left: 270px !important;
				    border-right: 1px solid #423970 !important;
				}
			}
		}
	}
	
}


.rev-summary-table-detail{
	height: 550px;
	overflow: scroll;
	.xpna-aop-rev-table {
		thead {
			 position: sticky; top: 0; z-index: 4;
			tr{
				th:nth-child(1),
				th:nth-child(2),
				th:nth-child(3),
				th:nth-child(4),
				th:nth-child(5),
				th:nth-child(6),
				th:nth-child(7),
				th:nth-child(8),
				th:nth-child(9) {/*first cell in the header*/
				  min-width: 100px !important;
				  position: sticky !important;
				}
				th:nth-child(1){
					width: 100px !important;
				    text-align: center;
				    left: 0px !important;
				}
				th:nth-child(2){
					width: 100px !important;
				    left: 100px !important;
				}
				th:nth-child(3){
					width: 100px !important;
				    left: 200px !important;
				}
				th:nth-child(4){
					width: 100px !important;
				    left: 300px !important;
				}
				th:nth-child(5){
					width: 100px !important;
				    left: 400px !important;
				}
				th:nth-child(6){
					width: 100px !important;
				    left: 500px !important;
				}
				th:nth-child(7){
					width: 100px !important;
				    left: 600px !important;
				}
				th:nth-child(8){
					width: 100px !important;
				    left: 700px !important;
				}
				th:nth-child(9){
					width: 100px !important;
				    left: 800px !important;
				}

				th.header-border-right:nth-child(1),
				th.header-border-right:nth-child(2),
				th.header-border-right:nth-child(3),
				th.header-border-right:nth-child(4),
				th.header-border-right:nth-child(5),
				th.header-border-right:nth-child(6),
				th.header-border-right:nth-child(7),
				th.header-border-right:nth-child(8){
					border-bottom: unset !important;
					border-right: 1px solid #423970 !important;
				}
				th.header-border-right:nth-child(9){
					border-bottom: unset !important;
					border-right: 1px solid #423970 !important;
				}
			}
		}
		tbody {
			tr{
				td:nth-child(1),
				td:nth-child(2),
				td:nth-child(3),
				td:nth-child(4),
				td:nth-child(5),
				td:nth-child(6),
				td:nth-child(7),
				td:nth-child(8),
				td:nth-child(9) {/*first cell in the header*/
				//   min-width: 150px !important;
				  min-width: 100px !important;
				  position: sticky !important;
				  background-color: #fff;
				  text-align: center;
				  padding: 0px;
				}
				td:nth-child(1){
					width: 100px !important;
				    // left: 0px;
				    border-right: unset !important;
				    left: 0px !important;
				}
				td:nth-child(2){
					width: 100px !important;
				    // left: 150px;
				    left: 100px !important;
				}
				td:nth-child(3){
					width: 100px !important;
				    // left: 300px;
				    left: 200px !important;
				}
				td:nth-child(4){
					width: 100px !important;
				    // left: 0px;
				    border-right: unset !important;
				    left: 300px !important;
				}
				td:nth-child(5){
					width: 100px !important;
				    // left: 150px;
				    left: 400px !important;
				}
				td:nth-child(6){
					width: 100px !important;
				    // left: 300px;
				    left: 500px !important;
				}
				td:nth-child(7){
					width: 100px !important;
				    // left: 0px;
				    border-right: unset !important;
				    left: 600px !important;
				}
				td:nth-child(8){
					width: 100px !important;
				    // left: 0px;
				    border-right: unset !important;
				    left: 700px !important;
				}
				td:nth-child(9){
					width: 100px !important;
				    // left: 450px;
				    left: 800px !important;
				    border-right: 1px solid #423970 !important;
				}
			}
		}
	}
	
}

