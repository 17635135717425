@import '../../../../utility.scss';


.hfmDataTable.scatterChart{
	.table-view{
		.rt-thead .rt-th:first-child {
			width: 3vh !important;
		}
		.rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
		    width: 3vh !important;
		}
	}
}
.hfmDataTable .ReactTable {
	box-shadow : 0 1px 3px $color-box-shadow1, 0 1px 2px $color-box-shadow2;
}

.hfmDataTable .ReactTable.no-fixed-line {
	.rt-tbody .rt-tr-group .rt-tr .rt-td:first-child , 
	.rt-thead .rt-th:first-child {
    	border-right: 1px solid rgba(0,0,0,0.05);
	}
	.rt-tbody .rt-tr-group .rt-tr .rt-td , .rt-thead .rt-th{
		white-space: normal;
		border-right: 1px solid rgba(0,0,0,0.05);
	}

	.rthfc-th-fixed-left-last,.rthfc-td-fixed-left-last{
		border-right: 2px solid $color-blue-mostly-dark !important;
	}

	.rt-tbody{
		max-height: 400px;
	}



	.rt-table{
		//@include customScrollBar;
	}

  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{
    padding: 5px !important;
  }
}


.highcharts-tooltip {
  z-index: 9998;
}

.highcharts-tooltip div {
  background-color: $color-white;
  border: 1px solid $color-gray-medium-light;
  opacity: 1;
  z-index: 9999!important;
  padding: 5px
}

span.text-red {
    color: $color-cinnabar-red;
    font-weight: bold;
}

span.text-green {
    color: $color-apple-green;
    font-weight: bold;
}

.summary {
    text-align: center;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    .title{
    	font-weight: bold;
    }
}

.title-summary {
    padding: 10px;
    font-size: 13px;
    font-weight: bold;
}

span.bu-keys {
    margin-right: 5px;
    font-weight: bold;
}

.bu-list{
	padding: 0px 0px 5px 10px !important;
}

.summary-content{
	display: inline-flex;
}
.summary.list-item{
	width: 125px;
	border-right: 2px solid $color-gray-light;
}

#column_chart_overdue{
	.highcharts-subtitle{
		text-align: left;
		left: 7.5px !important;
	}
}

.analysis-action{
  .filter-section{
    padding: 0px 0px 5px 10px !important;
  }
  .hfmDataTable .ReactTable.no-fixed-line .rt-tbody {
    max-height: 375px !important;
  }
}

.email-template{
  .MuiFormControl-root .MuiFormLabel-root{
      font-size: 15px !important;
      font-weight: bold !important;
  }
  .MuiInputBase-root.Mui-disabled {
  	color: rgba(0, 0, 0, 0.87) !important;
  }

  .MuiFormLabel-root.Mui-disabled{
  	color: rgba(0, 0, 0, 0.54) !important;
  }
  .ck.ck-reset.ck-editor.ck-rounded-corners{
  	margin-top: 25px;
  }
  
}

.viewEmailModal{
  max-width : 750px !important;
}
.viewEmailModalWide{
  max-width : 1080px !important;
}
.download-excel-custom{
  float: right !important;
}
