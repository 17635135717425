.customModal {
    // width: 30% !important
}

.modal-content{
    padding-left: 15px !important;
    padding-top: 0px !important;
}

.main-header{
    margin-left: 20px;
}

.main-header-body{
    display: flex;
    align-items: center;
}

.main-header-heading{
    margin-left: 5px !important;
}

.main-header-input{
    width: 60%
}

.sub-header {
    margin-left: 50px;
    margin-bottom: 10px;
    .sub-header-heading {
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: 5px;
    }
    .sub-header-list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .sub-header-item{
            display: flex;
            align-items: center;
            .sub-header-input{
                width: 70%;
            }
        }
    }
}

.button-row{
    display: flex;
    align-items:center;
    justify-content : center;
    margin-bottom: 5px;
    .MuiButtonBase-root{
        background-color: #062565 !important;
        color: #ffffff !important;
    }
}
