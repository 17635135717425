$size__site_content_width : 1024px;

/* Media Queries */ 

$media_queries : (
    'xs'                : "only screen and (min-width: 280px) and (max-width: 482px)",
    'eReaders'          : "only screen and (min-width: 482px) and (max-width: 599px)",
    'mobile'            : "only screen and (min-width: 482px) and (max-width: 667px)",
    'customMobile'      : "only screen and (min-width: 600px) and (max-width: 900px)",
    'customTab'         : "only screen and (min-width: 667px) and (max-width: 900px)",
    'tablet'            : "only screen and (min-width: 667px) and (max-width: 1024px)",
    'desktop'           : "only screen and (min-width: 1025px)",
    'wideScreens'       : "only screen and (min-width: 1339px)",
    'retina2'           : "only screen and (-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)",
    'retina3'           : "only screen and (-webkit-min-device-pixel-ratio: 3) and (min-resolution: 288dpi)",
    'landscape'         : "screen and (orientation:landscape) ",    
    'portrait'          : "screen and (orientation:portrait) "
);

@mixin devices($breakpoints) {
    $conditions : ();
    @each $breakpoint in $breakpoints {
        // If the key exists in the map
        $conditions: append(
            $conditions,
            #{inspect(map-get($media_queries, $breakpoint))},
            comma
        );
    }

    @media #{$conditions} {
        @content;
    }

}
